import {
  InvitationUpdateRequest,
  PasswordResetTokenRequest,
  ResetPasswordRequest,
} from './../../../../generated/public/Api';
import {
  PaginatedUserTenantList,
  PatchedUserPermissionEditRequest,
  UserStrategyPermissions,
  UserTenant,
} from './../../../../generated/tenants/Api';
import { InvitationRequest } from '../../../../generated/tenants/Api';
import { RootState } from '../../../store';
import {
  CheckAddUserResult,
  CheckDeleteUserResult,
  CheckPasswordResetResult,
  checkPasswordResetToken,
  CheckPasswordResetTokenResult,
  deleteUsers,
  getUserPermissions,
  getUserDetailed,
  getUsersList,
  resetUserPassword,
  sendInvitation,
  sendPasswordResetToken,
  CheckInvitationResult,
  checkInvitationToken,
  invitationComplete,
  userPermissionsUpdate,
} from './userApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface UserState {
  list: {
    state: 'pending' | 'idle';
    value?: PaginatedUserTenantList;
  };
  delete: {
    state: 'pending' | 'idle';
    value?: CheckDeleteUserResult;
  };
  detailed: {
    state: 'pending' | 'idle';
    value?: UserTenant;
  };
  permissions: { state: 'pending' | 'idle'; value?: UserStrategyPermissions };
  invitation?: { state: 'pending' | 'idle'; value?: CheckAddUserResult };
  checkInvitation: { state: 'pending' | 'idle'; value?: CheckInvitationResult };
  addUser: { state: 'pending' | 'idle'; value?: CheckAddUserResult };

  checkPasswordResetToken: {
    state: 'pending' | 'idle';
    value?: CheckPasswordResetTokenResult;
  };
  passwordResetToken: {
    state: 'pending' | 'idle';
    value?: CheckPasswordResetTokenResult;
  };
  passwordReset: {
    state: 'pending' | 'idle';
    value?: CheckPasswordResetResult;
  };

  permissionsUpdate: { state: 'pending' | 'idle' };
}

const initialState: UserState = {
  list: {
    state: 'pending',
  },
  delete: {
    state: 'pending',
  },
  detailed: {
    state: 'pending',
  },
  permissions: { state: 'pending' },
  checkInvitation: { state: 'pending' },
  addUser: { state: 'pending' },

  checkPasswordResetToken: {
    state: 'pending',
  },
  passwordResetToken: {
    state: 'pending',
  },
  passwordReset: {
    state: 'pending',
  },

  permissionsUpdate: { state: 'pending' },
};

export const getUsersListAsyncThunk = createAsyncThunk(
  'user/usersList',
  (query?: {
    filter_info?: string;
    limit?: number;
    offset?: number;
    strategy_id?: string;
  }) => getUsersList(query),
);

export const deleteUsersAsyncThunk = createAsyncThunk(
  'users/deleteUsers',
  (query: { ids: Array<string> }) => deleteUsers(query),
);

export const getUserDetailedAsyncThunk = createAsyncThunk(
  'user/getUserDetailed',
  (args: string) => getUserDetailed(args),
);

export const getUserPermissionsAsyncThunk = createAsyncThunk(
  'user/permissions',
  (strategyId: string) => getUserPermissions(strategyId),
);

//invitation
export const invitationAsyncThunk = createAsyncThunk(
  'user/inviteUser',
  (args: InvitationRequest) => sendInvitation(args),
);
export const checkInvitationAsyncThunk = createAsyncThunk(
  'invivation/check',
  (token: string) => checkInvitationToken(token),
);

export const addUserAfterInviteAsyncThunk = createAsyncThunk(
  'invitation/addUser',
  (args: { token: string; data: InvitationUpdateRequest }) =>
    invitationComplete(args.token, args.data),
);

//password reset

export const checkPasswordResetTokenAsyncThunk = createAsyncThunk(
  'passwordReset/check-token',
  (token: string) => checkPasswordResetToken(token),
);

export const sendPasswordResetTokenAsyncThunk = createAsyncThunk(
  'passwordReset/token',
  (data: PasswordResetTokenRequest) => sendPasswordResetToken(data),
);

export const resetUserPasswordAsyncThunk = createAsyncThunk(
  'passwordReset/reset',
  (data: ResetPasswordRequest) => resetUserPassword(data),
);

//permissions
export const userPermissionsUpdateAsyncThunk = createAsyncThunk(
  'permissions/user',
  (args: { userId: string; data: PatchedUserPermissionEditRequest }) =>
    userPermissionsUpdate(args.userId, args.data),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startSendNewInvitation: (state) => {
      state.invitation = { state: 'pending' };
    },
    endSendNewInvitation: (state) => {
      state.invitation = undefined;
    },
    clearPasswordResetToken: (state) => {
      state.passwordResetToken.state = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder

      //users list
      .addCase(getUsersListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(getUsersListAsyncThunk.fulfilled, (state, action) => {
        state.list.state = 'idle';
        state.list.value = action.payload;
      })
      .addCase(getUsersListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //user detailed
      .addCase(getUserDetailedAsyncThunk.pending, (state) => {
        state.detailed.state = 'pending';
      })
      .addCase(getUserDetailedAsyncThunk.fulfilled, (state, action) => {
        state.detailed.state = 'idle';
        state.detailed.value = action.payload;
      })
      .addCase(getUserDetailedAsyncThunk.rejected, (state) => {
        state.detailed.state = 'idle';
      })

      //delete users
      .addCase(deleteUsersAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteUsersAsyncThunk.fulfilled, (state, action) => {
        state.delete.state = 'idle';
        state.delete.value = action.payload;
      })
      .addCase(deleteUsersAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      })

      //user permissions
      .addCase(getUserPermissionsAsyncThunk.pending, (state) => {
        state.permissions.state = 'pending';
      })
      .addCase(getUserPermissionsAsyncThunk.fulfilled, (state, action) => {
        state.permissions.state = 'idle';
        state.permissions.value = action.payload;
      })
      .addCase(getUserPermissionsAsyncThunk.rejected, (state) => {
        state.permissions.state = 'idle';
      })

      //send invitation
      .addCase(invitationAsyncThunk.pending, (state) => {
        if (state.invitation) {
          state.invitation.state = 'pending';
        }
      })
      .addCase(invitationAsyncThunk.fulfilled, (state, action) => {
        if (state.invitation) {
          state.invitation.state = 'idle';
          state.invitation.value = action.payload;
          state.invitation.value.status === 'success';
        }
      })
      .addCase(invitationAsyncThunk.rejected, (state) => {
        if (state.invitation) {
          state.invitation.state = 'idle';
        }
      })
      //check invitation token
      .addCase(checkInvitationAsyncThunk.pending, (state) => {
        state.checkInvitation.state = 'pending';
      })
      .addCase(checkInvitationAsyncThunk.fulfilled, (state, action) => {
        state.checkInvitation.state = 'idle';
        state.checkInvitation.value = action.payload;
      })
      .addCase(checkInvitationAsyncThunk.rejected, (state) => {
        state.checkInvitation.state = 'idle';
      })

      //add user after invitation
      .addCase(addUserAfterInviteAsyncThunk.pending, (state) => {
        state.addUser.state = 'pending';
      })
      .addCase(addUserAfterInviteAsyncThunk.fulfilled, (state, action) => {
        state.addUser.state = 'idle';
        state.addUser.value = action.payload;
      })
      .addCase(addUserAfterInviteAsyncThunk.rejected, (state) => {
        state.addUser.state = 'idle';
      })

      //reset password token
      .addCase(checkPasswordResetTokenAsyncThunk.pending, (state) => {
        state.checkPasswordResetToken.state = 'pending';
      })
      .addCase(checkPasswordResetTokenAsyncThunk.fulfilled, (state, action) => {
        state.checkPasswordResetToken.state = 'idle';
        state.checkPasswordResetToken.value = action.payload;
      })
      .addCase(checkPasswordResetTokenAsyncThunk.rejected, (state) => {
        state.checkPasswordResetToken.state === 'idle';
      })

      //send password reset token
      .addCase(sendPasswordResetTokenAsyncThunk.pending, (state) => {
        state.passwordResetToken.state = 'pending';
      })
      .addCase(sendPasswordResetTokenAsyncThunk.fulfilled, (state, action) => {
        state.passwordResetToken.state = 'idle';
        state.passwordResetToken.value = action.payload;
      })
      .addCase(sendPasswordResetTokenAsyncThunk.rejected, (state) => {
        state.passwordResetToken.state = 'idle';
      })

      //reset password
      .addCase(resetUserPasswordAsyncThunk.pending, (state) => {
        state.passwordReset.state = 'pending';
      })
      .addCase(resetUserPasswordAsyncThunk.fulfilled, (state, action) => {
        state.passwordReset.state = 'idle';
        state.passwordReset.value = action.payload;
      })
      .addCase(resetUserPasswordAsyncThunk.rejected, (state) => {
        state.passwordResetToken.state = 'idle';
      })

      //user permission
      .addCase(userPermissionsUpdateAsyncThunk.pending, (state) => {
        state.permissionsUpdate.state = 'pending';
      })
      .addCase(userPermissionsUpdateAsyncThunk.fulfilled, (state) => {
        state.permissionsUpdate.state = 'idle';
      })
      .addCase(userPermissionsUpdateAsyncThunk.rejected, (state) => {
        state.permissionsUpdate.state = 'idle';
      });
  },
});
//users list
export const selectUsers = (state: RootState) => state.users.list.value;
//user by ID
export const selectUserDetailed = (state: RootState) =>
  state.users.detailed.value;
//permissions list
export const selectPermissions = (state: RootState) =>
  state.users.permissions.value;

export const checkInvitationSend = (state: RootState) => state.users.invitation;
export const checkInivation = (state: RootState) => state.users.checkInvitation;
export const checkAddUser = (state: RootState) => state.users.addUser;

export const checkDeleteUsers = (state: RootState) => state.users.delete;

//password reset
export const checkResetPasswordToken = (state: RootState) =>
  state.users.checkPasswordResetToken;
export const checkPasswordResetTokenCreate = (state: RootState) =>
  state.users.passwordResetToken;
export const checkPasswordReset = (state: RootState) =>
  state.users.passwordReset;

export const checkUserPermissionsUpdate = (state: RootState) =>
  state.users.permissionsUpdate;

export const {
  startSendNewInvitation,
  endSendNewInvitation,
  clearPasswordResetToken,
} = userSlice.actions;

export default userSlice.reducer;
