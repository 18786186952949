import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../common/modals/Modal';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  Strategy,
  StrategyCreateRequest,
} from '../../../../../../../generated/tenants/Api';
import {
  updateStrategyAsyncThunk,
  createStrategyAsyncThunk,
  checkStrategyCreate,
  checkStrategyUpdate,
  clearCreateStrategy,
  clearUpdateStrategy,
  getStrategiesListAsyncThunk,
} from '../api/strategiesSlice';
import { ApiError } from '../../../../../../../common/types';
import {
  EuiModalBody,
  EuiFormRow,
  EuiFieldText,
  EuiFieldNumber,
  EuiToolTip,
  EuiText,
  EuiSwitch,
} from '@elastic/eui';
import {
  getLoginStateAsyncThunk,
  selectConfig,
  selectUser,
} from '../../../../../session/api/sessionSlice';
import { H3, H4 } from '../../../../../../App.style';
import { useTranslation } from 'react-i18next';

interface StrategyFormModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedStrategy?: Strategy;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const StrategyFormModal: React.FC<StrategyFormModalProps> = ({
  isVisible,
  closeModal,
  selectedStrategy,
  setIsLoadingVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [strategyName, setStrategyName] = useState<string>('');
  const [assignedLines, setAssignedLines] = useState<number>(1);
  const [demoResources, setDemoRecourses] = useState<boolean>(true);

  useEffect(() => {
    if (selectedStrategy) {
      setStrategyName(selectedStrategy.name);
      setAssignedLines(selectedStrategy.assigned_lines);
    } else {
      setStrategyName('');
      setAssignedLines(1);
    }
  }, [selectedStrategy]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoadingVisible(true);
    if (selectedStrategy !== undefined) {
      const data = {
        id: selectedStrategy.id,
        data: {
          name: strategyName,
          assigned_lines: assignedLines,
        },
      };
      dispatch(updateStrategyAsyncThunk(data))
        .then(() => {
          dispatch(getLoginStateAsyncThunk());
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    } else {
      const data: StrategyCreateRequest = {
        demo: demoResources,
        strategy_data: {
          name: strategyName,
          assigned_lines: assignedLines,
        },
      };

      dispatch(createStrategyAsyncThunk(data))
        .then(() => {
          dispatch(getLoginStateAsyncThunk());
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    }

    return false;
  };

  const [apiErrors, setApiErrors] = useState<ApiError<Strategy>>();

  const checkCreate = useAppSelector(checkStrategyCreate);

  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleCloseModalClick();
      dispatch(getStrategiesListAsyncThunk());
      dispatch(clearCreateStrategy());
    } else {
      setApiErrors(checkCreate.value?.error);
    }
  }, [checkCreate]);

  const checkUpdate = useAppSelector(checkStrategyUpdate);

  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      handleCloseModalClick();
      dispatch(getStrategiesListAsyncThunk());
      dispatch(clearUpdateStrategy());
    } else {
      setApiErrors(checkUpdate.value?.error);
    }
  }, [checkUpdate]);

  const handleCloseModalClick = () => {
    closeModal();
    dispatch(clearCreateStrategy());
    dispatch(clearUpdateStrategy());
    setStrategyName('');
    setAssignedLines(1);
  };

  //lines
  const config = useAppSelector(selectConfig);
  const user = useAppSelector(selectUser);

  const currentTenantName = (user.value?.companies || [])
    .filter((tenant) => !!tenant.slug && tenant.slug === config.tenantSubdomain)
    .map((tenant) => tenant.name)[0];

  const freeLinesByTenantName = (name: string) => {
    return user.value?.companies.find((obj) => obj.name === name)?.free_lines;
  };

  const [assignedLinesError, setAssignedLinesError] = useState<boolean>(false);
  useEffect(() => {
    if (availableLines) {
      if (assignedLines > availableLines) {
        setAssignedLinesError(true);
      } else {
        setAssignedLinesError(false);
      }
    }
  }, [assignedLines]);

  const freeLines = freeLinesByTenantName(currentTenantName);

  const [availableLines, setAvailableLines] = useState<number>(0);
  useEffect(() => {
    if (freeLines !== undefined) {
      if (selectedStrategy) {
        setAvailableLines(freeLines + selectedStrategy.assigned_lines);
      } else {
        setAvailableLines(freeLines);
      }
    }
  }, [freeLines, selectedStrategy]);

  const body = (
    <EuiModalBody style={{ maxWidth: 400 }}>
      <EuiFormRow
        label={
          <H3>
            {t('settings.adminPanel.manageWorkspaces.form.workspaceName')}
          </H3>
        }
        isInvalid={apiErrors?.name && true}
        error={apiErrors?.name}
      >
        <EuiFieldText
          autoFocus
          value={strategyName}
          onChange={(e) => {
            setStrategyName(e.target.value);
            setApiErrors({ ...apiErrors, name: undefined });
          }}
        />
      </EuiFormRow>

      {!selectedStrategy && (
        <div style={{ margin: '20px 0px', display: 'flex', gap: 25 }}>
          <H4>
            {t('settings.adminPanel.manageWorkspaces.form.createDemoResources')}
          </H4>
          <EuiSwitch
            compressed
            label={
              <H4>{t('settings.adminPanel.manageWorkspaces.form.yes')}</H4>
            }
            onChange={(e) => setDemoRecourses(e.target.checked)}
            checked={demoResources}
          />
        </div>
      )}

      <EuiFormRow
        label={
          <H3>
            {t('settings.adminPanel.manageWorkspaces.form.assignedLines')}
          </H3>
        }
        isInvalid={apiErrors?.assigned_lines ? true : assignedLinesError}
        error={
          apiErrors?.assigned_lines
            ? apiErrors.assigned_lines
            : t('settings.adminPanel.manageWorkspaces.form.noLines')
        }
      >
        <EuiFieldNumber
          value={assignedLines}
          isInvalid={apiErrors?.assigned_lines ? true : assignedLinesError}
          onChange={(e) => {
            setAssignedLines(Number(e.target.value));
            setApiErrors({ ...apiErrors, assigned_lines: undefined });
          }}
          append={
            <EuiToolTip content="">
              <EuiText size="s">
                {t('settings.adminPanel.manageWorkspaces.form.freeLines')}
                {availableLines}
              </EuiText>
            </EuiToolTip>
          }
        />
      </EuiFormRow>
    </EuiModalBody>
  );
  return (
    <Modal
      isVisible={isVisible}
      onCancel={handleCloseModalClick}
      headerTitle={
        selectedStrategy
          ? `${t('common.edit')} ${selectedStrategy.name}`
          : t('settings.adminPanel.manageWorkspaces.form.createNewWorkspace')
      }
      children={body}
      onConfirm={handleSubmit}
      buttonDisabled={availableLines <= 0}
    />
  );
};

export default StrategyFormModal;
