import React, { useEffect, useState } from 'react';
import { TenantFormContainer } from '../Tenants.style';
import styled from 'styled-components';
import {
  COLOR_NEUTRAL_30,
  COLOR_PRIMARY_1,
  H2,
  H3,
  H4,
} from '../../../../../../App.style';
import { EuiFieldNumber, EuiRadio } from '@elastic/eui';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { ApiErrorExtended } from '../../../../../../../common/types';
import {
  CompanyCreateRequest,
  Company,
  BillingConfigurationRequest,
} from '../../../../../../../generated/public/Api';
import { useTranslation } from 'react-i18next';

const ModelBox = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  width: 440px;
  padding: 12px 18px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 5px;
  border: ${({ $isSelected }) =>
    $isSelected
      ? `1px solid ${COLOR_PRIMARY_1}`
      : `1px solid ${COLOR_NEUTRAL_30}`};

  display: flex;
  flex-direction: column;
`;

const BillingFormLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BilingFormRightContainer = styled.div`
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
`;

const BillingFormBorderBox = styled.div`
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #9aa5b1;
  margin-bottom: 16px;
`;

interface TenantBillingFormProps {
  tenant: CompanyCreateRequest;
  setTenant: React.Dispatch<React.SetStateAction<CompanyCreateRequest>>;
  tenantApiErrors: ApiErrorExtended<Company>;
  setTenantApiErrors: React.Dispatch<
    React.SetStateAction<ApiErrorExtended<Company>>
  >;
  createNew?: boolean;
  detailedView?: boolean;
  openDeleteTenantModal?: () => void;
}

type StringifyProperties<T> = {
  [K in keyof T]: T[K] extends number ? string : T[K];
};

export type StringifiedBillingConfigurationRequest =
  StringifyProperties<BillingConfigurationRequest>;

const TenantBillingForm: React.FC<TenantBillingFormProps> = ({
  tenant,
  setTenant,
  detailedView,
  tenantApiErrors,
  setTenantApiErrors,
}) => {
  const { t } = useTranslation();
  const [billingForm, setBillingForm] =
    useState<StringifiedBillingConfigurationRequest>({
      domestic_mobile_call_price:
        tenant.billing_configuration.domestic_mobile_call_price.toString(),
      international_mobile_call_price:
        tenant.billing_configuration.international_mobile_call_price.toString(),
      domestic_landline_call_price:
        tenant.billing_configuration.domestic_landline_call_price.toString(),
      international_landline_call_price:
        tenant.billing_configuration.international_landline_call_price.toString(),
      sms_price: tenant.billing_configuration.sms_price.toString(),
      currency: tenant.billing_configuration.currency,
      template: tenant.billing_configuration.template,
    });
  const billing = tenant.billing_configuration;

  const handlePriceChange =
    (field: keyof BillingConfigurationRequest) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const regex = /^\d+(\.\d{1,2})?$/;

      if (regex.test(value) || value === '') {
        setBillingForm({
          ...billingForm,
          [field]: e.target.value,
        });

        if (tenantApiErrors.billing_configuration?.[field]) {
          setTenantApiErrors({
            ...tenantApiErrors,
            billing_configuration: {
              ...tenantApiErrors.billing_configuration,
              [field]: undefined,
            },
          });
        }
      }
    };

  useEffect(() => {
    setTenant((prevTenant) => ({
      ...prevTenant,
      billing_configuration: {
        ...prevTenant.billing_configuration,
        domestic_mobile_call_price: parseFloat(
          billingForm.domestic_mobile_call_price,
        ),
        international_mobile_call_price: parseFloat(
          billingForm.international_mobile_call_price,
        ),
        domestic_landline_call_price: parseFloat(
          billingForm.domestic_landline_call_price,
        ),
        international_landline_call_price: parseFloat(
          billingForm.international_landline_call_price,
        ),
        sms_price: parseFloat(billingForm.sms_price),
        currency: billingForm.currency,
        template: billingForm.template,
      },
    }));
  }, [billingForm, setTenant]);
  return (
    <TenantFormContainer style={{ flexDirection: 'row' }}>
      <BillingFormLeftContainer>
        <H2 $medium>
          {t('superAdminPanel.tenants.form.billings.billingPlan')}
        </H2>
        <ModelBox $isSelected={true}>
          <EuiRadio
            id={'model_per_call'}
            label={
              <H4 $medium>
                {t('superAdminPanel.tenants.form.billings.perCall')}
              </H4>
            }
            checked={billing.template === 'PER_CALL'}
            onChange={() => {}}
          />
          <H4 style={{ marginLeft: 25 }} $grey>
            opis
          </H4>
        </ModelBox>

        <ModelBox>
          <EuiRadio
            id={'model_per_records'}
            label={
              <H4 $medium>
                {t('superAdminPanel.tenants.form.billings.perRecord')}
              </H4>
            }
            checked={false}
            onChange={() =>
              alert(
                `Nie ustalono jeszcze cennika dla modelu  'Stawka za rekord'. `,
              )
            }
          />
          <H4 style={{ marginLeft: 25 }} $grey>
            opis
          </H4>
        </ModelBox>
      </BillingFormLeftContainer>
      <BilingFormRightContainer>
        {/* będzie używane przy innych modelach billingowych
        <H2 $medium style={{ marginBottom: 16 }}>
          Agent pricing
        </H2>

        <FormRow label={<H3>Stawka za agenta</H3>}>
          <EuiFieldNumber

            onChange={() => {
              console.log('hej');
            }}
          />
        </FormRow> */}

        <H2 $medium style={{ marginBottom: 16 }}>
          {t('superAdminPanel.tenants.form.billings.pricingTelco')}
        </H2>

        <BillingFormBorderBox>
          <H3 $selected>{t('superAdminPanel.tenants.form.billings.mobile')}</H3>
        </BillingFormBorderBox>

        <FormRow
          label={
            <H3>{t('superAdminPanel.tenants.form.billings.domesticMobile')}</H3>
          }
          isInvalid={
            tenantApiErrors.billing_configuration?.domestic_mobile_call_price
              ? true
              : false
          }
          error={
            tenantApiErrors.billing_configuration?.domestic_mobile_call_price
          }
        >
          <EuiFieldNumber
            readOnly={detailedView}
            isInvalid={
              tenantApiErrors.billing_configuration?.domestic_mobile_call_price
                ? true
                : false
            }
            value={billingForm.domestic_mobile_call_price}
            onChange={handlePriceChange('domestic_mobile_call_price')}
          />
        </FormRow>

        <FormRow
          label={
            <H3>
              {t('superAdminPanel.tenants.form.billings.internationalMobile')}
            </H3>
          }
          isInvalid={
            tenantApiErrors.billing_configuration
              ?.international_mobile_call_price
              ? true
              : false
          }
          error={
            tenantApiErrors.billing_configuration
              ?.international_mobile_call_price
          }
        >
          <EuiFieldNumber
            readOnly={detailedView}
            isInvalid={
              tenantApiErrors.billing_configuration
                ?.international_mobile_call_price
                ? true
                : false
            }
            value={billingForm.international_mobile_call_price}
            onChange={handlePriceChange('international_mobile_call_price')}
          />
        </FormRow>

        <BillingFormBorderBox>
          <H3 $selected>
            {t('superAdminPanel.tenants.form.billings.landline')}
          </H3>
        </BillingFormBorderBox>

        <FormRow
          label={
            <H3>
              {t('superAdminPanel.tenants.form.billings.domesticLandline')}
            </H3>
          }
          isInvalid={
            tenantApiErrors.billing_configuration?.domestic_landline_call_price
              ? true
              : false
          }
          error={
            tenantApiErrors.billing_configuration?.domestic_landline_call_price
          }
        >
          <EuiFieldNumber
            readOnly={detailedView}
            isInvalid={
              tenantApiErrors.billing_configuration
                ?.domestic_landline_call_price
                ? true
                : false
            }
            value={billingForm.domestic_landline_call_price}
            onChange={handlePriceChange('domestic_landline_call_price')}
          />
        </FormRow>

        <FormRow
          label={
            <H3>
              {t('superAdminPanel.tenants.form.billings.internationalLandline')}
            </H3>
          }
          isInvalid={
            tenantApiErrors.billing_configuration
              ?.international_landline_call_price
              ? true
              : false
          }
          error={
            tenantApiErrors.billing_configuration
              ?.international_landline_call_price
          }
        >
          <EuiFieldNumber
            readOnly={detailedView}
            isInvalid={
              tenantApiErrors.billing_configuration
                ?.international_landline_call_price
                ? true
                : false
            }
            value={billingForm.international_landline_call_price}
            onChange={handlePriceChange('international_landline_call_price')}
          />
        </FormRow>

        <BillingFormBorderBox>
          <H3 $selected>SMS</H3>
        </BillingFormBorderBox>

        <FormRow
          label={<H3>{t('superAdminPanel.tenants.form.billings.smsPrice')}</H3>}
          isInvalid={
            tenantApiErrors.billing_configuration?.sms_price ? true : false
          }
          error={tenantApiErrors.billing_configuration?.sms_price}
        >
          <EuiFieldNumber
            readOnly={detailedView}
            isInvalid={
              tenantApiErrors.billing_configuration?.sms_price ? true : false
            }
            value={billingForm.sms_price}
            onChange={handlePriceChange('sms_price')}
          />
        </FormRow>
      </BilingFormRightContainer>
    </TenantFormContainer>
  );
};

export default TenantBillingForm;
