require('./euiIconCache');
import '@elastic/eui/dist/eui_theme_light.css';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { useTranslation } from 'react-i18next';
import {
  selectConfig,
  selectUser,
  redirectToPublic,
  acceptCookies,
  redirectToTenant,
} from './features/session/api/sessionSlice';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { Login } from './features/session/Login';
import {
  AppContainer,
  AppLoadingContainer,
  Center,
  ContentContainer,
  MainContainer,
} from './App.style';
import {
  EuiToast,
  EuiFlexItem,
  EuiButton,
  EuiFlexGroup,
  EuiProvider,
  EuiLoadingSpinner,
} from '@elastic/eui';

import CampaignDetailed from './features/campaigns/CampaignDetailed';
import BotsEdit from './features/bots/configuration/BotsEdit';
import BotsPresets from './features/bots/BotsPresets';
import VinAuxModules from './features/bots/configuration/vindication/VinAuxModules';
import VinIdentification from './features/bots/configuration/vindication/VinIdentification';
import VinNegotiations from './features/bots/configuration/vindication/VinNegotiations';
import VinPaymentStatus from './features/bots/configuration/vindication/VinPaymentStatus';
import VinSummary from './features/bots/configuration/vindication/VinSummary';
import VinVerification from './features/bots/configuration/vindication/VinVerification';
import BotsStartPanel from './features/bots/BotsStartPanel';
import {
  getStrategiesListAsyncThunk,
  selectStrategiesList,
  selectStrategyId,
  setStrategyId,
} from './features/settings/adminPanel/components/strategies/api/strategiesSlice';
import Sidebar from './features/sidebar/Sidebar';
import CreateProcess from './features/processes/CreateProcess';
import ProcessDetailed from './features/processes/ProcessDetailed';
import PresetsPanel from './features/presets/PresetsPanel';
import PasswordReset from './features/session/PasswordReset';
import CallDetailed from './features/campaigns/tabs/calls/detailed/CallDetailed';
import ProcessesPanel from './features/processes/panel/ProcessesPanel';
import CampaignsPanel from './features/campaigns/panel/CampaignsPanel';
import AdminPanel from './features/settings/adminPanel/AdminPanel';
import {
  getUserPermissionsAsyncThunk,
  selectPermissions,
} from './features/users/api/userSlice';
import UserPanel from './features/settings/userSettings/UserSettingsPanel';
import {
  getUserSettingsAsyncThunk,
  selectUserSettings,
} from './features/settings/userSettings/api/userSettingsSlice';
import UserRegister from './features/users/UserRegister';
import ReportsPanel from './features/reports/ReportsPanel';
import { createGlobalStyle } from 'styled-components';
import SuperAdminPanel from './features/settings/superAdminPanel/SuperAdminPanel';
import SuperUserRegister from './features/settings/superAdminPanel/components/superUsers/SuperUserRegister';
import VindicationBuilder from './features/bots/vindication/VindicationBuilder';
import AnalyticsPanel from './features/analytics/AnalyticsPanel';
import DashboardPanel from './features/dashboard/DashboardPanel';
import usePermissions from './features/users/usePermissions';
import NoPermissionsScreen from '../common/NoPermissionsScreen';
import { unwrapResult } from '@reduxjs/toolkit';

const GlobalStyle = createGlobalStyle`
  .euiFormLabel {
    color: #1a1a1a !important;
  }
`;

function PublicApp() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user.state === 'pending') {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [user.state]);

  useEffect(() => {
    if (user.value) {
      const userValue = user.value;
      if (
        !userValue.is_super_admin &&
        !userValue.is_supervisor &&
        userValue.companies.length > 0
      ) {
        dispatch(redirectToTenant(userValue.companies[0].slug));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user.value) {
      const userValue = user.value;
      if (
        !userValue.is_super_admin &&
        !userValue.is_supervisor &&
        userValue.companies.length > 0
      ) {
        dispatch(redirectToTenant(userValue.companies[0].slug));
      }
    }
  }, [user]);

  return (
    <BrowserRouter>
      {loading && (
        <AppLoadingContainer>
          <EuiLoadingSpinner size={'xxl'} />
        </AppLoadingContainer>
      )}
      {user.value ? (
        <Routes>
          {user.value.is_super_admin && (
            <Route path="/*" element={<SuperAdminPanel />} />
          )}
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/invitations/:token" element={<UserRegister />} />
          <Route
            path="/invitations-admin/:token"
            element={<SuperUserRegister />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password/:token" element={<PasswordReset />} />
          <Route path="/invitations/:token" element={<UserRegister />} />
          <Route
            path="/invitations-admin/:token"
            element={<SuperUserRegister />}
          />
        </Routes>
      )}
    </BrowserRouter>
  );
}

function Dashboard() {
  const strategy = useAppSelector(selectStrategyId);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedProcessFilter, setSelectedProcessFilter] =
    useState<string>('');

  const handleSeeCampaignsClick = (id: string) => {
    setSelectedProcessFilter(id);
    navigate('/dashboard/campaigns');
  };

  const userSettings = useAppSelector(selectUserSettings);

  useEffect(() => {
    if (
      userSettings.value?.app_language === 'POLISH' &&
      i18n.language !== 'pl-PL'
    ) {
      i18n.changeLanguage('pl-PL');
    } else if (
      userSettings.value?.app_language === 'ENGLISH' &&
      i18n.language !== 'en-GB'
    ) {
      i18n.changeLanguage('en-GB');
    } else if (
      userSettings.value?.app_language === 'SPANISH' &&
      i18n.language !== 'es-ES'
    ) {
      console.log('no espanol');
    }
  }, [userSettings]);

  const { checkPermission, isLoading } = usePermissions();

  const hasCampaignAccess = checkPermission('campaign');

  if (isLoading) {
    return <div>Loading permissions...</div>;
  }

  return (
    <MainContainer>
      <Sidebar hasCampaignAccess={hasCampaignAccess} strategyId={strategy} />

      <ContentContainer>
        <Routes>
          <Route path="*" element={<Navigate replace to="/" />} />

          <>
            <Route path="/" element={<DashboardPanel />} />

            <Route
              path="/processes"
              element={
                <ProcessesPanel
                  handleSeeCampaignsClick={handleSeeCampaignsClick}
                />
              }
            />
            <Route
              path="/campaigns"
              element={
                <CampaignsPanel
                  selectedProcessFilter={selectedProcessFilter}
                  setSelectedProcessFilter={setSelectedProcessFilter}
                />
              }
            />

            {hasCampaignAccess && (
              <Route path="/reports" element={<ReportsPanel />} />
            )}

            <Route path="/process/create/:name" element={<CreateProcess />} />
            <Route path="/process/detailed/:id" element={<ProcessDetailed />} />

            <Route path="/presets/:preset" element={<PresetsPanel />} />

            <Route path="/campaigns/:id" element={<CampaignDetailed />} />
            {hasCampaignAccess && (
              <Route
                path="/campaigns/:campaign_id/calls/:call_id"
                element={<CallDetailed />}
              />
            )}

            <Route path="/user_settings/*" element={<UserPanel />} />

            <Route path="/analytics" element={<AnalyticsPanel />} />
            {/* old */}
            <Route path="/bots" element={<BotsStartPanel />} />

            <>
              <Route path="/bots/edit/:id" element={<BotsEdit />} />
              <Route path="/bots/new" element={<BotsPresets />} />
              <Route path="/bots/new/1/1" element={<VinIdentification />} />
              <Route path="/bots/new/1/2" element={<VinVerification />} />
              <Route path="/bots/new/1/3" element={<VinPaymentStatus />} />
              <Route path="/bots/new/1/4" element={<VinNegotiations />} />
              <Route path="/bots/new/1/5" element={<VinAuxModules />} />
              <Route path="/bots/new/1/6" element={<VinSummary />} />
            </>
          </>
        </Routes>
      </ContentContainer>
    </MainContainer>
  );
}

function TenantApp() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const config = useAppSelector(selectConfig);
  const strategy = useAppSelector(selectStrategyId);
  const userPermissions = useAppSelector(selectPermissions);

  if (
    user.state === 'idle' &&
    (!user.value ||
      !(user.value?.companies || []).some(
        (tenant) => tenant.slug === config.tenantSubdomain,
      ))
  ) {
    dispatch(redirectToPublic());
    return <></>;
  }

  //strategy logic

  const strategiesList = useAppSelector(selectStrategiesList);

  useEffect(() => {
    if (strategy === '') {
      dispatch(getStrategiesListAsyncThunk())
        .then(unwrapResult)
        .then((res) => {
          if (res.length === 0) {
            setLoading(false);
            localStorage.setItem('strategy', '');
          }
        });
    }
  }, [strategy]);

  useEffect(() => {
    if (strategy === '' && strategiesList.length > 0) {
      dispatch(setStrategyId(strategiesList[0].id));
    } else if (strategy) {
      dispatch(setStrategyId(strategy));
    }
  }, [strategiesList]);

  //loading logic
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (strategy) {
      const fetchData = async () => {
        await dispatch(getUserSettingsAsyncThunk());
        await dispatch(getUserPermissionsAsyncThunk(strategy));
        setLoading(false);
      };

      fetchData();
    }
  }, [strategy, dispatch]);

  const isAdmin = () => {
    return (
      userPermissions &&
      (userPermissions.is_tenant_admin || userPermissions.is_tenant_owner)
    );
  };
  const noStrategies = strategy === '' && strategiesList.length === 0;
  const noPermissions =
    userPermissions &&
    Object.values(userPermissions).every((value) => value === false);

  if (loading) {
    return (
      <Center>
        <EuiLoadingSpinner size={'xxl'} />
      </Center>
    );
  }

  if (noStrategies || noPermissions) {
    return <NoPermissionsScreen />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard/*" element={<Dashboard />} />

        {isAdmin() && <Route path="/tenant_panel/*" element={<AdminPanel />} />}
        <Route path="/builder/vindication/*" element={<VindicationBuilder />} />
        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);

  const { t } = useTranslation();

  return (
    <EuiProvider colorMode={'light'}>
      <GlobalStyle />
      <AppContainer>
        {config?.state === 'idle' && config?.value ? (
          config.tenantSubdomain ? (
            <TenantApp />
          ) : (
            <PublicApp />
          )
        ) : (
          <div />
        )}

        {/* cookies */}
        {config.showCookieInfo && (
          <EuiToast
            title={t('cookiesToastTitle')}
            style={{
              position: 'fixed',
              bottom: 20,
              right: 20,
              width: 350,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <EuiFlexGroup justifyContent="flexEnd" gutterSize="m">
              <EuiFlexItem grow={false}>
                <EuiButton
                  size="s"
                  style={{ margin: 6 }}
                  onClick={() => dispatch(acceptCookies())}
                >
                  Ok
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiToast>
        )}
      </AppContainer>
    </EuiProvider>
  );
}

export default App;
