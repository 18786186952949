import React, { useEffect, useState } from 'react';
import {
  CampaignsTableContainer,
  CampaignsPanelContainer,
} from './CampaignsPanel.style';
import CampaignsBar from './bar/CampaignsBar';
import { PaginatedCampaignOverviewList } from '../../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  selectStrategiesList,
  selectStrategyId,
} from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../processes/api/processSlice';
import CampaignsTable from './CampaignsTable';
import CampaignCreateModal from '../CampaignCreateModal';
import {
  checkCampaignCreate,
  endCreatingCampaign,
  selectCampaignsPanelList,
  startCreatingCampaign,
} from '../api/campaignsSlice';
import { useNavigate } from 'react-router-dom';
import { CampaingsFiltersValues } from './bar/filters/CampaignsFilters';
import ErrorPopup from '../../../../common/popups/ErrorPopup';
import usePermissions from '../../users/usePermissions';

interface CampaignsPanelProps {
  selectedProcessFilter: string;
  setSelectedProcessFilter: React.Dispatch<React.SetStateAction<string>>;
}

const CampaignsPanel: React.FC<CampaignsPanelProps> = ({
  selectedProcessFilter,
  setSelectedProcessFilter,
}) => {
  const strategyId = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkPermission } = usePermissions();
  const hasCampaignAccess = checkPermission('campaign');

  //api
  const [campaigns, setCampaigns] = useState<PaginatedCampaignOverviewList>();

  const campaignsPerPage = 12;

  //gets campaigns and processes list from api
  useEffect(() => {
    dispatch(
      getProcessesListAsyncThunk({
        id: strategyId,
        query: { is_archived: true },
      }),
    );
  }, []);

  const apiProcessesList = useAppSelector(selectProcessesList);
  const apiCampaignsList = useAppSelector(selectCampaignsPanelList);
  const apiStrategiesList = useAppSelector(selectStrategiesList);

  useEffect(() => {
    setCampaigns(apiCampaignsList);
  }, [apiCampaignsList]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (campaigns && loading) {
      setLoading(false);
    }
  }, [campaigns]);

  const closeCampaignCreateModal = () => {
    dispatch(endCreatingCampaign());
  };
  const showCampaignCreateModal = () => {
    dispatch(startCreatingCampaign());
  };

  const checkCampaign = useAppSelector(checkCampaignCreate);
  useEffect(() => {
    if (
      checkCampaign?.state === 'idle' &&
      checkCampaign.value?.status === 'success' &&
      checkCampaign.value.campaign
    ) {
      closeCampaignCreateModal();
      navigate(`/dashboard/campaigns/${checkCampaign.value.campaign.id}`, {
        state: { showCreatePopup: true },
      });
    }

    if (
      checkCampaign?.state === 'idle' &&
      checkCampaign.value?.status === 'fail'
    ) {
      closeCampaignCreateModal();
      showCampaignCreateErrorPopup();
    }
  }, [checkCampaign]);

  //error popup
  const [campaignCreateErrorPopupVisible, setCampaignCreateErrorPopupVisible] =
    useState<boolean>(false);

  const showCampaignCreateErrorPopup = () =>
    setCampaignCreateErrorPopupVisible(true);
  const closeCampaignCreateErrorPopup = () =>
    setCampaignCreateErrorPopupVisible(false);

  const [filters, setFilters] = useState<CampaingsFiltersValues>({
    processes: undefined,
    statuses: undefined,
    date: undefined,
    search: '',
  });

  useEffect(() => {
    if (selectedProcessFilter) {
      setFilters({ ...filters, processes: [selectedProcessFilter] });
      setSelectedProcessFilter('');
    }
  }, []);

  return (
    <CampaignsPanelContainer>
      {apiProcessesList.length > 0 && (
        <CampaignCreateModal
          isVisible={checkCampaign?.state ? true : false}
          closeModal={closeCampaignCreateModal}
          processesList={apiProcessesList}
          strategiesList={apiStrategiesList}
        />
      )}
      {/* top bar with filters */}
      <CampaignsBar
        filters={filters}
        setFilters={setFilters}
        processList={apiProcessesList}
        showCampaignCreateModal={showCampaignCreateModal}
        hasCampaignAccess={hasCampaignAccess}
      />

      <CampaignsTableContainer>
        {campaignCreateErrorPopupVisible && (
          <ErrorPopup
            onClose={closeCampaignCreateErrorPopup}
            text={`Sorry, an error occured! Campaign wasn't created`}
            textLine2={'Please try again'}
          />
        )}

        <CampaignsTable
          strategyId={strategyId}
          processesList={apiProcessesList}
          campaigns={campaigns}
          campaignsPerPage={campaignsPerPage}
          filters={filters}
          loading={loading}
          setLoading={setLoading}
        />
      </CampaignsTableContainer>
    </CampaignsPanelContainer>
  );
};

export default CampaignsPanel;
