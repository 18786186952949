import { EuiFieldText } from '@elastic/eui';
import React from 'react';
import { H3 } from '../../../App.style';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import { VoiceConfigurationSnapshot } from '../../../../generated/tenants/Api';
import { SnapshotContainer } from '../Campaigns.style';
import { useTranslation } from 'react-i18next';

interface VoiceSnapshotProps {
  voice?: VoiceConfigurationSnapshot;
}

const VoiceSnapshot: React.FC<VoiceSnapshotProps> = ({ voice }) => {
  const { t } = useTranslation();
  return (
    <SnapshotContainer>
      {voice?.name && (
        <FormRow label={<H3>{t('presets.voicesForm.name')}</H3>}>
          <EuiFieldText readOnly value={voice?.name} />
        </FormRow>
      )}

      <FormRow label={<H3>{t('presets.voicesForm.language')}</H3>}>
        <EuiFieldText readOnly value={voice?.language} />
      </FormRow>

      <FormRow label={<H3>{t('presets.voicesForm.gender')}</H3>}>
        <EuiFieldText
          readOnly
          value={
            voice?.gender === 'MALE'
              ? t('processes.form.voices.male')
              : t('processes.form.voices.female')
          }
        />
      </FormRow>

      <FormRow label={<H3>{t('presets.voicesForm.baseTts')}</H3>}>
        <EuiFieldText readOnly value={voice?.base_tts_speed_percentage} />
      </FormRow>

      <FormRow label={<H3>{t('presets.voicesForm.asrVendor')}</H3>}>
        <EuiFieldText readOnly value={voice?.asr_vendor} />
      </FormRow>

      <FormRow label={<H3>{t('presets.voicesForm.ttsVendor')}</H3>}>
        <EuiFieldText readOnly value={voice?.tts_vendor} />
      </FormRow>

      <FormRow label={<H3>{t('presets.voicesForm.voiceName')}</H3>}>
        <EuiFieldText readOnly value={voice?.voice_name} />
      </FormRow>
    </SnapshotContainer>
  );
};

export default VoiceSnapshot;
