import { EuiPopover } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import {
  SidebarContainer,
  SidebarLektaLogoFont,
  SidebarLinkContainer,
  SidebarLogoContainer,
  SidebarSpacer,
  SidebarTenantNameContainer,
  StrategyContainer,
  UserPopoverButton,
  UserPopoverContainer,
  UserProfileContainer,
  UserProfileNameContainer,
} from './Sidebar.style';

import { COLOR_WHITE, H3, H4 } from '../../App.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  checkProcessCreate,
  checkProcessUpdate,
  endCreateProcess,
  endUpdateProcess,
} from '../processes/api/processSlice';

import { ReportsIcon } from '../../../resources/icons-new/ReportsIcon';
import { ProcessesIcon } from '../../../resources/icons-new/ProcessesIcon';
import { CampaignsIcon } from '../../../resources/icons-new/CampaignsIcon';
import { selectUserSettings } from '../settings/userSettings/api/userSettingsSlice';

import {
  logoutAsyncThunk,
  redirectToTenant,
  selectConfig,
  selectUser,
} from '../session/api/sessionSlice';
import { UserAvatarImg, UserAvatarDiv } from '../users/Users.style';
import { ChevronRightIcon } from '../../../resources/icons-new/ChevronRIght';
import PuzzleIcon from '../../../resources/icons-new/PuzzleIcon';
import { AnalyticsIcon } from '../../../resources/icons-new/AnalyticsIcon';
import { useTranslation } from 'react-i18next';
import { DashboardIcon } from '../../../resources/icons-new/DashboardIcon';
import { LektaLogoIcon } from '../../../resources/icons-new/LektaLogoIcon';
import { Strategy } from '../../../generated/tenants/Api';
import StrategyNavbarSelect from '../settings/adminPanel/components/strategies/StrategyNavbarSelect';
import { selectStrategiesList } from '../settings/adminPanel/components/strategies/api/strategiesSlice';

const presets_entrypoint = '/dashboard/presets/voices';
const reports_entrypoint = '/dashboard/reports';
const processes_entrypoint = '/dashboard/processes';
const campaigns_entrypoint = '/dashboard/campaigns';
const analytics_entrypoint = '/dashboard/analytics';
const dashboard_entrypoint = '/dashboard';

interface SidebarProps {
  hasCampaignAccess: boolean;
  strategyId: string;
}

const Sidebar: React.FC<SidebarProps> = ({ hasCampaignAccess, strategyId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [blocked, setBlocked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string,
  ) => {
    if (blocked) {
      event.preventDefault();
      const userConfirmed = window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
      if (userConfirmed) {
        navigate(path);

        dispatch(endCreateProcess());
        dispatch(endUpdateProcess());
        setBlocked(false);
      }
    } else {
      navigate(path);
    }
  };

  const checkCreateProcess = useAppSelector(checkProcessCreate);
  const checkUpdateProcess = useAppSelector(checkProcessUpdate);

  useEffect(() => {
    if (checkCreateProcess?.state) {
      setBlocked(true);
    } else if (checkUpdateProcess?.state) {
      setBlocked(true);
    } else {
      setBlocked(false);
    }
  }, [checkCreateProcess, checkUpdateProcess]);

  //user popover
  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState<boolean>(false);
  const handleUserPopoverClick = () => setIsUserPopoverOpen((prev) => !prev);
  const closeUserPopover = () => setIsUserPopoverOpen(false);

  const userSettings = useAppSelector(selectUserSettings);
  const user = useAppSelector(selectUser);

  const handleNavigate = (path: string) => {
    if (blocked) {
      const userConfirmed = window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
      if (userConfirmed) {
        navigate(path);

        dispatch(endCreateProcess());
        dispatch(endUpdateProcess());
        setBlocked(false);
        closeUserPopover();
      }
    } else {
      navigate(path);
      closeUserPopover();
    }
  };

  const handleGoToSAPanel = () => {
    if (blocked) {
      const userConfirmed = window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
      if (userConfirmed) {
        dispatch(redirectToTenant(''));

        dispatch(endCreateProcess());
        dispatch(endUpdateProcess());
        setBlocked(false);
        closeUserPopover();
      }
    } else {
      dispatch(redirectToTenant(''));
      closeUserPopover();
    }
  };

  const handleLogout = () => {
    if (blocked) {
      const userConfirmed = window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
      if (userConfirmed) {
        dispatch(logoutAsyncThunk());

        dispatch(endCreateProcess());
        dispatch(endUpdateProcess());
        setBlocked(false);
        closeUserPopover();
      }
    } else {
      dispatch(logoutAsyncThunk());
      closeUserPopover();
    }
  };

  const [selectedStrategy, setSelectedStrategy] = useState<Strategy>();

  const strategiesList = useAppSelector(selectStrategiesList);

  //if localStorage has invalid strategy ID, reload page and remove from localStorage
  useEffect(() => {
    if (strategiesList.length > 0 && strategyId) {
      const checkStrategy = strategiesList.some((e) => e.id === strategyId);
      if (checkStrategy) {
        const selectedStrategy = strategiesList.find(
          (e) => e.id === strategyId,
        );
        setSelectedStrategy(selectedStrategy);
      } else {
        localStorage.removeItem('strategy');
        window.location.reload();
      }
    }
  }, [strategiesList]);

  const isOnPanelAdmin = location.pathname.startsWith('/tenant_panel');
  const isOnUserSettings = location.pathname.startsWith('/user_settings');

  const config = useAppSelector(selectConfig);
  const tenant = config.tenantSubdomain;

  return (
    <SidebarContainer>
      <SidebarLogoContainer onClick={() => handleNavigate('/dashboard')}>
        <LektaLogoIcon color="#ffffff" />
        <SidebarLektaLogoFont>Lease</SidebarLektaLogoFont>
      </SidebarLogoContainer>

      <SidebarTenantNameContainer>
        <H3 $semibold>{tenant}</H3>
      </SidebarTenantNameContainer>

      {user.value && !isOnPanelAdmin && !isOnUserSettings && (
        <div style={{ width: '100', marginBottom: 16 }}>
          {config.tenantSubdomain && (
            <>
              {selectedStrategy && strategiesList.length > 0 && (
                <StrategyContainer>
                  <StrategyNavbarSelect
                    selectedStrategy={selectedStrategy}
                    setSelectedStrategy={setSelectedStrategy}
                    strategiesList={strategiesList}
                  />
                </StrategyContainer>
              )}
            </>
          )}
        </div>
      )}

      <SidebarSpacer />

      <SidebarLinkContainer
        $selected={location.pathname === dashboard_entrypoint}
        to={dashboard_entrypoint}
        onClick={(e) => handleClick(e, dashboard_entrypoint)}
      >
        <DashboardIcon color={COLOR_WHITE} />
        <H3 $selected={location.pathname === dashboard_entrypoint}>
          {t('sidebar.dashboard')}
        </H3>
      </SidebarLinkContainer>
      <SidebarLinkContainer
        $selected={location.pathname.startsWith(processes_entrypoint)}
        to={processes_entrypoint}
        onClick={(e) => handleClick(e, processes_entrypoint)}
      >
        <ProcessesIcon color={COLOR_WHITE} />
        <H3 $selected={location.pathname === processes_entrypoint}>
          {t('sidebar.processes')}
        </H3>
      </SidebarLinkContainer>

      <SidebarLinkContainer
        $selected={location.pathname.startsWith(campaigns_entrypoint)}
        to={campaigns_entrypoint}
        onClick={(e) => handleClick(e, campaigns_entrypoint)}
      >
        <CampaignsIcon color={COLOR_WHITE} />
        <H3 $selected={location.pathname === campaigns_entrypoint}>
          {t('sidebar.campaigns')}
        </H3>
      </SidebarLinkContainer>

      <SidebarLinkContainer
        $selected={location.pathname.startsWith(analytics_entrypoint)}
        to={analytics_entrypoint}
        onClick={(e) => handleClick(e, analytics_entrypoint)}
      >
        <AnalyticsIcon color={COLOR_WHITE} />

        <H3 $selected={location.pathname.includes('/analytics')}>
          {t('analytics.panel.title')}
        </H3>
      </SidebarLinkContainer>

      {hasCampaignAccess && (
        <SidebarLinkContainer
          $selected={location.pathname.startsWith(reports_entrypoint)}
          to={reports_entrypoint}
          onClick={(e) => handleClick(e, reports_entrypoint)}
        >
          <ReportsIcon color={COLOR_WHITE} />
          <H3 $selected={location.pathname == reports_entrypoint}>
            {t('sidebar.reports')}
          </H3>
        </SidebarLinkContainer>
      )}

      <SidebarSpacer />

      <SidebarLinkContainer
        $selected={location.pathname.startsWith(presets_entrypoint)}
        to={presets_entrypoint}
        onClick={(e) => handleClick(e, presets_entrypoint)}
      >
        <PuzzleIcon color={COLOR_WHITE} />
        <H3 $selected={location.pathname.includes('/presets')}>
          {t('sidebar.presets')}
        </H3>
      </SidebarLinkContainer>

      <UserPopoverContainer>
        {user.value && userSettings.value && (
          <EuiPopover
            ownFocus={false}
            button={
              <div style={{ paddingRight: 5 }}>
                <UserProfileContainer onClick={handleUserPopoverClick}>
                  {userSettings.value.avatar ? (
                    <UserAvatarImg src={userSettings.value.avatar} />
                  ) : (
                    <UserAvatarDiv color={userSettings.value.accent_color}>
                      <H4 $medium style={{ color: 'white' }}>
                        {userSettings.value.initials
                          ? userSettings.value.initials
                          : 'SA'}
                      </H4>
                    </UserAvatarDiv>
                  )}

                  <UserProfileNameContainer>
                    <H4 $medium>{user.value.first_name}</H4>
                    <H4 $medium>{user.value.last_name}</H4>
                  </UserProfileNameContainer>

                  <ChevronRightIcon color="#FFF" />
                </UserProfileContainer>
              </div>
            }
            isOpen={isUserPopoverOpen}
            closePopover={closeUserPopover}
            anchorPosition="rightCenter"
            panelPaddingSize="none"
            hasArrow={false}
          >
            <div style={{ width: 120, overflow: 'hidden' }}>
              <UserPopoverButton
                $radiusTop
                onClick={() => handleNavigate('/dashboard/user_settings/')}
              >
                <H3>{t('sidebar.popover.settings')}</H3>
              </UserPopoverButton>

              {user.value.is_super_admin && (
                <UserPopoverButton onClick={handleGoToSAPanel}>
                  <H3>{t('sidebar.popover.superAdmin')}</H3>
                </UserPopoverButton>
              )}

              <UserPopoverButton $radiusBottom onClick={handleLogout}>
                <H3>{t('sidebar.popover.logout')}</H3>
              </UserPopoverButton>
            </div>
          </EuiPopover>
        )}
      </UserPopoverContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
