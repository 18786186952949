import { EuiToolTip } from '@elastic/eui';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../../../common/hooks';
import { Button, ButtonEmpty, COLOR_PRIMARY_1, H1, H2 } from '../../App.style';
import { Process, ProcessCreateRequest } from '../../../generated/tenants/Api';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import CallingPreferencesForm from './forms/CallingPreferencesForm';
import CampaignPreferencesForm from './forms/CampaignPreferencesForm';
import DialoguePresetForm from './forms/DialoguePresetForm';
import GeneralForm from './forms/GeneralForm';
import {
  ProcessFormContentContainer,
  TabButton,
  Content,
  Sidebar,
  ButtonsContainer,
  ProcessTitleContainer,
  ProcessFormContainer,
} from './forms/ProcessForm.style';
import VoicePresetForm from './forms/VoicePresetForm';
import CalendarForm from './forms/CalendarForm';
import {
  checkProcessCreate,
  checkProcessUpdate,
  startUpdateProcess,
} from './api/processSlice';
import { ApiError } from '../../../common/types';
import { useDispatch } from 'react-redux';
import { CheckIcon } from '../../../resources/icons-new/CheckIcon';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon } from '../../../resources/icons-new/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';

const useProcessApiErrors = () => {
  const checkCreate = useAppSelector(checkProcessCreate);
  const createErrors = checkCreate?.value?.error;

  const checkUpdate = useAppSelector(checkProcessUpdate);
  const updateErrors = checkUpdate?.value?.error;

  const [processApiErrors, setProcessApiErrors] = useState<ApiError<Process>>(
    {} as ApiError<Process>,
  );

  useEffect(() => {
    if (createErrors)
      setProcessApiErrors((prevErrors) => ({ ...prevErrors, ...createErrors }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setProcessApiErrors((prevErrors) => ({ ...prevErrors, ...updateErrors }));
  }, [updateErrors]);

  return [processApiErrors, setProcessApiErrors] as const;
};

export type ProcessTab =
  | 'General'
  | 'Dialogue'
  | 'Voice'
  | 'Calling Preferences'
  | 'Campaign Preferences'
  | 'Calendar';

interface ProcessFormProps {
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  button: () => void;
  cancelButton?: () => void;
  detailed: boolean;
  editOnStart?: boolean;
  edit?: boolean;
  hasProcessAcceess: boolean;
  handleStartEdit?: () => void;
  handleStopEdit?: () => void;
}

const ProcessForm: React.FC<ProcessFormProps> = ({
  process,
  setProcess,
  button,
  cancelButton,
  detailed,
  editOnStart,
  edit,
  handleStartEdit,
  handleStopEdit,
  hasProcessAcceess,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const [selectedTab, setSelectedTab] = useState<ProcessTab>('General');

  const tabs: ProcessTab[] = [
    'General',
    'Dialogue',
    'Voice',
    'Calling Preferences',
    'Campaign Preferences',
    'Calendar',
  ];

  const handleTabClick = (tab: ProcessTab) => {
    setSelectedTab(tab);
  };

  const [processApiErrors, setProcessApiErrors] = useProcessApiErrors();

  useEffect(() => {
    setProcessApiErrors({} as ApiError<Process>);
  }, []);

  useEffect(() => {
    if (editOnStart) {
      handleStartEdit && handleStartEdit();
      dispatch(startUpdateProcess());
    }
  }, [editOnStart]);

  const renderForm = (tab: ProcessTab) => {
    switch (tab) {
      case 'General':
        return (
          <GeneralForm
            process={process}
            setProcess={setProcess}
            detailed={detailed}
            edit={edit}
            apiErrors={processApiErrors}
            setApiErrors={setProcessApiErrors}
          />
        );
      case 'Dialogue':
        return (
          <DialoguePresetForm
            strategy={strategy}
            process={process}
            setProcess={setProcess}
            edit={edit}
          />
        );
      case 'Voice':
        return (
          <VoicePresetForm
            strategy={strategy}
            process={process}
            setProcess={setProcess}
            edit={edit}
          />
        );
      case 'Calling Preferences':
        return (
          <CallingPreferencesForm
            strategy={strategy}
            process={process}
            setProcess={setProcess}
            edit={edit}
          />
        );
      case 'Campaign Preferences':
        return (
          <CampaignPreferencesForm
            process={process}
            setProcess={setProcess}
            edit={edit}
            apiErrors={processApiErrors}
            setApiErrors={setProcessApiErrors}
          />
        );
      case 'Calendar':
        return (
          <CalendarForm
            strategy={strategy}
            process={process}
            setProcess={setProcess}
            edit={edit}
          />
        );
      default:
        return null;
    }
  };

  const [tabsCompleted, setTabsCompleted] = useState({
    General: false,
    Dialogue: false,
    Voice: false,
    'Calling Preferences': false,
    'Campaign Preferences': false,
    Calendar: false,
  });

  useEffect(() => {
    setTabsCompleted({
      General: process.name !== '',
      Dialogue: process.bot_configuration !== '',
      Voice: process.voice_configuration !== '',
      'Calling Preferences':
        process.calling_configuration !== '' &&
        process.phone_numbers !== undefined &&
        process.phone_numbers.length > 0,
      'Campaign Preferences': process.redirection_phone_number !== '',
      Calendar:
        process.calendar_configuration !== '' &&
        process.call_center_non_working_days_configuration !== '' &&
        process.campaign_non_working_days_configuration !== '',
    });
  }, [process]);

  const checkAllTabs = () => {
    return Object.values(tabsCompleted).every((val) => val === true);
  };

  const handleModifyClick = () => {
    handleStartEdit && handleStartEdit();
    dispatch(startUpdateProcess());
  };

  // const [edit, setEdit] = useState<boolean>();
  useEffect(() => {
    if (detailed && !editOnStart) {
      handleStopEdit && handleStopEdit();
    } else {
      handleStartEdit && handleStartEdit();
    }
  }, [detailed]);

  const handleCancelClick = () => {
    cancelButton && cancelButton();
    handleStopEdit && handleStopEdit();
  };

  const publishButtonIsDisabled = !checkAllTabs() as boolean;

  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      if (edit || publishButtonIsDisabled) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [edit, publishButtonIsDisabled]);

  const renderTabName = (tab: ProcessTab) => {
    switch (tab) {
      case 'General':
        return t('processes.form.tabs.general');
      case 'Dialogue':
        return t('processes.form.tabs.dialogue');
      case 'Voice':
        return t('processes.form.tabs.voice');
      case 'Calling Preferences':
        return t('processes.form.tabs.callingPreferences');
      case 'Campaign Preferences':
        return t('processes.form.tabs.campaignPreferences');
      case 'Calendar':
        return t('processes.form.tabs.calendar');
    }
  };

  return (
    <ProcessFormContainer>
      <ProcessTitleContainer>
        <div style={{ display: 'flex', gap: 16 }}>
          {!edit && (
            <Button
              $size="S"
              $styleType="NORMAL"
              onClick={() => navigate('/dashboard/processes')}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          <H1 $bold style={{ marginBottom: 5 }}>
            {!edit
              ? t('processes.form.detailedTitle')
              : t('processes.form.title')}{' '}
            {process.name}
          </H1>
        </div>

        <ButtonsContainer>
          {!detailed && (
            <EuiToolTip
              position="top"
              content={
                publishButtonIsDisabled
                  ? t('processes.form.buttonDisabledText')
                  : ''
              }
            >
              <Button
                $size="M"
                disabled={publishButtonIsDisabled}
                onClick={button}
              >
                {t('processes.form.publishProcess')}
              </Button>
            </EuiToolTip>
          )}

          {detailed && hasProcessAcceess && (
            <>
              {edit ? (
                <div style={{ display: 'flex' }}>
                  <ButtonEmpty onClick={handleCancelClick}>
                    {t('common.cancel')}
                  </ButtonEmpty>
                  <Button
                    $size="M"
                    disabled={publishButtonIsDisabled}
                    onClick={button}
                  >
                    {t('common.saveChanges')}
                  </Button>
                </div>
              ) : (
                <Button
                  $size="M"
                  $styleType="NORMAL"
                  onClick={handleModifyClick}
                >
                  {t('processes.form.editProcess')}
                </Button>
              )}
            </>
          )}
        </ButtonsContainer>
      </ProcessTitleContainer>

      <ProcessFormContentContainer>
        <Sidebar>
          {tabs.map((tab) => (
            <TabButton
              key={tab}
              $selected={tab === selectedTab}
              onClick={() => handleTabClick(tab)}
            >
              <H2 $selected={tab === selectedTab}>{renderTabName(tab)}</H2>
              {tabsCompleted[tab] && <CheckIcon color={COLOR_PRIMARY_1} />}
            </TabButton>
          ))}
        </Sidebar>
        <Content> {renderForm(selectedTab)} </Content>
      </ProcessFormContentContainer>
    </ProcessFormContainer>
  );
};

export default ProcessForm;
