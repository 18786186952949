import {
  EuiFieldNumber,
  EuiFieldText,
  EuiRadioGroup,
  EuiSuperSelect,
} from '@elastic/eui';
import React, { useEffect } from 'react';
import { H3, H4 } from '../../../App.style';
import {
  Process,
  ProcessCreateRequest,
  RedirectionPresentationModeEnum,
} from '../../../../generated/tenants/Api';
import { FormContainer, FormRow } from './ProcessForm.style';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../../../common/types';

interface CampaignPreferencesFormProps {
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  edit?: boolean;
  apiErrors: ApiError<Process>;
  setApiErrors: React.Dispatch<React.SetStateAction<ApiError<Process>>>;
}

const CampaignPreferencesForm: React.FC<CampaignPreferencesFormProps> = ({
  process,
  setProcess,
  edit,
  apiErrors,
  setApiErrors,
}) => {
  const { t } = useTranslation();
  const directTransferToCC = ['Call Center', 'Bot'];
  const directTransferToCcOptions = directTransferToCC.map((text) => {
    return {
      value: text,
      inputDisplay: text,
      dropdownDisplay: <H3>{text}</H3>,
    };
  });

  const redirectionPresentationOptions = [
    {
      value: 'CALLER',
      inputDisplay: t('processes.form.campaignPreferences.caller'),
      dropdownDisplay: (
        <H3>{t('processes.form.campaignPreferences.caller')}</H3>
      ),
    },
    {
      value: 'CAMPAIGN',
      inputDisplay: t('processes.form.campaignPreferences.campaign'),
      dropdownDisplay: (
        <H3>{t('processes.form.campaignPreferences.campaign')}</H3>
      ),
    },
  ];

  const inboundEnabledForClosedRecordOptions = [
    {
      id: 'inbound_enabled_for_closed',
      label: <H4>{t('processes.form.general.yes')}</H4>,
    },
    {
      id: 'inbound_disabled_for_closed',
      label: <H4>{t('processes.form.general.no')}</H4>,
    },
  ];

  useEffect(() => {
    if (process.redirection_phone_number === '')
      setProcess({ ...process, redirection_phone_number: '+48123456789' });
  }, []);

  {
    t('processes.form.campaignPreferences.inboundEnabled');
  }

  return (
    <FormContainer $processForm>
      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.inboundEnabled')}</H3>
        }
        isInvalid={apiErrors.inbound_enabled_for_closed_record ? true : false}
        error={apiErrors.inbound_enabled_for_closed_record}
      >
        <EuiRadioGroup
          disabled={!edit}
          id="inbound_enabled_for_closed_record"
          options={inboundEnabledForClosedRecordOptions}
          idSelected={
            process.inbound_enabled_for_closed_record
              ? 'inbound_enabled_for_closed'
              : 'inbound_disabled_for_closed'
          }
          onChange={(id) => {
            setProcess({
              ...process,
              inbound_enabled_for_closed_record:
                id === 'inbound_enabled_for_closed' ? true : false,
            });
            setApiErrors({
              ...apiErrors,
              inbound_enabled_for_closed_record: undefined,
            });
          }}
        />
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.directTransfer')}</H3>
        }
        isInvalid={apiErrors.direct_transfer_to_cc ? true : false}
        error={apiErrors.direct_transfer_to_cc}
      >
        {!edit ? (
          <EuiFieldText
            value={process.direct_transfer_to_cc ? 'Call Center' : 'Bot'}
            readOnly
          />
        ) : (
          <EuiSuperSelect
            isInvalid={apiErrors.direct_transfer_to_cc ? true : false}
            options={directTransferToCcOptions}
            valueOfSelected={
              process.direct_transfer_to_cc ? 'Call Center' : 'Bot'
            }
            onChange={(e) => {
              setProcess({
                ...process,
                direct_transfer_to_cc: e === 'Call Center' ? true : false,
              });
              setApiErrors({
                ...apiErrors,
                direct_transfer_to_cc: undefined,
              });
            }}
          />
        )}
      </FormRow>

      <FormRow
        label={<H3>{t('processes.form.campaignPreferences.redirect')}</H3>}
        isInvalid={apiErrors.redirection_presentation_mode ? true : false}
        error={apiErrors.redirection_presentation_mode}
      >
        {!edit ? (
          <EuiFieldText
            value={
              process.redirection_presentation_mode === 'CALLER'
                ? t('processes.form.campaignPreferences.caller')
                : t('processes.form.campaignPreferences.campaign')
            }
            readOnly
          />
        ) : (
          <EuiSuperSelect
            isInvalid={apiErrors.redirection_presentation_mode ? true : false}
            options={redirectionPresentationOptions}
            valueOfSelected={process.redirection_presentation_mode}
            onChange={(e) => {
              setProcess({
                ...process,
                redirection_presentation_mode:
                  e as RedirectionPresentationModeEnum,
              });
              setApiErrors({
                ...apiErrors,
                redirection_presentation_mode: undefined,
              });
            }}
          />
        )}
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.campaignDuration')}</H3>
        }
        isInvalid={apiErrors.default_campaign_duration_days ? true : false}
        error={apiErrors.default_campaign_duration_days}
      >
        <EuiFieldNumber
          isInvalid={apiErrors.default_campaign_duration_days ? true : false}
          readOnly={!edit}
          value={process.default_campaign_duration_days}
          onChange={(e) => {
            setProcess({
              ...process,
              default_campaign_duration_days: Number(e.target.value),
            });
            setApiErrors({
              ...apiErrors,
              default_campaign_duration_days: undefined,
            });
          }}
          min={1}
        />
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.callCenterNumber')}</H3>
        }
        isInvalid={apiErrors.redirection_phone_number ? true : false}
        error={apiErrors.redirection_phone_number}
      >
        <>
          <EuiFieldText
            isInvalid={apiErrors.redirection_phone_number ? true : false}
            readOnly={!edit}
            value={
              process.redirection_phone_number === null
                ? ''
                : process.redirection_phone_number
            }
            onChange={(e) => {
              setProcess({
                ...process,
                redirection_phone_number: e.target.value,
              });
              setApiErrors({
                ...apiErrors,
                redirection_phone_number: undefined,
              });
            }}
          />
        </>
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.fallbackMessage')}</H3>
        }
        isInvalid={apiErrors.inbound_fallback_message ? true : false}
        error={apiErrors.inbound_fallback_message}
      >
        <EuiFieldText
          isInvalid={apiErrors.inbound_fallback_message ? true : false}
          readOnly={!edit}
          value={
            process.inbound_fallback_message
              ? process.inbound_fallback_message
              : ''
          }
          onChange={(e) => {
            setProcess({
              ...process,
              inbound_fallback_message: e.target.value,
            });
            setApiErrors({
              ...apiErrors,
              inbound_fallback_message: undefined,
            });
          }}
        />
      </FormRow>
    </FormContainer>
  );
};

export default CampaignPreferencesForm;
