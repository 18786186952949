import { EuiIcon, EuiSuperSelect, EuiTextArea } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { Button, H1, H3, H4 } from '../../../../../App.style';
import {
  NumbersAddNewContentContainer,
  NumbersAddNewButtonContainer,
  PhoneNumbersContainer,
} from '../../../adminPanel/components/phoneNumbers/PhoneNumbers.style';
import { FormRow } from '../../../../processes/forms/ProcessForm.style';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import SuccessPopup from '../../../../../../common/popups/SuccessPopup';
import {
  checkTenantPhoneNumberCreate,
  clearCreateNumber,
  createTenantPhoneNumbersAsyncThunk,
} from '../../../adminPanel/components/phoneNumbers/api/phoneNumbersSlice';
import {
  PhoneNumberCreateRequest,
  PhoneNumberResults,
} from '../../../../../../generated/public/Api';
import { useAutoClosePopup } from '../../../../../../common/popups/useAutoClosePopup';
import ErrorPopup from '../../../../../../common/popups/ErrorPopup';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../../../common/inputs/Checkbox';
import { AddNewNumbersDirectToTenantContainer } from './SuperAdminNumbers.style';
import {
  getTenantsListAsyncThunk,
  selectTenantsList,
} from '../../api/superAdminSlice';
import LoadingModal from '../../../../../../common/modals/LoadingModal';

const NumbersAddNew = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTenantsListAsyncThunk());
  }, []);

  const apiTenantsList = useAppSelector(selectTenantsList);
  const tenantOptions = apiTenantsList.map((tenant) => {
    return { value: tenant.slug, inputDisplay: tenant.slug };
  });

  //operator
  const operatorsOptions = [
    { value: 'twilio-sip-trunk', inputDisplay: 'Twilio' },
    { value: 'gcp-netia-proxy', inputDisplay: 'Netia' },
  ];
  const [selectedOperatorId, setSelectedOperatorId] = useState<string>(
    operatorsOptions[0].value,
  );

  const [phoneNumbers, setPhoneNumbers] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data: PhoneNumberCreateRequest = {
      phone_numbers: phoneNumbers.split(','),
      mobile_operator: selectedOperatorId,
      tenant_slug: directToTenant ? selectedTenantSlug : undefined,
    };

    setNewNumbersErrors(undefined);

    setIsLoadingVisible(true);

    dispatch(createTenantPhoneNumbersAsyncThunk(data)).finally(() => {
      setIsLoadingVisible(false);
    });
    return false;
  };

  const validateButton = (): boolean => {
    return (
      !selectedTenantSlug || !selectedOperatorId || phoneNumbers.length === 0
    );
  };

  const {
    isVisible: successPopupVisible,
    showPopup: showSuccessPopup,
    closePopup: closeSuccessPopup,
  } = useAutoClosePopup();

  const {
    isVisible: errorPopupVisible,
    showPopup: showErrorPopup,
    closePopup: closeErrorPopup,
  } = useAutoClosePopup();

  const checkCreateNumber = useAppSelector(checkTenantPhoneNumberCreate);

  const [newNumbersErrors, setNewNumbersErrors] =
    useState<PhoneNumberResults>();

  useEffect(() => {
    if (
      checkCreateNumber.state === 'idle' &&
      checkCreateNumber.value?.status === 'fail'
    ) {
      showErrorPopup();
      setNewNumbersErrors(undefined);
    }
    if (
      checkCreateNumber.state === 'idle' &&
      checkCreateNumber.value?.status === 'success' &&
      checkCreateNumber.value.error?.already_assigned.length === 0 &&
      checkCreateNumber.value.error.wrong_numbers.length === 0
    ) {
      showSuccessPopup();
      setPhoneNumbers('');
    }

    if (
      checkCreateNumber.state === 'idle' &&
      checkCreateNumber.value?.status === 'success'
    ) {
      setNewNumbersErrors(checkCreateNumber.value.error);
      const assignedNumbers =
        checkCreateNumber.value.error?.already_assigned.join(', ');
      const wrongNumbers =
        checkCreateNumber.value.error?.wrong_numbers.join(', ');

      const combinedNumbers =
        assignedNumbers && wrongNumbers
          ? `${assignedNumbers}, ${wrongNumbers}` // Both are not empty, combine with a comma
          : assignedNumbers || wrongNumbers;
      setPhoneNumbers(combinedNumbers || '');
    }
  }, [checkCreateNumber]);

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = e.clipboardData.getData('text');

    // Split by commas and then by newlines
    const parts = pastedText
      .split(',')
      .flatMap((part) => part.split(/[\r\n]+/));
    const processedNumbers = parts.map((part) =>
      // Remove non-essential characters and trim each number
      part.replace(/[^\d+]/g, '').trim(),
    );

    const formattedText = processedNumbers.join(', ');

    setPhoneNumbers(formattedText);
    e.preventDefault();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Allow only numeric input, specific characters, spaces, and commas
    if (!/[0-9+()-, ]/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearCreateNumber());
      setPhoneNumbers('');
      setNewNumbersErrors(undefined);
    };
  }, []);

  //adding numbers direct to tenant
  const [directToTenant, setDirectToTenant] = useState<boolean>(false);
  const [selectedTenantSlug, setSelectedTenantSlug] = useState<string>('');

  useEffect(() => {
    setSelectedTenantSlug(apiTenantsList[0].slug);
  }, [apiTenantsList]);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <PhoneNumbersContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />

      {successPopupVisible && directToTenant ? (
        <SuccessPopup
          onClose={closeSuccessPopup}
          text={t('superAdminPanel.numbers.add.popups.successAddTenant', {
            tenant: selectedTenantSlug,
          })}
        />
      ) : (
        successPopupVisible && (
          <SuccessPopup
            onClose={closeSuccessPopup}
            text={t('superAdminPanel.numbers.add.popups.successAdd')}
          />
        )
      )}

      {errorPopupVisible && (
        <ErrorPopup
          onClose={closeErrorPopup}
          text={t('superAdminPanel.numbers.add.popups.error')}
        />
      )}

      <H1 $bold>{t('superAdminPanel.numbers.add.title')}</H1>

      <NumbersAddNewContentContainer onSubmit={handleSubmit}>
        <FormRow label={<H3>{t('superAdminPanel.numbers.add.operator')}</H3>}>
          <EuiSuperSelect
            valueOfSelected={selectedOperatorId}
            options={operatorsOptions}
            onChange={(e) => setSelectedOperatorId(e)}
          />
        </FormRow>

        <FormRow
          style={{ marginTop: -10 }}
          helpText={
            <div style={{ display: 'flex', gap: 4 }}>
              <EuiIcon
                style={{ color: '#3E4C59' }}
                type="iInCircle"
                color={'subdued'}
              />

              <H4 $wrap $grey>
                {t('superAdminPanel.numbers.add.info')}
              </H4>
            </div>
          }
        >
          <EuiTextArea
            style={{ minHeight: 100 }}
            value={phoneNumbers}
            onPaste={handlePaste}
            onKeyPress={handleKeyPress}
            onChange={(e) => setPhoneNumbers(e.target.value)}
          />
        </FormRow>

        <AddNewNumbersDirectToTenantContainer
          onClick={() => {
            setDirectToTenant((prev) => !prev); //setDirectToTenant((prev) => !prev);
          }}
        >
          <Checkbox checked={directToTenant} />
          <H3>{t('superAdminPanel.numbers.add.directToTenant')}</H3>
        </AddNewNumbersDirectToTenantContainer>

        {directToTenant && (
          <FormRow
            label={<H3>{t('superAdminPanel.numbers.assign.tenant')}</H3>}
          >
            <EuiSuperSelect
              valueOfSelected={selectedTenantSlug}
              options={tenantOptions}
              onChange={(e) => setSelectedTenantSlug(e)}
            />
          </FormRow>
        )}

        {newNumbersErrors && newNumbersErrors.already_assigned.length > 0 && (
          <div>
            <H3 style={{ color: '#FF0000' }}>
              {t('superAdminPanel.numbers.add.assignedNumbers')}
              {newNumbersErrors.already_assigned.join(', ')}
            </H3>
          </div>
        )}

        {newNumbersErrors && newNumbersErrors.wrong_numbers.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <H3 style={{ color: '#FF0000' }}>
              {t('superAdminPanel.numbers.add.wrongNumbers')}
              {newNumbersErrors?.wrong_numbers.join(', ')}
            </H3>
          </div>
        )}

        {newNumbersErrors && newNumbersErrors.added_numbers.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <H3 style={{ color: 'green' }}>
              {t('superAdminPanel.numbers.add.addedNumbers')}
              {newNumbersErrors?.added_numbers.join(', ')}
            </H3>
          </div>
        )}

        <NumbersAddNewButtonContainer style={{ marginTop: 20 }}>
          <Button type={'submit'} disabled={validateButton()}>
            {t('superAdminPanel.numbers.add.text')}
          </Button>
        </NumbersAddNewButtonContainer>
      </NumbersAddNewContentContainer>
    </PhoneNumbersContainer>
  );
};

export default NumbersAddNew;
