import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  campaignsPauseCcAsyncThunk,
  campaignsPauseInboundAsyncThunk,
  checkCampaignStatus,
  getCampaignByIdAsyncThunk,
  getCampaignStatusAsyncThunk,
  getCampaignsRecordingsAsyncThunk,
  getCampaignsTranscriptsAsyncThunk,
  selectCampaignById,
  updateCampaignStatusAsyncThunk,
} from './api/campaignsSlice';
import {
  Campaign,
  CampaignStatus,
  CampaignUpdateStatusTransitionEnum,
  ServiceStatusEnum,
} from '../../../generated/tenants/Api';
import {
  BarContentContainer,
  BarContentLeftContainer,
  BarRightContainer,
  CampaignDetailedBar,
  CampaignDetailedContainer,
  CampaignDownloadButtonsContainer,
  CampaignStatusesContainer,
  Content,
  FakeButton,
  FinishCampaignButton,
  StatusName,
  StatusRow,
  TabButton,
  TabButtonsContainer,
  TabContainer,
} from './Campaigns.style';
import {
  EuiLoadingSpinner,
  EuiPopover,
  EuiSwitch,
  EuiToolTip,
  copyToClipboard,
} from '@elastic/eui';
import { Button, H2, H3, H4 } from '../../App.style';
import moment from 'moment';
import RecordsList from './tabs/records/RecordsList';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../processes/api/processSlice';
import CallsList from './tabs/calls/CallsList';
import CampaignFinishModal from './CampaignFinishModal';
import { renderStatus } from './panel/campaignTableFunctions';

import { ChevronUpIcon } from '../../../resources/icons-new/ChevronUpIcon';
import { ChevronDownIcon } from '../../../resources/icons-new/ChevronDownIcon';
import { ArrowRightIcon } from '../../../resources/icons-new/ArrowRightIcon';
import { CampaignsIcon } from '../../../resources/icons-new/CampaignsIcon';
import { CopyIcon } from '../../../resources/icons-new/CopyIcon';
import SuccessPopup from '../../../common/popups/SuccessPopup';
import CampaignSnapshot from './CampaignSnapshot';
import { PhoneIcon } from '../../../resources/icons-new/PhoneIcon';
import { ReportsIcon } from '../../../resources/icons-new/ReportsIcon';
import { DownloadIcon } from '../../../resources/icons-new/DownloadIcon';
import { generateReportAsyncThunk } from '../reports/api/reportsSlice';
import CampaignNumbersModal from './CampaignNumbersModal';
import CampaignHistory from './tabs/history/CampaignHistory';
import { useTranslation } from 'react-i18next';
import CampaignOptimization from './tabs/optimization/CampaignOptimization';
import { ArrowLeftIcon } from '../../../resources/icons-new/ArrowLeftIcon';
import usePermissions from '../users/usePermissions';
import { unwrapResult } from '@reduxjs/toolkit';
import LoadingModal from '../../../common/modals/LoadingModal';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export type CampaignTab =
  | 'Records'
  | 'Calls'
  | 'History'
  | 'Process snapshot'
  | 'Optimization';

const tabs: CampaignTab[] = [
  'Records',
  'Calls',
  'History',
  'Process snapshot',
  'Optimization',
];

const CampaignDetailed = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const navigate = useNavigate();
  const { checkPermission } = usePermissions();
  const hasCampaignAccess = checkPermission('campaign');
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef<boolean>(false);

  interface LocationState {
    showCreatePopup?: boolean;
  }

  // In your component
  const location = useLocation();
  const showCreatePopup = (location.state as LocationState)?.showCreatePopup;
  const [createPopupVisible, setCreatePopupVisible] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (showCreatePopup) {
      setCreatePopupVisible(true);
      navigate('.', { state: {} });

      timer = setTimeout(() => {
        setCreatePopupVisible(false);
      }, 5000);
    }

    // Clean up function
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showCreatePopup]);

  const [campaign, setCampaign] = useState<Campaign>();

  useEffect(() => {
    if (id) {
      dispatch(getCampaignByIdAsyncThunk(id));
      dispatch(
        getProcessesListAsyncThunk({
          id: strategy,
          query: { is_archived: true },
        }),
      );
    }
  }, []);

  const apiCampaignDetailed = useAppSelector(selectCampaignById);
  const apiProcessesList = useAppSelector(selectProcessesList);

  const fetchCampaignStatus = () => {
    const refreshSeconds = 5;

    if (campaign) {
      dispatch(getCampaignStatusAsyncThunk(campaign.id))
        .then(unwrapResult)
        .then((res: CampaignStatus) => {
          if (isMountedRef.current) {
            if (res.status === 'PENDING') {
              timerRef.current = setTimeout(
                fetchCampaignStatus,
                refreshSeconds * 1000,
              );
            } else {
              dispatch(getCampaignByIdAsyncThunk(campaign.id));
            }
          }
        });
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    fetchCampaignStatus();

    return () => {
      isMountedRef.current = false;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [campaign?.status === 'PENDING']);

  const findProcessNameById = (id: string) => {
    const process = apiProcessesList.find((process) => process.id === id);

    return process ? process.name : '---';
  };

  useEffect(() => {
    if (apiCampaignDetailed) {
      setCampaign(apiCampaignDetailed);
    }
  }, [apiCampaignDetailed]);

  const [selectedTab, setSelectedTab] = useState<CampaignTab>('Records');

  const query = useQuery();
  const callsOnStart = query.get('calls') === 'true';

  useEffect(() => {
    if (callsOnStart) {
      setSelectedTab('Calls');
    }
  }, [callsOnStart]);

  //tab

  const handleTabClick = (tab: CampaignTab) => {
    setSelectedTab(tab);
  };

  const [selectedRecordId, setSelectedRecordId] = useState<string>('');

  const clearSelectedRecordId = () => setSelectedRecordId('');

  const handleCallsHistoryClick = (id: string) => {
    setSelectedTab('Calls');
    setSelectedRecordId(id);
  };

  const handleRedirectToStatistics = () => {
    if (campaign) {
      navigate(
        `/dashboard/analytics?processId=${campaign.process}&campaignId=${campaign.id}`,
      );
    }
  };

  const renderForm = (tab: CampaignTab) => {
    if (campaign)
      switch (tab) {
        case 'Records':
          return (
            <RecordsList
              campaign={campaign}
              handleCallsHistoryClick={handleCallsHistoryClick}
              hasCampaignAccess={hasCampaignAccess}
            />
          );
        case 'Calls':
          return (
            <CallsList
              campaignId={campaign.id}
              selectedRecordId={selectedRecordId}
              clearSelectedRecordId={clearSelectedRecordId}
              hasCampaignAccess={hasCampaignAccess}
            />
          );
        case 'History':
          return <CampaignHistory campaignId={campaign.id} />;

        case 'Process snapshot':
          return <CampaignSnapshot campaign={campaign} />;
        case 'Optimization':
          return (
            <CampaignOptimization
              campaign={campaign}
              hasCampaignAccess={hasCampaignAccess}
            />
          );
        default:
          return null;
      }
  };

  const [campaignIdCopied, setCampaignIdCopied] = useState<boolean>(false);

  const handleCampaignIdClick = () => {
    if (campaign) {
      copyToClipboard(campaign.id);
      setCampaignIdCopied(true);
    }
  };

  const [isFinishCampaignPopoverVisible, setIsFinishcampaignPopoverVisible] =
    useState<boolean>(false);

  const closeFinishCampaignPopover = () =>
    setIsFinishcampaignPopoverVisible(false);

  const handleChangeCCStatus = () => {
    if (!campaign || !hasCampaignAccess) {
      alert('something went wrong');
      return;
    }

    const cc_paused = campaign.cc_status === 'OPEN';
    const data = {
      id: campaign.id,
      data: { cc_paused },
    };

    dispatch(campaignsPauseCcAsyncThunk(data))
      .then(() => {
        dispatch(getCampaignByIdAsyncThunk(campaign.id));
      })
      .catch(() => {
        alert('something went wrong');
      });
  };

  const handleChangeInboundStatus = () => {
    if (!campaign || !hasCampaignAccess) {
      return;
    }

    const inbound_paused = campaign.inbound_status === 'OPEN';
    const data = {
      id: campaign.id,
      data: { inbound_paused: inbound_paused },
    };

    dispatch(campaignsPauseInboundAsyncThunk(data))
      .then(() => {
        dispatch(getCampaignByIdAsyncThunk(campaign.id));
      })
      .catch(() => {
        alert('something went wrong');
      });
  };

  const [isFinishCampaignModalVisible, setIsFinishCampaignModalVisible] =
    useState<boolean>(false);

  const openFinishCampaignModal = () => setIsFinishCampaignModalVisible(true);
  const closeFinishCampaignModal = () => setIsFinishCampaignModalVisible(false);

  const [isCampaigNnumbersModalVisible, setIsCampaignNumbersModalVisible] =
    useState<boolean>(false);
  const openCampaignNumbersModal = () => setIsCampaignNumbersModalVisible(true);
  const closeCampaignNumbersModal = () =>
    setIsCampaignNumbersModalVisible(false);

  const handleFinishCampaignClick = () => {
    if (campaign) {
      const data = {
        id: campaign.id,
        data: { transition: 'STOP' as CampaignUpdateStatusTransitionEnum },
      };
      setIsLoadingVisible(true);
      dispatch(updateCampaignStatusAsyncThunk(data)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const checkUpdateStatus = useAppSelector(checkCampaignStatus);
  useEffect(() => {
    if (
      checkUpdateStatus.state === 'idle' &&
      checkUpdateStatus.value?.status === 'success' &&
      id
    ) {
      closeFinishCampaignModal();

      dispatch(getCampaignByIdAsyncThunk(id));
      dispatch(
        getProcessesListAsyncThunk({
          id: strategy,
          query: { is_archived: true },
        }),
      );
    }
  }, [checkUpdateStatus]);

  const handleDownloadRecordings = () => {
    if (campaign) {
      dispatch(
        getCampaignsRecordingsAsyncThunk({ campaignId: campaign.id }),
      ).then((returnedAction) => {
        if (
          getCampaignsRecordingsAsyncThunk.fulfilled.match(returnedAction) &&
          campaign.process !== null
        ) {
          const audioUrl = returnedAction.payload.campaignsRecordingsUrl;

          const link = document.createElement('a');

          link.href = audioUrl;
          link.download = returnedAction.payload.filename;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        }
      });
    }
  };

  const handleDownloadTranscripts = () => {
    if (campaign) {
      dispatch(
        getCampaignsTranscriptsAsyncThunk({ campaignId: campaign.id }),
      ).then((returnedAction) => {
        if (
          getCampaignsTranscriptsAsyncThunk.fulfilled.match(returnedAction) &&
          campaign.process !== null
        ) {
          const audioUrl = returnedAction.payload.campaignsTranscriptUrl;

          const link = document.createElement('a');

          link.href = audioUrl;

          link.download = returnedAction.payload.filename;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        }
      });
    }
  };

  const handleCloseCreatePopup = () => setCreatePopupVisible(false);

  const handleGoToReports = () => {
    if (campaign) {
      const processId = campaign.process;
      const campaignId = campaign.id;

      navigate(
        `/dashboard/reports?processId=${processId}&campaignId=${campaignId}`,
      );
    }
  };

  const generateCustomReport = () => {
    if (campaign?.id) {
      dispatch(generateReportAsyncThunk(campaign.id)).then((returnedAction) => {
        if (
          generateReportAsyncThunk.fulfilled.match(returnedAction) &&
          campaign.process !== null
        ) {
          const audioUrl = returnedAction.payload.url;

          const link = document.createElement('a');

          link.href = audioUrl;

          link.download = returnedAction.payload.filename;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        }
      });
    }
  };

  const handleCampaignStatusChange = () => {
    if (campaign && hasCampaignAccess) {
      const campaignPaused = campaign.status === 'PAUSED';

      const data = {
        id: campaign.id,
        data: {
          transition: (campaignPaused
            ? 'UNPAUSE'
            : 'PAUSE') as CampaignUpdateStatusTransitionEnum,
        },
      };
      dispatch(updateCampaignStatusAsyncThunk(data))
        .then(() => {
          dispatch(getCampaignByIdAsyncThunk(campaign.id));
        })
        .catch(() => {
          alert('something went wrong');
        });
    }
  };

  const renderTabName = (name: CampaignTab) => {
    switch (name) {
      case 'Records':
        return t('campaigns.records.campaignTabs.records');
      case 'Calls':
        return t('campaigns.records.campaignTabs.calls');
      case 'History':
        return t('campaigns.records.campaignTabs.history');
      case 'Process snapshot':
        return t('campaigns.records.campaignTabs.processSnapshot');
      case 'Optimization':
        return t('campaigns.records.campaignTabs.optimization');
    }
  };

  const renderServiceStatusText = (serviceStatus: ServiceStatusEnum) => {
    switch (serviceStatus) {
      case 'OPEN':
        return t('campaigns.serviceStatuses.open');
      case 'CLOSED':
        return t('campaigns.serviceStatuses.closed');
      case 'DISABLED':
        return t('campaigns.serviceStatuses.disabled');
      case 'PAUSED':
        return t('campaigns.serviceStatuses.paused');
    }
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <CampaignFinishModal
        isVisible={isFinishCampaignModalVisible}
        closeModal={closeFinishCampaignModal}
        finishCampaign={handleFinishCampaignClick}
      />

      <CampaignNumbersModal
        isVisible={isCampaigNnumbersModalVisible}
        closeModal={closeCampaignNumbersModal}
        phoneNumbers={campaign?.phone_numbers}
      />

      {campaign && (
        <CampaignDetailedContainer>
          {createPopupVisible && (
            <SuccessPopup
              onClose={handleCloseCreatePopup}
              text={t('campaigns.success')}
            />
          )}
          <CampaignDetailedBar>
            <BarContentContainer>
              {/* top row */}
              <BarContentLeftContainer>
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <Button
                    $styleType="NORMAL"
                    $size={'S'}
                    onClick={() => navigate('/dashboard/campaigns')}
                  >
                    <ArrowLeftIcon />
                  </Button>
                  <H2>{t('campaigns.campaign')}</H2>
                  <H2 $selected>
                    {campaign.process
                      ? findProcessNameById(campaign.process)
                      : '---'}
                  </H2>

                  <H2 style={{ marginLeft: 10 }}>{t('campaigns.start')}</H2>
                  <H2 $selected>
                    {moment(campaign.start_date).format('DD.MM.YYYY')}
                  </H2>

                  <H2 style={{ marginLeft: 10 }}>{t('campaigns.end')}</H2>
                  <H2 $selected>
                    {moment(campaign.end_date).format('DD.MM.YYYY')}
                  </H2>

                  <H4 style={{ marginLeft: 10 }}>
                    {campaign.status !== 'FINISHED' &&
                    campaign.status !== 'STOPPED' ? (
                      <EuiPopover
                        button={
                          <div
                            style={{
                              border:
                                campaign.status !== 'PENDING'
                                  ? `1px solid ${renderStatus(
                                      campaign.status,
                                      'color',
                                    )}`
                                  : '',
                              borderRadius: 5,
                              padding: '0px 10px',
                              display: 'flex',
                              gap: 6,
                              alignItems: 'center',
                              cursor:
                                campaign.status !== 'PENDING' ? 'pointer' : '',
                            }}
                            onClick={() =>
                              campaign.status !== 'PENDING' &&
                              campaign.status !== 'ERROR' &&
                              hasCampaignAccess &&
                              setIsFinishcampaignPopoverVisible((prev) => !prev)
                            }
                          >
                            {campaign.status === 'PENDING' && (
                              <EuiLoadingSpinner />
                            )}
                            <H4>{renderStatus(campaign.status, 'text')}</H4>

                            {campaign.status !== 'PENDING' &&
                              campaign.status !== 'ERROR' &&
                              hasCampaignAccess && (
                                <>
                                  {isFinishCampaignPopoverVisible ? (
                                    <ChevronUpIcon />
                                  ) : (
                                    <ChevronDownIcon />
                                  )}
                                </>
                              )}
                          </div>
                        }
                        isOpen={isFinishCampaignPopoverVisible}
                        closePopover={closeFinishCampaignPopover}
                        anchorPosition="downCenter"
                        hasArrow={false}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <H4>{t('campaigns.changeStatus')}</H4>
                          <ArrowRightIcon />

                          <FinishCampaignButton
                            onClick={openFinishCampaignModal}
                          >
                            {t('campaigns.finish')}
                          </FinishCampaignButton>
                        </div>
                      </EuiPopover>
                    ) : (
                      <>{renderStatus(campaign.status, 'text')}</>
                    )}
                  </H4>
                </div>

                {/* bottom row */}
                <div style={{ display: 'flex', gap: 5, marginTop: 8 }}>
                  <EuiToolTip
                    position="top"
                    content={
                      <p style={{ textAlign: 'center' }}>
                        {campaignIdCopied
                          ? t('campaigns.idCopied')
                          : t('campaigns.copyID')}
                      </p>
                    }
                  >
                    <FakeButton
                      onClick={handleCampaignIdClick}
                      style={{ textAlign: 'center' }}
                      onMouseLeave={() => setCampaignIdCopied(false)}
                    >
                      <CopyIcon />
                      {t('campaigns.copyID')}
                    </FakeButton>
                  </EuiToolTip>

                  <Button
                    $size={'S'}
                    $styleType="NORMAL"
                    onClick={openCampaignNumbersModal}
                  >
                    <PhoneIcon />
                    {t('campaigns.showCampaignNumbers')}
                  </Button>

                  <Button
                    $size={'S'}
                    $styleType="NORMAL"
                    onClick={handleRedirectToStatistics}
                  >
                    <CampaignsIcon
                      style={{ width: 14, height: 14, marginRight: 5 }}
                    />
                    {t('campaigns.goToStats')}
                  </Button>

                  {hasCampaignAccess && (
                    <Button
                      $size={'S'}
                      $styleType="NORMAL"
                      onClick={handleGoToReports}
                    >
                      <ReportsIcon />
                      {t('campaigns.goToReports')}
                    </Button>
                  )}
                </div>
              </BarContentLeftContainer>
              {/* middle row */}
            </BarContentContainer>
            <BarRightContainer>
              {(campaign.status === 'FINISHED' ||
                campaign.status === 'ERROR' ||
                campaign.status === 'STOPPED') &&
                hasCampaignAccess && (
                  <CampaignDownloadButtonsContainer>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                    >
                      <H4 style={{ marginRight: 5 }}>
                        {t('campaigns.download')}{' '}
                      </H4>
                      <Button
                        onClick={handleDownloadTranscripts}
                        $styleType="NORMAL"
                        $size={'S'}
                      >
                        {t('campaigns.transcripts')}
                        <DownloadIcon />
                      </Button>
                      <Button
                        onClick={handleDownloadRecordings}
                        $styleType="NORMAL"
                        $size={'S'}
                      >
                        {t('campaigns.recordings')}
                        <DownloadIcon />
                      </Button>

                      <Button
                        onClick={generateCustomReport}
                        $styleType="SECONDARY"
                        $size={'S'}
                      >
                        {t('campaigns.customReport')}
                        <DownloadIcon />
                      </Button>
                    </div>
                  </CampaignDownloadButtonsContainer>
                )}
              {campaign.status !== 'PENDING' &&
                campaign.status !== 'FINISHED' &&
                campaign.status !== 'ERROR' &&
                campaign.status !== 'STOPPED' && (
                  <CampaignStatusesContainer>
                    <StatusRow>
                      <StatusName>
                        <H4 $bold>{t('campaigns.outbound')}</H4>
                      </StatusName>

                      {campaign.status !== 'SCHEDULED' && (
                        <EuiSwitch
                          compressed
                          label=""
                          checked={campaign.status === 'RUNNING'}
                          onChange={handleCampaignStatusChange}
                        />
                      )}
                      <H4>
                        {campaign.status === 'RUNNING'
                          ? t('campaigns.open')
                          : t('campaigns.paused')}
                      </H4>
                    </StatusRow>

                    <StatusRow>
                      <StatusName>
                        <H4 $bold>{t('campaigns.inbound')}</H4>
                      </StatusName>
                      {campaign.status !== 'SCHEDULED' && (
                        <EuiSwitch
                          compressed
                          label=""
                          checked={campaign.inbound_status === 'OPEN'}
                          onChange={handleChangeInboundStatus}
                        />
                      )}
                      <H4>
                        {renderServiceStatusText(campaign.inbound_status)}
                      </H4>
                    </StatusRow>

                    <StatusRow>
                      <StatusName>
                        <H4 $bold>{t('campaigns.cc')}</H4>
                      </StatusName>
                      {campaign.status !== 'SCHEDULED' && (
                        <EuiSwitch
                          disabled={campaign.cc_status === 'DISABLED'}
                          compressed
                          label=""
                          checked={campaign.cc_status === 'OPEN'}
                          onChange={handleChangeCCStatus}
                        />
                      )}
                      <H4>{renderServiceStatusText(campaign.cc_status)}</H4>
                    </StatusRow>
                  </CampaignStatusesContainer>
                )}
            </BarRightContainer>
          </CampaignDetailedBar>
          <TabContainer>
            <TabButtonsContainer>
              {tabs.map((tab, index) => (
                <TabButton
                  key={index}
                  $isSelected={selectedTab === tab}
                  onClick={() => handleTabClick(tab)}
                >
                  <H3 $bold={selectedTab === tab}>{renderTabName(tab)}</H3>
                </TabButton>
              ))}
            </TabButtonsContainer>

            <Content>{renderForm(selectedTab)}</Content>
          </TabContainer>
        </CampaignDetailedContainer>
      )}
    </>
  );
};

export default CampaignDetailed;
