import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../common/hooks';
import { COLOR_NEUTRAL_80, BLACK_typography, H4 } from '../../../App.style';
import {
  DateHeaderContent,
  PaginationContainer,
  ArrowsContainer,
  PaginationText,
  DoneTotalHeaderContainer,
  TableContainer,
  CampaignsTab,
  CampaignsTableHeader,
} from './CampaignsPanel.style';
import {
  PaginatedCampaignOverviewList,
  Process,
} from '../../../../generated/tenants/Api';
import { EuiSkeletonRectangle, EuiToolTip } from '@elastic/eui';
import CampaignRow from './CampaignRow';
import { ArrowIconLeft } from '../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../resources/icons-new/ArrowIconRight';
import { CampaingsFiltersValues } from './bar/filters/CampaignsFilters';
import { getCampaignsPanelListAsyncThunk } from '../api/campaignsSlice';
import { ArrowUpIcon } from '../../../../resources/icons-new/ArrowUpIcon';
import { ArrowDownIcon } from '../../../../resources/icons-new/ArrowDownIcon';
import { QuestionCircleIcon } from '../../../../resources/icons-new/QuestionCircleIcon';
import { useTranslation } from 'react-i18next';

interface CampaignsTableProps {
  strategyId: string;
  processesList: Process[];
  campaigns: PaginatedCampaignOverviewList | undefined;
  campaignsPerPage: number;
  filters: CampaingsFiltersValues;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({
  strategyId,
  processesList,
  campaigns,
  campaignsPerPage,
  filters,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  //start-end date sorting logic

  type Ordering = '-end_date' | '-start_date' | 'end_date' | 'start_date';
  type SortField = 'start_date' | 'end_date';
  type SortDirection = 'asc' | 'desc';
  type SortState = { field: SortField; direction: SortDirection };

  const [sort, setSort] = useState<SortState>({
    field: 'start_date',
    direction: 'asc',
  });

  //pagination

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const totalItems = campaigns?.count as number;
  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };

  //function handling sorting and pagination
  const returnOrder = () => {
    return sort.direction === 'asc'
      ? sort.field
      : (`-${sort.field}` as Ordering);
  };

  useEffect(() => {
    const fetchCampaigns = () => {
      const campaignsData = {
        strategyId: strategyId,
        query: {
          ordering: returnOrder(),
          process_ids: filters.processes,
          statuses: filters.statuses,
          start_date_gte: filters.date?.start_date,
          end_date_lte: filters.date?.end_date,
          offset: offset,
          limit: campaignsPerPage,
          label_text: filters.search,
        },
      };

      setLoading(true);
      dispatch(getCampaignsPanelListAsyncThunk(campaignsData)).finally(() => {
        setLoading(false);
      });
    };

    fetchCampaigns();

    const refreshSeconds = 30;

    const intervalId = setInterval(fetchCampaigns, refreshSeconds * 1000); //calls fetchCampaigns every 30 seconds

    return () => clearInterval(intervalId);
  }, [sort, filters, offset, limit]);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  return (
    <TableContainer>
      {campaigns?.results && (
        <CampaignsTab>
          <thead>
            <tr>
              <CampaignsTableHeader>
                <DateHeaderContent
                  style={{
                    color:
                      sort.field === 'start_date'
                        ? BLACK_typography
                        : COLOR_NEUTRAL_80,
                  }}
                  onClick={() =>
                    setSort({
                      field: 'start_date',
                      direction:
                        sort.field === 'start_date' && sort.direction === 'asc'
                          ? 'desc'
                          : 'asc',
                    })
                  }
                >
                  <H4 $grey>{t('campaigns.table.startDate')}</H4>
                  {sort.field === 'start_date' ? (
                    sort.direction === 'asc' ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    )
                  ) : (
                    <ArrowDownIcon color={'grey'} />
                  )}
                </DateHeaderContent>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <DateHeaderContent
                  style={{
                    color:
                      sort.field === 'end_date'
                        ? BLACK_typography
                        : COLOR_NEUTRAL_80,
                  }}
                  onClick={() =>
                    setSort({
                      field: 'end_date',
                      direction:
                        sort.field === 'end_date' && sort.direction === 'asc'
                          ? 'desc'
                          : 'asc',
                    })
                  }
                >
                  <H4 $grey>{t('campaigns.table.endDate')}</H4>
                  {sort.field === 'end_date' ? (
                    sort.direction === 'asc' ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    )
                  ) : (
                    <ArrowDownIcon color={'grey'} />
                  )}
                </DateHeaderContent>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <H4 $grey>{t('campaigns.table.process')}</H4>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <H4 $grey>{t('campaigns.table.status')}</H4>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <H4 $grey>{t('campaigns.table.details')}</H4>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <DoneTotalHeaderContainer>
                  <H4 $grey>{t('campaigns.table.doneTotal')}</H4>
                  <EuiToolTip
                    position="top"
                    content={t('campaigns.table.doneTotalTooltip')}
                  >
                    <QuestionCircleIcon />
                  </EuiToolTip>
                </DoneTotalHeaderContainer>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <H4 $grey>{t('campaigns.table.labels')}</H4>
              </CampaignsTableHeader>

              <CampaignsTableHeader>
                <PaginationContainer>
                  <PaginationText>
                    {getPaginationTextContent(
                      currentPage,
                      itemsPerPage,
                      totalItems,
                    )}
                  </PaginationText>

                  <ArrowsContainer>
                    <button
                      disabled={currentPage === 1}
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                    >
                      <ArrowIconLeft disabled={currentPage === 1} />
                    </button>

                    <button
                      disabled={currentPage === totalPages}
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                    >
                      <ArrowIconRight disabled={currentPage === totalPages} />
                    </button>
                  </ArrowsContainer>
                </PaginationContainer>
              </CampaignsTableHeader>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  padding: '16px 0px',
                }}
              >
                {Array.from({ length: 8 }).map(() => (
                  <EuiSkeletonRectangle width="100%" height={50} />
                ))}
              </div>
            ) : (
              <>
                {campaigns?.results.length === 0 ? (
                  <div
                    style={{
                      padding: 20,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {t('campaigns.table.noCampaigns')}
                  </div>
                ) : (
                  campaigns.results.map((campaign, index) => {
                    const process = processesList.find(
                      (item) => item.id === campaign.process,
                    );
                    return (
                      <CampaignRow
                        key={`campaign-row-${index}`}
                        campaign={campaign}
                        process={process}
                      />
                    );
                  })
                )}
              </>
            )}
          </tbody>
        </CampaignsTab>
      )}
    </TableContainer>
  );
};

export default CampaignsTable;
