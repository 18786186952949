import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  AnalyticsBarContainer,
  AnalyticsBarSelectContainer,
  AnalyticsClearFiltersContainer,
  AnalyticsContainer,
  AnalyticsContentContainer,
  AnalyticsLoadingContainer,
  AnalyticsSelectedCampaignContainer,
  AnalyticsSelectedCampaigns,
} from './Analytics.style';
import AnsweredCallsDialogueStatusChart from './charts/AnsweredCallsDialogueStatusChart';
import InteruptionStatusChart from './charts/InteruptionStatusChart';
import { H1, H3, H4, fontIBM } from '../../App.style';
import {
  TabContainer,
  TabButtonsContainer,
  TabButton,
} from '../campaigns/Campaigns.style';
import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiLoadingSpinner,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from '@elastic/eui';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../processes/api/processSlice';
import {
  selectStrategiesList,
  selectStrategyId,
} from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import OverwiewCharts from './charts/OverwiewCharts';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { CampaignOverview } from '../../../generated/tenants/Api';
import {
  getCampaignsPanelListAsyncThunk,
  selectCampaignsPanelList,
} from '../campaigns/api/campaignsSlice';
import ReportsCampaignInput from '../reports/ReportsCampaignInput';
import OutboundPerWeekdayChart from './charts/OutboundPerWeekdayChart';
import { CampaignsIcon } from '../../../resources/icons-new/CampaignsIcon';
import { XIcon } from '../../../resources/icons-new/XIcon';
import OutboundPerRange from './charts/OutboundPerRange';
import OutboundPerHourChart from './charts/OutboundPerHourChart';
import ProcessesInput from '../../../common/inputs/ProcessesInput';

export type AnalyticsTab =
  | 'OVERVIEW'
  | 'OUTBOUND_PER_DAY'
  | 'OUTBOUND_PER_HOURS'
  | 'ANSWERED_CALLS_STATUS'
  | 'INTERUPTION_STATUS'
  | 'OUTBOUND_PER_WEEKDAY';

const tabs: AnalyticsTab[] = [
  'OVERVIEW',
  'OUTBOUND_PER_DAY',
  'OUTBOUND_PER_WEEKDAY',
  'OUTBOUND_PER_HOURS',
  'ANSWERED_CALLS_STATUS',
  'INTERUPTION_STATUS',
];

const AnalyticsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const strategy = useAppSelector(selectStrategyId);
  useEffect(() => {
    const data = {
      id: strategy,
      query: { is_archived: true },
    };
    dispatch(getProcessesListAsyncThunk(data));
  }, [strategy]);
  const apiStrategiesList = useAppSelector(selectStrategiesList);
  const apiProcessesList = useAppSelector(selectProcessesList);

  function findStrategyNameById(id: string) {
    return apiStrategiesList.find((strategy) => strategy.id === id)?.name ?? '';
  }

  const processesOptions = [...apiProcessesList].map((process) => ({
    value: process.id,
    processName: process.name,
    strategyName: findStrategyNameById(process.strategy),
    is_archived: process.is_archived,
  }));

  const [selectedTab, setSelectedTab] = useState<AnalyticsTab>('OVERVIEW');

  const handleTabClick = (tab: AnalyticsTab) => {
    setSelectedTab(tab);
    setLoading(true);
  };

  const renderForm = (tab: AnalyticsTab) => {
    switch (tab) {
      case 'OVERVIEW':
        return (
          <OverwiewCharts
            processId={selectedProcessId}
            type={campaignOrTime}
            campaigns={selectedCampaigns}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case 'OUTBOUND_PER_DAY':
        return (
          <OutboundPerRange
            processId={selectedProcessId}
            type={campaignOrTime}
            campaigns={selectedCampaigns}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case 'OUTBOUND_PER_HOURS':
        return (
          <OutboundPerHourChart
            processId={selectedProcessId}
            type={campaignOrTime}
            campaigns={selectedCampaigns}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case 'INTERUPTION_STATUS':
        return (
          <InteruptionStatusChart
            processId={selectedProcessId}
            type={campaignOrTime}
            campaigns={selectedCampaigns}
            startDate={startDate}
            endDate={endDate}
          />
        );

      case 'ANSWERED_CALLS_STATUS':
        return (
          <AnsweredCallsDialogueStatusChart
            processId={selectedProcessId}
            type={campaignOrTime}
            campaigns={selectedCampaigns}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case 'OUTBOUND_PER_WEEKDAY':
        return (
          <OutboundPerWeekdayChart
            processId={selectedProcessId}
            type={campaignOrTime}
            campaigns={selectedCampaigns}
            startDate={startDate}
            endDate={endDate}
          />
        );
      default:
        return null;
    }
  };

  const renderTabName = (name: AnalyticsTab) => {
    switch (name) {
      case 'OVERVIEW':
        return 'Overview';
      case 'OUTBOUND_PER_DAY':
        return 'Bot Efficiency';
      case 'OUTBOUND_PER_HOURS':
        return 'Outbound per hour';
      case 'INTERUPTION_STATUS':
        return 'Call interuption status';
      case 'ANSWERED_CALLS_STATUS':
        return 'Answered calls status';
      case 'OUTBOUND_PER_WEEKDAY':
        return 'Outbound per weekday';
    }
  };

  const [selectedProcessId, setSelectedProcessId] = useState<string>('');

  const campaignOrTimeOptions: EuiSuperSelectOption<string>[] = [
    {
      value: 'campaign',
      inputDisplay: <H4 $medium>{t('reports.campaign')}</H4>,
      dropdownDisplay: <H3>{t('reports.campaign')}</H3>,
    },
    {
      value: 'time',
      inputDisplay: <H4 $medium>{t('reports.time')}</H4>,
      dropdownDisplay: <H3>{t('reports.time')}</H3>,
    },
  ];

  type CampaignOrTime = 'campaign' | 'time' | undefined;
  const [campaignOrTime, setCampaignOrTime] = useState<CampaignOrTime>();

  //time
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>(moment());

  const dateError = startDate && endDate && endDate.isBefore(startDate);

  //campaigns
  const [selectedCampaigns, setSelectedCampaigns] = useState<
    CampaignOverview[]
  >([]);
  useEffect(() => {
    if (campaignOrTime === 'campaign' && selectedProcessId) {
      const data = {
        strategyId: strategy,
        query: { process_ids: [selectedProcessId] },
      };
      dispatch(getCampaignsPanelListAsyncThunk(data));
    }
  }, [campaignOrTime, selectedProcessId]);

  const apiCampaignsList = useAppSelector(selectCampaignsPanelList);

  const handleRemoveCampaignFromSelected = (id: string) => {
    const newSelectedCampaigns = selectedCampaigns.filter(
      (campaign) => campaign.id !== id,
    );
    setSelectedCampaigns(newSelectedCampaigns);
  };

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 300);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [selectedTab]);

  const handleClearFilters = () => {
    setSelectedProcessId('');
    setCampaignOrTime(undefined);
    setSelectedCampaigns([]);
    setStartDate(undefined);
    setEndDate(moment());
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processIdFromQuery = queryParams.get('processId');
    const campaignIdFromQuery = queryParams.get('campaignId');

    if (processIdFromQuery) {
      setSelectedProcessId(processIdFromQuery);
    }
    if (campaignIdFromQuery && processIdFromQuery) {
      setCampaignOrTime('campaign');

      if (apiCampaignsList && apiCampaignsList.results) {
        const campaign = apiCampaignsList.results.find(
          (c) => c.id === campaignIdFromQuery,
        );
        if (campaign) {
          setSelectedCampaigns([campaign]);
        }
      }
    }
  }, [dispatch, location.search, apiCampaignsList]);

  return (
    <AnalyticsContainer>
      <AnalyticsBarContainer>
        <H1 $medium>{t('analytics.panel.title')}</H1>
        <AnalyticsBarSelectContainer>
          <H3>{t('analytics.panel.chooseProcess')}</H3>
          <ProcessesInput
            processesList={processesOptions}
            selectedProcessId={selectedProcessId}
            setSelectedProcessId={(value) => {
              setSelectedProcessId(value);
              setSelectedCampaigns([]);
            }}
          />

          {selectedProcessId && (
            <>
              <H3>{t('analytics.panel.filterType')}</H3>
              <div style={{ minWidth: 120 }}>
                <EuiSuperSelect
                  placeholder={t('common.select')}
                  options={campaignOrTimeOptions}
                  valueOfSelected={campaignOrTime ? campaignOrTime : undefined}
                  disabled={!selectedProcessId}
                  onChange={(e) => {
                    if (e === 'time') {
                      setStartDate(undefined);
                      setEndDate(moment());
                      setCampaignOrTime('time');
                      setSelectedCampaigns([]);
                    } else if (e === 'campaign') {
                      setSelectedCampaigns([]);
                      setCampaignOrTime('campaign');
                    }
                  }}
                />
              </div>
            </>
          )}

          {campaignOrTime === 'time' ? (
            <>
              <H3>{t('analytics.panel.timeRange')}</H3>
              <EuiDatePickerRange
                css={{
                  width: 360,
                  background: 'white',
                  cursor: 'pointer',
                }}
                isInvalid={dateError}
                startDateControl={
                  <EuiDatePicker
                    css={{
                      background: 'white',
                      fontFamily: fontIBM,
                      cursor: 'pointer',
                    }}
                    selected={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    startDate={startDate}
                    endDate={endDate}
                    aria-label="Start date"
                    timeFormat="HH:mm"
                    showTimeSelect
                    dateFormat="DD.MM.YYYY, HH:mm"
                    maxDate={moment()}
                  />
                }
                endDateControl={
                  <EuiDatePicker
                    css={{
                      background: 'white',
                      fontFamily: fontIBM,
                      cursor: 'pointer',
                    }}
                    selected={endDate}
                    onChange={(date) => date && setEndDate(date)}
                    startDate={startDate}
                    endDate={endDate}
                    aria-label="End date"
                    showTimeSelect
                    timeFormat="HH:mm"
                    placeholder="DD.MM.YYYY, HH:MM"
                    dateFormat="DD.MM.YYYY, HH:mm"
                    maxDate={moment()}
                  />
                }
              />
            </>
          ) : (
            campaignOrTime === 'campaign' && (
              <>
                <H3>{t('analytics.panel.selectCampaigns')}</H3>
                {apiCampaignsList?.results ? (
                  <ReportsCampaignInput
                    selectedCampaigns={selectedCampaigns}
                    setSelectedCampaigns={setSelectedCampaigns}
                    campaignsList={apiCampaignsList.results}
                    noText
                    analytics
                  />
                ) : (
                  <EuiLoadingSpinner size={'m'} />
                )}
              </>
            )
          )}

          {(selectedCampaigns.length > 0 || campaignOrTime === 'time') && (
            <AnalyticsClearFiltersContainer onClick={handleClearFilters}>
              <H4>{t('analytics.panel.clearFilters')}</H4>
              <XIcon />
            </AnalyticsClearFiltersContainer>
          )}
        </AnalyticsBarSelectContainer>
        {selectedCampaigns.length > 0 && (
          <AnalyticsSelectedCampaigns>
            <H3>{t('analytics.panel.selectedCampaigns')}</H3>
            {selectedCampaigns.map((campaign) => (
              <AnalyticsSelectedCampaignContainer>
                <CampaignsIcon style={{ width: 12, height: 12 }} />
                {moment(campaign.start_date).format('DD.MM.YY')} -
                {moment(campaign.end_date).format('DD.MM.YY')}
                <XIcon
                  $pointer
                  color="#9AA5B1"
                  onClick={() => handleRemoveCampaignFromSelected(campaign.id)}
                />
              </AnalyticsSelectedCampaignContainer>
            ))}
          </AnalyticsSelectedCampaigns>
        )}
      </AnalyticsBarContainer>
      <TabContainer style={{ marginTop: 0 }}>
        <TabButtonsContainer>
          {tabs.map((tab, index) => (
            <TabButton
              style={{ padding: 5 }}
              key={index}
              $isSelected={selectedTab === tab}
              onClick={() => handleTabClick(tab)}
            >
              <H3 $bold={selectedTab === tab}>{renderTabName(tab)}</H3>
            </TabButton>
          ))}
        </TabButtonsContainer>
        <AnalyticsContentContainer>
          {loading ? (
            <AnalyticsLoadingContainer>
              <EuiLoadingSpinner size="xxl" />
            </AnalyticsLoadingContainer>
          ) : (
            renderForm(selectedTab)
          )}
        </AnalyticsContentContainer>
      </TabContainer>
    </AnalyticsContainer>
  );
};

export default AnalyticsPanel;
