import { translationsEn } from './en';

export const translationsPl: typeof translationsEn = {
  cookiesToastTitle: 'Używamy ciasteczek!',
  strenghtBar: {
    tooShort: 'Za krótkie',
    okay: 'Ok',
    strong: 'Silne hasło',
    weak: 'Słabe',
    good: 'Dobre',
  },
  login: {
    email: 'Email',
    password: 'Hasło',
    forgotPassword: 'Zapomniałeś hasła? kliknij ',
    login: 'Zaloguj się',
    error: {
      line1: `Przepraszamy, to nie jest poprawny email lub hasło.`,
      line2: 'Proszę spróbuj ponownie.',
    },

    resetPassword: {
      modal: {
        title: 'Resetowania hasła',
        confirmation: 'Potwierdzenie',
        tip: 'Podaj adres email konta, na który zostanie wysłany link do resetowania hasła.',
        after:
          'Link do resetowania hasła został wysłany na podany adres e-mail',
        notValidEmail: 'To nie jest prawidłowy email',
      },
      form: {
        wrongToken: `Link do zmiany hasła wygasł. Proszę wróć do strony głównej i spróbuj ponownie`,
        backToLogin: 'Powrót do logowania',
        newPassword: 'nowe hasło',
        confirmNewPassword: 'potwierdź nowe hasło',
        tooShort: `Twoje hasło musi mieć przynajmniej 12 znaków długości i zarówno hasło, jak i hasło potwierdzające muszą się zgadzać. Proszę spróbuj ponownie.`,
        changed: 'Hasło zmienione',
      },
    },
  },

  // panel superadmina
  superAdminPanel: {
    sidebar: {
      selectTenant: 'Lista organizacji',
      superUsers: 'Super użytkownicy',
      numbers: {
        title: 'Numery',
        add: 'Dodaj',
        assign: 'Przypisz do organizacji',
        history: 'Historia',
      },
      billings: 'Billingi',
    },

    tenants: {
      settings: 'Ustawienia organizacji',
      general: 'Ogólne',
      priceAndBillings: 'Ceny i rozliczenia',
      language: 'Ustawienia języka',
      sms: 'SMS',
      changesSaved: 'Zmiany zapisane!',
      error: 'Nie można zapisać, formularz zawiera błędy',
      list: {
        title: 'Wybierz organizację',
        create: 'Stwórz nową organizację',
        goTo: 'Przejdź do organizacji',
      },
      form: {
        details: 'Szczegóły organizacji',
        name: 'Nazwa organizacji',
        slugTooltip:
          'Przedrostek domeny dla organizacji. Bez spacji i znaków specjalnych.',
        lines: 'Linie',
        globalSettings: 'Ustawienia globalne',
        defaultLanguage: 'Domyślny język',
        timezone: 'Strefa czasowa',
        biznesOwnerDetails: 'Szczegóły właściciela biznesu',
        delete: 'Usuń konto',
        deleteThisTenant: 'Usuń to konto organizacji',
        edit: 'Edycja ustawien organizacji',
        create: 'Tworzenie nowej organizacji',
        billings: {
          billingPlan: 'Model rozliczeniowy',
          perCall: 'Model: Stawka za rozmowę',
          perRecord: 'Model: Stawka za rekord',
          pricingTelco: 'Cena: Telco',
          mobile: 'Komórkowe',
          domesticMobile: 'Połączenia krajowe. Cena za minutę',
          internationalMobile: 'Połączenia międzynarodowe. Cena za minutę',
          landline: 'Stacjonarne',
          domesticLandline: 'Połączenia krajowe. Cena za minutę',
          internationalLandline: 'Połączenia międzynarodowe. Cena za minutę',
          smsPrice: 'Stawka za sms (PLN)',
        },
      },
      deleteModal: {
        modalTitle: 'Usuń organizację',
        buttonText: 'Usuń na stałe',
        line1: 'Czy na pewno chcesz usunąć tą organizację?',
        line2: 'Potwierdź to, wpisując tutaj nazwę organizacji:',
      },
    },

    superUsers: {
      title: 'Lekta Super użytkownicy',
      invite: 'Zaproś superużytkownika',

      inviteModal: {
        title: 'Zaproś nowego superużytkownika Lekta',
        emailError: 'Podaj prawidłowy e-mail',
        role: 'Rola',
        buttonText: 'Zaproś',
      },

      table: {
        username: 'Nazwa użytkownika',
        role: 'Rola',
        permissions: 'Uprawnienia',
        notKnown: 'Nieznany',
        lastLogin: 'Ostatnie logowanie',
        noUsers: 'Brak superużytkowników do wyświetlenia',
        full: 'pełny',
        viewOnly: 'tylko do przeglądania',
        notLogged: 'Jeszcze się nie zalogowano',
        editAccess: 'Edytuj dostęp',
      },

      invitation: {
        badToken: 'Zły token zaproszenia',
        error: 'Błąd',
        step1: {
          hello: 'Witaj!',
          invited: 'Zostałeś zaproszony do Platformy Lekta Lease!',
          passwordsNotMatch: 'Hasła nie są zgodne',
          introduce: 'Krok 1. Proszę przedstawić się',
          firstName: 'Imię',
          lastName: 'Nazwisko',
        },
        step2: {
          createPassword:
            ' Krok 2. Utwórz hasło. Hasło powinno zawierać co najmniej 12 liter.',
          password: 'Hasło',
          repeatPassword: 'Powtórz hasło',
          join: 'Dołącz',
        },
        step3: {
          userCreated: 'Użytkownik utworzony',
          redirect: 'przekierowanie do tenantów',
        },
      },

      popups: {
        permissionEditSuccess: 'Pomyślnie edytowano uprawnienie',
        permissionEditError: 'coś poszło nie tak przy zmianie uprawnień',
        invited: 'Super użytkownik został zaproszony.',
      },
    },
    numbers: {
      add: {
        title: 'Dodaj nowe numery',
        operator: 'Operator:',
        info: `Proszę wprowadzić numery do pola tekstowego w formie listy (w każdej linii jeden numer LUB po przecinku LUB po średniku)`,
        directToTenant: 'Przypisz numery bezpośrednio do Tenanta',
        assignedNumbers: 'Już przypisane numery: ',
        wrongNumbers: 'Błędne numery: ',
        addedNumbers: 'Dodane numery: ',
        text: 'Dodaj numery',
        popups: {
          successAdd: 'Numery zostały dodane do Platformy Lekta',
          successAddTenant: 'Numery zostały dodane do organizacji {{tenant}}',
          error: 'Coś poszło nie tak. Spróbuj ponownie.',
        },
      },
      assign: {
        title: 'Przypisz do tenantów',
        all: 'Wszystkie numery',
        free: 'Wolne numery',
        empty: 'Nie ma więcej numerów do pokazania',
        tenant: 'Organizacja',
        copy: 'Kopiuj',
        copyNumber: 'Kopiuj numer',
        copiedNumber: 'Numer skopiowany do schowka',
        popups: {
          success: `Numery zostały przypisane do konta tenant. Są teraz dostępne do użytku.`,
          error:
            'Coś poszło nie tak podczas przypisywania numerów. Proszę spróbuj ponownie.',
        },
      },

      history: {
        title: 'Historia numerów',
        select: 'Wybierz numer',
        historyFor: 'Historia dla numeru:    ',
        empty: 'Brak historii dla tego numeru',
        goToCampaign: 'Przejdź do kampanii',
        copyCampaignID: 'Skopiuj ID kampanii',
      },
    },
  },

  navbar: {
    adminPanel: 'Panel Administratora',
    backToApp: 'Wróć do aplikacji',
    switchTo: 'Zmień na',
  },

  settings: {
    adminPanel: {
      sidebar: {
        users: 'Użytkownicy',
        numbers: 'Numery',
        assignTo: 'Zarządzanie numerami',
        history: 'Historia',
        addNew: 'Dodaj nowe',
        sftp: 'Ustawienia SFTP',
        manageWorkspaces: 'Zarządzaj przestrzenią roboczą',
      },

      accessTokens: {
        title: 'Tokeny dostępu',
        buttonText: 'Utwórz nowy token',
        table: {
          tokenName: 'Nazwa tokenu',
          created: 'Utworzono',
          lastUsed: 'Ostatnio użyty',
          expirationDate: 'Data ważności',
          create: 'Utwórz token dostępu',
          alert:
            'Data ważności tokenu nie może być wcześniejsza niż dzisiejsza.',
          empty: 'Brak tokenów dostępu',
          today: 'dzisiaj',
          token_expired: 'token wygasł',
          expiring_in_one_day: 'za 1 dzień',
          expiring_in: 'za {{count}} dni',
        },
        deleteTitle: 'Usuń token',
        deleteText: 'Czy na pewno chcesz usunąć ten token?',
        preview: {
          loadingTitle: 'Tworzenie tokenu...',
          successTitle: 'Token utworzony',
          failTitle: 'Nie udało się utworzyć tokenu',
          yourToken: 'Twój token dostępu',
          tokenInfo1: `Upewnij się, że skopiujesz i zapiszesz ten token, ponieważ nie będziesz`,
          tokenInfo2: 'mógł go zobaczyć ponownie!',
          failMessage:
            'Przepraszamy, coś poszło nie tak podczas tworzenia nowego tokenu dostępu',
          copyText: 'Kopiuj do schowka',
          textCopied: 'Token dostępu skopiowany do schowka',
        },
      },

      users: {
        title: 'Użytkownicy',
        invite: 'Zaproś nowego użytkownika',
        all: 'Wszyscy',
        changeAvatar: 'zmień avatar',
        deleteAccount: 'usuń konto',
        deactivate: 'deaktywuj',
        emailAddress: 'Adres e-mail: ',
        lastActivity: 'data ostatniej aktywności: ',

        inviteModal: {
          form: {
            email: 'Email',
            notValidEmail: 'Podaj prawidłowy email',
            role: 'Rola',
            workspace: 'Przestrzeń robocza',
          },
        },

        deleteModal: {
          line1: 'Czy na pewno chcesz usunąć użytkownika z mailem: ',
          line2: 'Czy na pewno chcesz usunąć swoje konto?',
          title: 'Usuń użytkownika',
        },

        table: {
          username: 'Nazwa użytkownika',
          role: 'Rola',
          permissions: 'Uprawnienia',
          lastLogin: 'Ostatnio zalogowany',
          noUsers: 'Brak użytkowników do wyświetlenia',
          row: {
            noAccess:
              'Użytkownik nie ma dostępu do żadnych przestrzeni roboczych',
            fullAccess:
              'Dostęp do wszystkich przestrzeni roboczych z pełnymi uprawnieniami',
            notLogged: 'Nie zalogowano jeszcze',
            editAccess: 'Edytuj dostęp',
          },
        },

        popups: {
          permissionsEdited: 'Uprawnienia zostały pomyślnie zmienione.',
          error: 'Coś poszło nie tak, spróbuj ponownie.',
          userInvited: 'Użytkownik został zaproszony.',
          avatarChanged: 'Avatar został pomyślnie zmieniony.',
          errorAvatarChange:
            'Coś poszło nie tak, upewnij się że plik ma mniej niż 1mb.',
        },
      },

      numbers: {
        assign: {
          title: 'Zarządzanie numerami',
          all: 'Nieprzypisane numery',
          free: 'Wolne numery w całym koncie organizacji',
          empty: 'Nie ma więcej numerów do pokazania',
          workspace: 'Przestrzeń robocza',
          replace: 'Zamień',
          unassign: 'Wypisz numer',
          replaceModal: {
            title: 'Zamień numer',
            numberToReplace: 'Numer, który chcesz zamienić:',
            replaceTo: 'Zamień na:',
          },
          unassignModal: {
            title: 'Odpinanie numeru',
            text: 'Czy na pewno chcesz usunąć przypisanie numeru {{number}} z przestrzeni roboczej {{strategyName}}?',
          },

          popups: {
            success: `Numery zostały przypisane do przestrzeni roboczej. Są teraz dostępne do użytku.`,
            error:
              'Coś poszło nie tak podczas przypisywania numerów. Proszę spróbuj ponownie.',
          },
        },
      },
      sftp: {
        title: 'Przesyłanie danych rekordów na SFTP',
        username: 'Nazwa użytkownika (login)',
        password: 'Hasło',
        changePassword: 'zmień hasło',
        setPassword: 'ustaw hasło',
        alreadyPassword:
          'Hasło zostało już ustawione, ale może być jeszcze skonfigurowane',
        viaKeys: 'Autoryzacja za pomocą kluczy',
        keysTooltip:
          'wprowadzony klucz publiczny pozwala na autoryzację kluczem zamiast hasłem',
        trustedKeys: 'Zaufane klucze',
        noKeys: 'Brak kluczy do wyświetlenia',
        addKey: 'Dodaj nowy klucz RSA +',
        publicKey: 'Publiczny klucz SFTP Lekta',
        copySSH: 'Kopiuj klucz SSH',
        copiedSSH: 'Klucz SSH skopiowany do schowka',
        passwordChange: {
          notEqual: 'Hasła nie są takie same',
          newPassword: 'Nowe hasło',
          confirmPassword: 'Potwierdź hasło',
        },
        deleteKeyQuestion: 'Czy na pewno chcesz usunąć ten klucz RSA?',
        deleteKey: 'Usuwanie klucza RSA',

        addKeyTitle: 'Dodaj nowy klucz RSA',
        notValidKey: 'Podany klucz publiczny jest nieprawidłowy',
        name: 'Nazwa',
        publicKeyTitle: 'Klucz publiczny',
        rsa: 'Konfiguracja RSA',
        removeSftp: 'Usuń konfiguracje SFTP',
        question: 'Czy na pewno chcesz usunąć tę konfigurację SFTP?',

        list: {
          title: 'Konfiguracja połączenia z serwerem SFTP',
          host: 'Host',
          port: 'Port',
          authorization: 'Autoryzacja',
          username: 'Nazwa użytkownika',
          details: 'Szczegóły',
          addNew: 'Dodaj nową konfigurację',
          checking: 'Sprawdzanie...',
          correctly: 'Prawidłowo zweryfikowano',
          failed: 'Połączenie nieudane',
          test: 'Test',
          delete: 'Usuń',
        },
        form: {
          password: 'Hasło',
          rsaKey: 'Klucz RSA',
          authMethod: 'Metoda uwierzytelniania',
          publicKey: 'Publiczny klucz Lekta',
          publicKeyCopied: 'Klucz publiczny skopiowany',
          publicKeyCopy: 'Kopiuj klucz publiczny',
          request:
            'Prosimy o dodanie konta Lekta do zaufanych kluczy publicznych.',
          test: 'Testuj SFTP',
          edit: 'Edytuj konfigurację SFTP',
        },
      },
      manageWorkspaces: {
        title: 'Zarządzaj przestrzeniami roboczymi',
        createNew: 'Utwórz nową',
        edit: 'Zarządzaj przestrzeniami roboczymi',
        list: {
          accordion1title: 'Aktywne przestrzenie robocze',
          accordion2title: 'Zarchiwizowane przestrzenie robocze',
          assignedLines: 'przypisane linie: ',
          created: 'utworzono ',
          modified: 'zmieniono ',
        },
        form: {
          gpkNotEmpty: 'Klucz publiczny GPG nie może być pusty',
          name: 'Nazwa',
          workspaceName: 'Nazwa przestrzeni roboczej',
          assignedLines: 'Przypisane linie',
          createDemoResources: 'Utworzyć zasoby demonstracyjne?',
          createNewWorkspace: 'Utwórz nową przestrzeń roboczą',
          noLines: 'Liczba przypisanych linii jest większa niż dostępne',
          freeLines: 'Wolne linie: ',
          encryptImportedData: 'Szyfruj importowane dane',
          encryptImportedDataTooltip: `Użyj tego klucza publicznego do szyfrowania plików przed przesłaniem. Zaszyfrowane pliki powinny mieć rozszerzenie .gpg. System użyje wygenerowanego klucza do odszyfrowania pliku. Pliki przesłane na serwer SFTP Lekta również będą korzystać z tej konfiguracji.`,
          yes: 'tak',
          no: 'nie',
          generateNewPublicKey: 'Wygeneruj nowy klucz publiczny',
          publicKey: 'Klucz publiczny',
          noPublicKey: 'Nie wygenerowano klucza',
          notDefined: 'Nie zdefiniowano',
          publicKeyCopied: 'Klucz publiczny skopiowany do schowka',
          publicKeyCopy: 'Kopiuj klucz publiczny',
          encryptOutgoingData: 'Szyfruj wychodzące dane',
          encryptOutgoingDataTooltip: `Podany klucz publiczny zostanie użyty do szyfrowania pobieranych plików wyjściowych. Pliki wyjściowe przesłane na serwer SFTP Lekta również będą szyfrowane.`,
          publicGpgKey: 'Publiczny klucz GPG',
          restoreQuestion:
            'Czy na pewno chcesz przywrócić tę przestrzeń roboczą?',
          restore: 'Przywróć przestrzeń roboczą: ',
          deleteUsingNow:
            'Aktualnie używasz tej przestrzeni roboczej, po usunięciu zostaniesz przekierowany do wyboru.',
          delete: 'Czy na pewno chcesz usunąć tę przestrzeń roboczą?',
          remove: 'Usuń przestrzeń roboczą: ',
          archiveTitle:
            'Czy na pewno chcesz zarchiwizować tę przestrzeń roboczą?',
          archiveText: `Po zarchiwizowaniu przestrzeń robocza będzie dostępna w panelu 'Zarchiwizowane przestrzenie robocze'.`,
          archive: 'Zarchiwizuj przestrzeń roboczą: ',
        },
      },
    },
    users: {
      sidebar: {
        title: 'Ustawienia \nużytkownika',
        profile: 'Profil',
        security: 'Prywatność',
        password: 'Hasło',
        accessTokens: 'Tokeny dostępu',
        language: 'Język',
        notifications: 'Powiadomienia',
      },
      passwordChange: {
        title: 'Zmiana hasła',
        current: 'Aktualne hasło',
        new: 'Nowe hasło',
        success: 'Twoje hasło zostało pomyślnie zmienione',
      },
      language: 'Język',
      notifications: {
        about: 'Wyślij mi e-maile na temat...',
        title: 'Powiadomienia',
        creatingNewCampaign: 'Tworzenie nowej kampanii',
        statusChangeCampaign: 'Zmiany w statusie kampanii',
        pending: 'Oczekująca',
        scheduled: 'Zaplanowane',
        running: 'Trwające',
        stopped: 'Zakończona ręcznie',
        finished: 'Zakończone',
        error: 'Błąd',
        outboundPaused: 'Wychodzące wstrzymane',
        inboundPaused: 'Przychodzące wstrzymane',
        ccPaused: 'CC wstrzymane',
        importNewRecords: 'Import nowych rekordów do kampanii',
        changesProcess: 'Zmiany w trakcie',
      },
    },
  },

  reports: {
    reportPerCall: 'Raport z rozmów',
    reportPerRecord: 'Raport z rekordów',
    reportPerCampaign: 'Raport z kampanii',
    processIs: 'Proces jest ',
    campaign: 'Kampania',
    process: 'Proces',
    time: 'Czas',
    successPopup: 'Raport pomyślnie pobrany',
    reports: 'Raporty',
    reportType: 'Typ raportu',
    fileFormat: 'Format pliku',
    reportFilters: 'Filtry raportu',
    campaignOrTime: 'Kampania lub czas',
    columnsInReport: 'Kolumny w raporcie',
    downloadReport: 'Pobierz raport',
    clearForm: 'Wyczyść formularz',
    noColumnsSelected: 'Proszę wybrać co najmniej 1 kolumnę.',
    selectCampaign: 'Wybierz kampanię',
    campaignIs: 'Kampania to',
    noCampaigns: 'Brak kampanii z tym identyfikatorem',
    removeSelection: 'usuń wybór',
    apply: 'zastosuj',
  },

  presets: {
    title: 'Konfiguracje',
    voices: 'Głosy',
    dialogues: 'Dialogi',
    callingPreferences: 'Preferencje dzwonienia',
    calendar: 'Kalendarz',
    callCenterDaysOff: 'Wolne dni call center',
    botDaysOff: 'Wolne dni bota',
    labels: 'Etykiety',
    customized: 'dostosowane',
    created: 'utworzono ',
    modified: 'zmodyfikowano ',
    associatedWith: 'powiązane z procesami:',
    noProcess: 'żaden proces nie jest powiązany z tą konfiguracją',
    deleteQuestion: 'Czy na pewno chcesz usunąć ten',
    noProcesses: 'Brak procesów',
    associatedline1:
      'Uwaga: Edycja tego ustawienia wstępnego spowoduje aktualizację wszystkich powiązanych procesów, które go wykorzystują, w tym: ',
    associatedline2: 'powiązane z procesami',
    addNewLabel: 'Dodaj nową etykietę',

    voicesForm: {
      name: 'Nazwa',
      language: 'Język',
      languageTooltip:
        'Określa język, w którym bot komunikacyjny będzie czytał wypowiedzi.',
      gender: 'Płeć',
      genderTooltip: 'Pole to pozwala na wybór płci głosu bota.',
      baseTtsSpeed: 'Tępo dykcji',
      baseTtsSpeedTooltip:
        'Pole określa prędkość z jaką bot czyta wypowiedzi wyrażone w procentach. 100 % to prędkość standardowa. Wartości poniżej spowalniają, a powyżej przyspieszają mowę.',
      asrVendor: 'Dostawca ASR',
      asrVendorTooltip:
        'Ten parametr odnosi się do wyboru dostawcy technologii rozpoznawania mowy, który konwertuje mowę użytkownika na tekst.',
      ttsVendor: 'Dostawca TTS',
      ttsVendorTooltip:
        'Ten parametr odnosi się do wyboru dostawcy technologii, która przekształca tekst generowany przez bota na mowę.',
      voiceName: 'Nazwa głosu',
      voiceNameTooltip: 'Wybór modelu głosowego dostępnego w ramach dostawcy.',
      noVoices: 'Brak głosów dla tej konfiguracji',
    },
    labelsForm: {
      selectLabels: 'Wybierz etykiety',
      color1: 'Delikatny Wielbłąd',
      color2: 'Pastelowa Lilia',
      color3: 'Delikatny Eukaliptus',
      color4: 'Pastelowe Niebo',
      color5: 'Ziemisty Brąz',
      color6: 'Jasne Drzewo Sandałowe',
      color7: 'Blady Bursztyn',
      color8: 'Jasna Pistacja',
      name: 'Nazwa',
      selectColor: 'Wybierz kolor',
      createNewLabel: 'Stwórz nową etykiete',
      editLabel: 'Edytuj etykietę',
      deleteQuestion: 'Czy na pewno chcesz usunąć tę etykietę?',
      deleteLabel: 'Usuń etykietę: ',
    },
    daysOff: {
      holidaysDate: 'Daty świąt',
      nothingToShow: 'Nic do pokazania',
      dateAlreadyAdded: 'Data już dodana',
      name: 'Nazwa',
    },
    callingPreferencesForm: {
      static: 'Statyczne',
      dynamic: 'Dynamiczne',
      randomized: 'Losowe',
      from: 'Od: ',
      to: 'Do: ',
      interval: 'Interwał: ',
      intervalError:
        'Nie można dodać nowego zestawu czasu, ponieważ przekroczyłoby to 24:00',
      endTimeError: 'Czas zakończenia musi być późniejszy niż czas rozpoczęcia',
      periodsCantOverlap: 'Okresy nie mogą się nakładać lub stykać',
      name: 'Nazwa',
      callAttempts: 'Łączna liczba prób połączeń',
      callAttemptsTooltip:
        'Określa maksymalną liczbę prób wykonania połączenia telefonicznego do danego rekordu w ramach jednej kampanii. Jeżeli limit jest osiągnięty, dalsze próby do tego numeru nie będą realizowane.',
      dailyCallAttempts: 'Dzienna liczba prób połączeń',
      dailyCallAttemptsTooltip:
        'Maksymalna liczba prób wykonania połączenia do danego numeru w ciągu jednego dnia. Jeśli ta liczba jest osiągnięta, bot zaczeka do następnego dnia przed kolejnymi próbami.',
      restDays: 'Opóźnienie ponownego połączenia',
      restDaysTooltip:
        'To pole określa liczbę dni, która musi upłynąć po osiągnięciu dziennego limitu prób połączeń, zanim bot skontaktuje się ponownie. Parametr dotyczy jedynie kampanii wielodniowych.',
      intervalBetweenAttempts: 'Interwał między próbami kontaktu',
      intervalBetweenAttemptsTooltip:
        'statyczne: stała wartość interwału pomiędzy próbami kontaktu, dynamiczne: wartość interwału zależna od pory dnia, losowe: wartość losowana z zakresu interwałów',
      timeBetweenAttemptsSeconds: 'Minimalny czas między próbami (HH:mm)',
      timeBetweenAttemptsSecondsTooltip:
        'Minimalny odstęp pomiędzy kolejnymi próbami kontaktu. Ustawienia kalendarza są nadrzędne.',

      minimal: 'Minimalny',
      maximal: 'Maksymalny',
      minimalError: 'Wartość minimalna musi być niższa od maksymalnej',
      timeRange: 'Zakres czasowy',
      timeRangeTooltip: 'Minimalny interwał między kolejną próbą kontaktu',
      addPeriod: 'Dodaj okres',
      basicInterval: 'Podstawowy interwał',
      basicIntervalTooltip:
        'Interwał stosowany poza skonfigurowanym zakresem czasowym',
    },
    calendarForm: {
      timezone: 'Strefa czasowa',
      workingHours: 'Godziny pracy',
      name: 'Nazwa',
      outbound: 'Wychodzące',
      inbound: 'Przychodzące',
      cc: 'Call center',
      notDefined: 'Nie zdefiniowane',
      monday: 'Poniedziałek',
      tuesday: 'Wtorek',
      wednesday: 'Środa',
      thursday: 'Czwartek',
      friday: 'Piątek',
      saturday: 'Sobota',
      sunday: 'Niedziela',
      addPeriod: 'Dodaj okres',
      periodsOverlap: 'Okresy nakładają się na siebie',
      periodsError:
        'Okresy nie powinny kończyć się i zaczynać o tej samej godzinie',
      endTimeGreater:
        'Czas zakończenia musi być późniejszy niż czas rozpoczęcia',
      inboundShorter: 'Przychodzące są krótsze niż wychodzące',
      outboundOutside: 'Wychodzące wykraczają poza przychodzące',
      nextInterval:
        'Następny interwał wykraczałby poza zakres, popraw poprzednie interwały, jeśli chcesz dodać nowe',
    },
  },
  processes: {
    title: 'Procesy',
    addNewColumn: 'Dodaj nową kolumnę',
    noCampaigns: 'Brak kampanii do wyświetlenia',
    seeCampaigns: 'Zobacz kampanie',
    uncategorized: 'Bez kategorii',
    noProcesses: 'Brak procesów do wyświetlenia',
    new: 'Nowy proces',
    customizeView: 'Dostosuj widok',
    campaignStatus: {
      pending: 'OCZEKUJĄCA',
      scheduled: 'ZAPLANOWANA',
      running: 'TRWAJĄCA',
      paused: 'WSTRZYMANA',
      stopped: 'ZAKOŃCZONA RĘCZNIE',
      finished: 'ZAKOŃCZONA',
      error: 'BŁĄD',
    },
    modals: {
      nameInUse: 'Nazwa jest już używana',
      lessThan: 'Długość nazwy kolumny musi być krótsza niż 16 znaków',
      name: 'Nazwa',
      createNewColumn: 'Utwórz nową kolumnę',
      nameOfProcess: 'Nazwa procesu',
      processNameError: 'Proces o tej nazwie już istnieje',
      createNewProcess: 'Utwórz nowy proces',
      deleteColumnQuestion: 'Czy na pewno chcesz usunąć tę kolumnę?',
      removeColumn: 'Usuń kolumnę: ',
      deleteProcessQuestion: 'Czy na pewno chcesz usunąć ten proces?',
      removeProcess: 'Usuń proces: ',
      currentName: 'Obecna nazwa',
      newName: 'Nowa nazwa',
      changeColumnName: 'Zmień nazwę kolumny',
    },
    form: {
      detailedTitle: 'Szczegóły procesu: ',
      title: 'Konfiguracja procesu: ',
      buttonDisabledText:
        'Publikowanie procesu będzie dostępne, gdy wszystkie wymagane informacje i ustawienia zostaną dostarczone.',
      publishProcess: 'Opublikuj proces',
      editProcess: 'Edytuj ten proces',

      tabs: {
        general: 'Ogólne',
        dialogue: 'Dialog',
        voice: 'Głos',
        callingPreferences: 'Ustawienia rozmów',
        campaignPreferences: 'Ustawienia kampanii',
        calendar: 'Kalendarz',
      },

      general: {
        add: 'Dodawanie',
        update: 'Aktualizowanie',
        yes: 'tak',
        additionalLinesYes: 'Dodatkowe wolne linie będą używane',
        no: 'nie',
        archive: 'archiwizuj',
        archiveTooltip:
          'Pliki zostaną przeniesione do folderu archiwizacji po przetworzeniu.',
        delete: 'usuwaj',
        deleteTooltip: 'Pliki zostaną trwale usunięte po przetworzeniu.',
        additionalLinesNo:
          'Dodatkowe wolne linie nie będą używane, nawet jeśli są dostępne.',
        currentDay: 'Bieżący dzień',
        full: 'Pełny',
        processName: 'Nazwa procesu',
        labels: 'Etykiety',
        description: 'Opis',
        csvImportMode: 'Tryb importu CSV',
        csvImportModeTooltip1: `Podczas przesyłania pliku CSV, nowe rekordy są tworzone dla każdego wiersza, a wszystkie istniejące rekordy z pasującym 'external_id' są zamykane.`,
        csvImportModeTooltip2:
          'Podczas przesyłania pliku CSV, każdy wiersz będzie skutkować utworzeniem nowego rekordu.',
        guarantedLines: 'Gwarantowane linie',
        guarantedLinesTooltip:
          'Ilość jednoczesnych prób kontaktu wykonywanych przez bota / ilość linii telekomunikacyjnych.',
        dynamicLinesUsage: 'Dynamiczne wykorzystanie linii',
        dynamicLinesUsageTooltip: `Ta opcja pozwala wybrać między maksymalizacją efektywności poprzez wykorzystanie wszystkich dostępnych linii dynamicznie dla uruchomionych kampanii, a zachowaniem statycznej konfiguracji, która konsekwentnie korzysta z liczby linii ustawionych w preferencjach.`,
        sftp: 'Transfer danych SFTP',
        inGlob: 'Wzorzec ścieżki importowanych plików / w glob',
        inGlobTooltip: `To pole służy do określenia wzorca ścieżki plików, które mają być zaimportowane. Umożliwia to filtrowanie plików z serwera SFTP na podstawie ich nazw i rozszerzeń. 'Glob' to standardowy język wzorców używany do dopasowywania plików i katalogów. Przykład: *.csv oznacza, że będą importowane tylko pliki z rozszerzeniem .csv.`,
        archivePath: 'Ścieżka archiwizacji',
        archivePathTooltip:
          'Ścieżka, w której zaimportowane pliki będą przechowywane w archiwum po przetworzeniu.',
        deleteArchived: 'Zarządzanie bazami klientów CSV po przetworzeniu',
        deleteArchivedTooltip: 'Czy importowane pliki mają być archiwizowane?',
        recordingsFormat: 'Format nagrań',
        importFileHandling: 'Tryb importu pliku CSV',
        importFileHandlingTooltip1:
          'Wybór tej opcji spowoduje utworzenie nowej kampanii na podstawie danych zawartych w zaimportowanym pliku. Wszystkie informacje zostaną użyte do zainicjowania nowej kampanii.',
        importFileHandlingTooltip2:
          'Ta opcja pozwala na aktualizację istniejącej kampanii o rekordy z nowego pliku. Uwaga: jeśli kampania nie istnieje, zostanie utworzona nowa.',
        externalSftpRecordings: 'Zewnętrzna ścieżka przesyłania nagrań SFTP',
        externalSftpRecordingsTooltip: `To pole określa ścieżkę na zewnętrznym serwerze SFTP, gdzie będą przesyłane nagrania. Upewnij się, że nadałeś odpowiednie uprawnienia do zapisu w tej lokalizacji na swoim serwerze SFTP.`,
        externalSftpReports: 'Zewnętrzna ścieżka przesyłania raportów SFTP',
        externalSftpReportsTooltip: `To pole określa ścieżkę na zewnętrznym serwerze SFTP, gdzie będą przesyłane raporty. Upewnij się, że nadałeś odpowiednie uprawnienia do zapisu w tej lokalizacji na swoim serwerze SFTP.`,
        externalSftpTranscript: 'Zewnętrzna ścieżka przesyłania transkrypcji',
        externalSftpTranscriptTooltip:
          'To pole określa ścieżkę na zewnętrznym serwerze SFTP, gdzie będą przesyłane transkrypcje. Upewnij się, że nadałeś odpowiednie uprawnienia do zapisu w tej lokalizacji na swoim serwerze SFTP.',
        uploadReports: 'Które dzienne raporty mają być wysyłane na SFTP?',
        cyclicReports: 'Które cykliczne raporty mają być wysyłane na SFTP?',
        perCall: 'za połączenie',
        perRecord: 'za rekord',
        perCampaign: 'za kampanię',
        generateReportDaily: 'Generować raport dziennie?',
        dailyReportType: 'Typ dziennego raportu',
        currentDay1:
          'Bieżący Dzień oznacza, że raport będzie zawierał tylko dane z bieżącego dnia.',
        currentDay2:
          'Obejmuje dane od samego początku kampanii, pokazując narastające wyniki, czyli wszystkie zgromadzone dane od startu kampanii do momentu tworzenia raportu.',
        send: 'wysyłaj',
        dailyReport: 'raporty dzienne',
        cyclicReport: 'raporty cykliczne',
        frequency: 'Częstotliwość',
        dailyDataRange: 'Zakres danych do raportu dziennego',
        cyclicDataRange: 'Zakres danych do raportu cyklicznego',
      },
      dialogue: {
        newFaas: 'Utwórz nową konfigurację niestandardowego faas',
        dialoguePreset: 'Konfiguracja dialogu',
        importFaas: 'importuj FAAS',
      },
      voices: {
        newVoice: 'Utwórz nową konfigurację dla głosu',
        voicePreset: 'Konfiguracja głosu',
        male: 'Męski',
        female: 'Damski',
      },
      callingPreferences: {
        newCallingPreference: 'Utwórz nową konfigurację połączeń',
        callingPreferencePreset: 'Ustawienia konfiguracji połączeń',
        phoneNumbers: 'Numery telefonów*',
        phoneNumbersTooltip:
          'Pole, w którym można wprowadzić numery telefonów, z których będzie wykonywana kampania.',
        atLeast: 'Musisz wybrać co najmniej jeden numer',
        note: 'Uwaga: przy wyborze wielu numerów, bot będzie priorytetowo wykonywał połączenia z różnych numerów jak najdłużej ',
      },
      calendar: {
        newCalendar: 'Utwórz nową konfigurację kalendarza',
        newPresetCC: 'Utwórz nową konfigurację call center - dni wolne',
        newPresetBot: 'Utwórz nową konfigurację bota - dni wolne',
        selectCalendar: 'Wybierz konfigurację kalendarza',
        timezone: 'Strefa czasowa',
        daysOffCC: 'Call center - dni wolne',
        nothing: 'Nic do pokazania',
        daysOffBot: 'Bot - dni wolne',
      },
      campaignPreferences: {
        caller: 'Dzwoniący',
        campaign: 'Kampania',
        inboundEnabled:
          'Czy połączenia przychodzące będą aktywne dla zamkniętego rekordu?',
        directTransfer:
          'Połączenie przychodzące (inbound) będzie połączone z: ',
        redirect:
          'Numer, który zostanie wyświetlony po przekierowaniu połączenia do call center',
        campaignDuration: 'Domyślny czas trwania kampanii (w dniach)',
        callCenterNumber: 'Numer telefonu call center',
        fallbackMessage:
          'Wiadomość, która zostanie wypowiedziana przez bota, jeśli inbound jest zamknięty.',
      },
    },
  },

  campaigns: {
    campaign: 'Kampania: ',
    outbound: 'Wychodzące',
    inbound: 'Przychodzące',
    cc: 'Call center',
    open: 'OTWARTA',
    paused: 'WSTRZYMANA',
    start: 'Początek',
    end: 'Koniec',
    changeStatus: 'Zmień status',
    finish: 'Zakończ',
    idCopied: 'ID skopiowane do schowka',
    copyID: 'Kopiuj ID kampanii',
    showCampaignNumbers: 'Pokaż numery kampanii',
    goToStats: 'Przejdź do analityki',
    goToReports: 'Przejdź do raportów',
    download: 'Pobierz ',
    transcripts: 'Transkrypcje ',
    recordings: 'Nagrania ',
    customReport: 'Niestandardowy raport',
    success: 'Kampania została pomyślnie utworzona',
    optimizationPopup:
      'Kampania zostanie ponownie uruchomiona i przez kilka minut nie będzie dostępna. W tym czasie nie będą realizowane żadne połączenia.',
    optimizationInfo: {
      line1: 'UWAGA: ',
      line2: `Po zapisaniu zmian kampania zostanie ponownie uruchomiona i przez kilka minut nie będzie dostępna. W tym czasie nie będą realizowane żadne połączenia.`,
    },
    serviceStatuses: {
      open: 'OTWARTE',
      paused: 'WSTRZYMANE',
      disabled: 'WYŁĄCZONE',
      closed: 'ZAMKNIĘTE',
    },
    createModal: {
      title: 'Utwórz kampanię',
      titleLoading: 'Tworzenie kampanii...',
      selectProcess: 'Wybierz proces',
      noProcesses: 'Brak procesów do wyboru',
      startEndDates: 'Daty rozpoczęcia-zakończenia',
      requestedLines: 'Żądane linie: ',
      availableLines: 'Dostępne linie: ',
      linesError: 'Żądane linie przekraczają dostępne linie.',
    },
    finishModal: {
      title: 'Zakończ kampanię',
      text1: 'Czy na pewno chcesz zakończyć tę kampanię?',
      text2: 'Ta akcja jest nieodwracalna.',
    },
    numbersModal: {
      title: 'Numery użyte w tej kampanii',
    },
    snapshots: {
      general: 'Ogólne',
      dialogue: 'Dialog',
      voice: 'Głos',
      callingPreferences: 'Preferencje połączeń',
      campaignPreferences: 'Preferencje kampanii',
      calendar: 'Kalendarz',
    },

    bar: {
      campaigns: 'Kampanie',
      newCampaign: 'Nowa kampania',
      filters: {
        title: 'Filtr',
        process: 'Proces',
        processIs: 'Proces to ',
        status: 'Status',
        statusIs: 'Status to ',
        date: 'Data',
        dateIs: 'Data to ',
        remove: 'usuń',
        apply: 'Zastosuj',
      },
    },

    table: {
      startDate: 'Data rozpoczęcia',
      endDate: 'Data zakończenia',
      process: 'Proces',
      status: 'Status',
      details: 'Szczegóły',
      doneTotal: 'Wykonane / Łącznie',
      doneTotalTooltip:
        'Pokazuje liczbę połączeń wykonanych spośród wszystkich rekordów w kampanii',
      labels: 'Etykiety z procesu',
      noCampaigns: 'Brak kampanii do wyświetlenia',
      outbound: 'wychodzące',
      inbound: 'przychodzące',
      cc: 'call center',
      seeMore: 'zobacz więcej',
    },
    history: {
      INITIALIZE: 'Kampania utworzona',
      DEPLOY: 'Kampania gotowa',
      RUN: 'Kampania w trakcie',
      PAUSE_RUNNING: 'Kampania wstrzymana',
      UNPAUSE_RUNNING: 'Kampania wznowiona',
      PAUSE_SCHEDULED: 'Kampania wstrzymana',
      UNPAUSE_SCHEDULED: 'Kampania wznowiona',
      ERROR: 'Błąd',
      STOP: 'Kampania zakończona ręcznie',
      FINISH: 'Kampania zakończona',
      PAUSE_INBOUND: 'Połączenia przychodzące wstrzymane',
      UNPAUSE_INBOUND: 'Połączenia przychodzące wznowione',
      PAUSE_CC: 'Przekierowanie do call center wstrzymane',
      UNPAUSE_CC: 'Przekierowanie do call center wznowione',
      UPLOAD_RECORDS: 'Nowe rekordy załadowane',
      userNotFound: 'Użytkownik nie znaleziony',
      byLog: 'przez {{firstName}} {{lastName}}',
      byUser: 'przez użytkownika',
      tokenInfoNotAvailable: 'Informacje o tokenie niedostępne',
      byToken: 'przez TOKEN',
      viaSftp: 'przez SFTP',
      byAdmin: 'przez administratora Lekta',
    },
    records: {
      campaignTabs: {
        records: 'Rekordy',
        calls: 'Połączenia',
        history: 'Historia',
        processSnapshot: 'Zrzut procesu',
        optimization: 'Optymalizacja',
      },
      table: {
        telephone: 'Numer telefonu',
        recordId: 'ID rekordu',
        importedDate: 'Data importu',
        source: 'Źródło',
        recordStatus: 'Status rekordu',
        recordDetails: 'Szczegóły rekordu',
        noDCM: 'Nie znaleziono numeru w mapowaniu kontekstu dialogu',
        noRecords: 'Brak rekordów do wyświetlenia',
        idCopied: 'ID skopiowane do schowka',
        copyID: 'kopiuj ID',
        callsHistory: 'Historia połączeń',
        open: 'OTWARTE',
        finished: 'ZAKOŃCZONE',
        cancelled: 'ANULOWANE',
        exhausted: 'NIEUDANE',
      },
      list: {
        uploadRecordsTitle: 'Prześlij rekordy dla tej kampanii',
        uploadRecords: 'Prześlij rekordy',
      },
      uploadModal: {
        csv: 'Obecnie obsługiwany jest tylko format pliku csv i xlsx',
        uploadImportHere: 'Prześlij tutaj plik importowy',
        fileErrorGpg:
          'Niepoprawny format pliku. Obsługiwane formaty: csv, xlsx lub gpg',
        fileErrorCsv:
          'Niepoprawny format pliku. Obsługiwane formaty: csv lub xlsx',
        fileErrorEmpty: 'Dostarczony plik jest pusty',
        somethingWentWrong:
          'Coś poszło nie tak, plik nie został przetworzony poprawnie.',
        uploadingRecords: 'Przesyłanie rekordów...',
        verifiedRecords: 'Zweryfikowane rekordy',
        recordsFailed: 'Rekordy nieudane: ',
        row: 'Rząd',
        recordsVerified: 'Rekordy zweryfikowane pomyślnie ',
      },
      filters: {
        title: 'Filtry',
        allFiltersApplied: 'Wszystkie filtry zostały zastosowane',
        source: 'Źródło',
        sourceIs: 'Źródło to ',
        status: 'Status',
        statusIs: 'Status to ',
        remove: 'usuń',
        apply: 'Zastosuj',
      },
    },
    calls: {
      filters: {
        VOICEMAIL: 'Poczta głosowa',
        ANSWERED: 'Odebrane',
        NOT_ANSWERED: 'Nieodebrane',
        REJECTED: 'Odrzucone',
        BUSY: 'Zajęte',
        TRANSFER: 'Transfer',
        NONEXISTENT_NUMBER: 'Nieistniejący numer',
        UNMAPPED: 'Inny',
        ERROR: 'Błąd',

        title: 'Filtry',
        allFiltersApplied: 'Wszystkie filtry zostały zastosowane',
        date: 'Data',
        dateIs: 'Data to',
        status: 'Status',
        statusIs: 'Status to ',
        callType: 'Typ połączenia',
        callTypeIs: 'Typ połączenia to ',
        remove: 'usuń',
        apply: 'Zastosuj',
        inbound: 'przychodzące',
        outbound: 'wychodzące',
      },
      list: {
        selected: 'Wybrane:',
        downloadTranscripts: 'Pobierz transkrypcje',
        downloadRecordings: 'Pobierz nagrania',
        date: 'Data',
        hour: 'Godzina',
        botNumber: 'Numer bota',
        type: 'Typ',
        customNumber: 'Numer klienta',
        telcoStatus: 'Status telco',
        noCalls: 'Brak połączeń do wyświetlenia',
        seeDetails: 'zobacz szczegóły',
      },
      detailed: {
        dashboard: 'Kokpit',
        campaigns: 'Kampanie',
        campaignNr: 'Nr kampanii ',
        callNr: 'Nr połączenia ',
        to: 'do',
        outboundCallFrom: 'Połączenie wychodzące od: ',
        downloadReport: 'Pobierz raport',
        callId: 'ID połączenia',
        idCopied: 'ID skopiowane do schowka',
        copyId: 'Kopiuj ID',
        dateAndTimeCallStart: 'Data i czas rozpoczęcia połączenia',
        callDuration: 'Czas trwania połączenia',
        campaign: 'Kampania',
        openingHours: 'Godziny otwarcia',
        callStatus: 'Status połączenia',
        callsFromThisRecord: 'Połączenia rekordu',
        recording: 'Nagranie',
        downloadRecording: 'Pobierz nagranie',
        noRecordings: 'Brak nagrań dostępnych dla tego połączenia',
        transcript: 'Transkrypcja',
        downloadTranscript: 'Pobierz transkrypcję',
        noTranscripts: 'Brak transkrypcji dostępnych dla tego połączenia',
        lektaBot: 'Bot Lekta',
        client: 'Klient',
        noRecording: 'Brak nagrania dostępnego dla tego połączenia',
        callsFromRecord: 'Połączenia z tego ID rekordu: ',
      },
    },
  },

  analytics: {
    panel: {
      title: 'Analityka',
      chooseProcess: 'Wybierz proces',
      filterType: 'Typ filtra',
      timeRange: 'Wybierz zakres czasu',
      selectCampaigns: 'Wybierz kampanie',
      clearFilters: 'Wyczyść filtry',
      selectedCampaigns: 'Wybrane kampanie:',
    },
    charts: {
      noDataToShow:
        'Brak danych do wyświetlenia. Proszę zastosować filtry, aby ustawić statystyki.',
      noDataAvailableLine1: 'Brak danych dla wybranego okresu.',
      noDataAvailableLine2: 'Proszę dostosować filtry.',
      overview: {
        contactRate: 'Współczynnik kontaktów',
        contactRateSubtitle:
          'Procent rekordów, które miały udane połączenie z klientem (każde połączenie ze statusem Odebrane)',
        conversionRate: 'Współczynnik konwersji',
        conversionRateSubtitle:
          'Procent połączeń wychodzących, które zakończyły się pożądanym wynikiem (status Gorący).',
        successRate: 'Współczynnik sukcesu',
        successRateSubtitle:
          'Procent połączeń wychodzących, które zakończyły się pożądanym wynikiem (status Zakończone).',
        databaseProgress: 'Postęp bazy danych',
        databaseProgressSubtitle:
          'Procent rekordów, które zostały pomyślnie zamknięte lub dostępne próby połączenia zostały zakończone.',
        records: 'Rekordy',
        callsAmount: 'Liczba połączeń',
        inbound: 'Przychodzące',
        outbound: 'Wychodzące',
        total: 'Łącznie: ',
        averageCallsTime: 'Średni czas połączeń',
        averageCallsTimeDesc:
          'Średni czas trwania połączenia wychodzącego i przychodzącego. Poczta głosowa nie jest uwzględniona.',
        totalCallsTime: 'Łączny czas połączeń',
        totalCallsTimeDesc:
          'Czas, przez który ludzie rozmawiali z botem. Poczta głosowa nie jest uwzględniona.',
        dialogueStatuses: 'Statusy dialogów',
        interuptionStatuses: 'Statusy przerw',
        processesStatuses: 'Statusy procesów',
        value: 'Wartości',
        percentage: 'Procent',
        oneCandle: '1 słupek reprezentuje: ',
        count: 'Ilość: ',
      },
    },
  },

  billings: {
    all: 'Wszystkie',
    today: 'Dzisiaj',
    last7days: 'Ostatnie 7 dni',
    thisMonth: 'Ten miesiąc',
    lastMonth: 'Ostatni miesiąc',
    custom: 'Niestandardowy',
    loading: 'Ładowanie...',
    title: 'Rozliczenia',
    tenant: 'Organizacja',
    selectTenant: 'Wybierz organizację',
    clearFilters: 'Wyczyść filtry',
    process: 'Przetwarzaj',
    startDate: 'data rozpoczęcia',
    endDate: 'data zakończenia',
    pasteCampaignID: 'Wklej ID kampanii',
    pleaseSelectTenant: 'Proszę wybrać organizację.',
    table: {
      ofSelected: 'z {{length}} wybranych',
      selectAll: 'wybierz wszystko',
      total: 'Razem: ',
      telcoCost: 'Koszt telekomunikacji: ',
      smsCost: 'Koszt SMS: ',
      doneTotal: 'Wykonano/ Razem',
      exportFile: 'Eksportuj plik',
      tenantName: 'Nazwa organizacji',
      process: 'Przetwarzaj',
      campaignID: 'ID kampanii',
      startDate: 'Data rozpoczęcia',
      endDate: 'Data zakończenia',
      status: 'Status',
      telcoCostTable: 'Koszt telco',
      smsCostTable: 'Koszt SMS',
      noBillingsToShow: 'Brak rozliczeń do wyświetlenia.',
    },
  },

  dashboard: {
    filters: {
      oldest: 'data: najstarsze',
      newest: 'data: najnowsze',
      alpha_asc: 'alfabetycznie: od A do Z',
      alpha_desc: 'alfabetycznie: od Z do A',
    },
    title: 'Panel',
    description:
      "Wyświetlanie aktywnych kampanii na dzisiaj (tylko tych ze statusem 'W trakcie' lub 'Wstrzymane').",
    sort: 'Sortowanie',
    noCampaignsToShow: 'Brak kampanii do wyświetlenia.',
    outbound: 'Wychodzące',
    voicemail: 'Poczta głosowa',
    answered: 'Odebrane',
    other: 'Inne',
    inbound: 'Przychodzące',
    callCenter: 'Call center',
    databaseCompletion: 'Ukończenie bazy danych',
    records: 'rekordy',
    callAnalysis: 'Analiza połączeń dla tej kampanii',
    callSuccessRate: 'Wskaźnik sukcesu połączeń',
    callConversionRate: 'Wskaźnik konwersji połączeń',
    callHotRate: 'Wskaźnik gorących połączeń',
    callActivityChart: 'Wykres aktywności połączeń',
  },

  sidebar: {
    dashboard: 'Pulpit',
    processes: 'Procesy',
    campaigns: 'Kampanie',
    reports: 'Raporty',
    presets: 'Konfiguracje',
    popover: {
      settings: 'Ustawienia',
      superAdmin: 'Panel SA',
      logout: 'Wyloguj',
    },
    dialogue: 'Dialog',
  },

  popups: {
    success: {
      title: 'Sukces',
    },
    error: {
      title: 'Błąd',
    },
  },

  common: {
    yes: 'tak',
    no: 'nie',
    created: 'utworzono ',
    modified: 'zmodyfikowano ',
    createNew: 'Stwórz nowy',
    restore: 'Przywróć',
    archive: 'Archiwizuj',
    somethingWentWrong: 'coś poszło nie tak',
    copyId: 'Kopiuj ID',
    copiedId: 'ID skopiowane do schowka',
    next: 'Dalej',
    error: 'Błąd',
    save: 'Zapisz',
    tooWeak: 'za słabe',
    notSelected: 'Nie wybrano',
    select: 'Wybierz opcję',
    update: 'Aktualizuj',
    duplicate: 'Duplikuj',
    remove: 'Usuń,',
    cancel: 'Anuluj',
    edit: 'Edytuj',
    confirm: 'Potwierdź',
    create: 'Utwórz',
    writeHere: 'pisz tutaj',
    submit: 'Zatwierdź',
    invite: 'Dodaj',
    here: 'tutaj',
    delete: 'Usuń',
    goBack: 'Wróć',
    saveChanges: 'Zapisz zmiany',
    modify: 'Modyfikuj',
    close: 'Zamknij',
    search: 'Szukaj...',

    user: 'Użytkownik',
    admin: 'Administrator',
    owner: 'Właściciel',
    loadingModalTitle: 'Zapisywanie zmian...',
    preview: 'Podgląd',
    getInfo: 'Uzyskaj informacje',
    newCampaign: 'Nowa kampania',
    lastChange: 'ostatnia zmiana: {{date}} przez {{name}}',
    selectProcess: 'Wybierz proces',
    showArchive: 'pokaż archiwalne',
    hideArchive: 'ukryj archiwalne',
    filterOptions: 'Opcje filtrowania',

    permissions: {
      no_permissions: 'brak uprawnień',
      read_only: 'tylko do odczytu',
      process_write: 'procesy',
      campaign_write: 'kampanie',
      process_campaign_write: 'kampanie, procesy',
      workspace: 'przestrzeń robocza',
    },
    noPermissions: {
      title: 'Pusty pulpit',
      subtitle1: 'Wygląda na to, że nie masz dostępu do żadnej organizacji.',
      subtitle2:
        'Porozmawiaj z administratorem, aby rozpocząć pracę z platformą Lekta Lease',
    },
  },
};
