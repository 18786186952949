import React, { useEffect, useRef, useState } from 'react';
import {
  FileFormatContainer,
  GenerateReportButtonContainer,
  ReportButton,
  ReportFiltersContainer,
  ReportFormContainer,
  ReportFormLeftContainer,
  ReportFormRightContainer,
  ReportTypeContainer,
  ReportsBarContainer,
  ReportsPanelContainer,
  SuperSelectContainer,
} from './Reports.style';

import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiLoadingSpinner,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from '@elastic/eui';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../processes/api/processSlice';
import {
  selectStrategiesList,
  selectStrategyId,
} from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import moment, { Moment } from 'moment';
import ReportsCampaignInput from './ReportsCampaignInput';
import {
  getCampaignsPanelListAsyncThunk,
  selectCampaignsPanelList,
} from '../campaigns/api/campaignsSlice';
import { CampaignOverview } from '../../../generated/tenants/Api';
import { H4, H3, H1, fontIBM, Button } from '../../App.style';
import {
  generateReportPerCallAsyncThunk,
  generateReportPerCampaignAsyncThunk,
  generateReportPerRecordAsyncThunk,
} from './api/reportsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessPopup from '../../../common/popups/SuccessPopup';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../common/modals/LoadingModal';
import ProcessesInput from '../../../common/inputs/ProcessesInput';

type ReportType =
  | 'report_per_call'
  | 'report_per_record'
  | 'report_per_campaign';

type CampaignOrTime = 'campaign' | 'time';

const ReportsPanel = () => {
  const { t } = useTranslation();
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const location = useLocation();

  //report type
  const [reportType, setReportType] = useState<ReportType>('report_per_call');
  const reportTypeOptions = [
    {
      value: 'report_per_call',
      inputDisplay: <H4 $medium>{t('reports.reportPerCall')}</H4>,
      dropdownDisplay: <H3>{t('reports.reportPerCall')}</H3>,
    },
    {
      value: 'report_per_record',
      inputDisplay: <H4 $medium>{t('reports.reportPerRecord')}</H4>,
      dropdownDisplay: <H3>{t('reports.reportPerRecord')}</H3>,
    },
    {
      value: 'report_per_campaign',
      inputDisplay: <H4 $medium>{t('reports.reportPerCampaign')}</H4>,
      dropdownDisplay: <H3>{t('reports.reportPerCampaign')}</H3>,
    },
  ];

  //file format
  const [fileFormat, setFileFormat] = useState<'csv' | 'json' | 'xlsx'>('csv');

  const fileFormatOptions = ['csv', 'json', 'xlsx'].map((format) => {
    return {
      value: format,
      inputDisplay: <H4 $medium>{format}</H4>,
      dropdownDisplay: <H3>{format}</H3>,
    };
  });

  //report filters process
  const [selectedProcessId, setSelectedProcessId] = useState<string>('');

  useEffect(() => {
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);
  const apiProcessesList = useAppSelector(selectProcessesList);
  const apiStrategiesList = useAppSelector(selectStrategiesList);
  function findStrategyNameById(id: string) {
    return apiStrategiesList.find((strategy) => strategy.id === id)?.name ?? '';
  }

  const processesOptions = [
    ...apiProcessesList.map((process) => {
      return {
        value: process.id,
        processName: process.name,
        strategyName: findStrategyNameById(process.strategy),
        is_archived: process.is_archived,
      };
    }),
  ];
  //report filters campaign or time
  const [campaignOrTime, setCampaignOrTime] = useState<CampaignOrTime>();
  const campaignOrTimeRef = useRef<HTMLDivElement>(null);

  const handleCampaignOrTimeClick = () => {
    const button = campaignOrTimeRef.current?.querySelector('button');
    if (button) {
      button.click();
    }
  };

  const campaignOrTimeOptions: EuiSuperSelectOption<string>[] = [
    {
      value: 'campaign',
      inputDisplay: <H4 $medium>{t('reports.campaign')}</H4>,
      dropdownDisplay: <H3>{t('reports.campaign')}</H3>,
    },
    {
      value: 'time',
      inputDisplay: <H4 $medium>{t('reports.time')}</H4>,
      dropdownDisplay: <H3>{t('reports.time')}</H3>,
    },
  ];

  //time
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>();

  const dateError = startDate && endDate && endDate.isBefore(startDate);

  //campaigns
  const [selectedCampaigns, setSelectedCampaigns] = useState<
    CampaignOverview[]
  >([]);
  useEffect(() => {
    if (campaignOrTime === 'campaign' && selectedProcessId) {
      const data = {
        strategyId: strategy,
        query: { process_ids: [selectedProcessId] },
      };
      dispatch(getCampaignsPanelListAsyncThunk(data));
    }
  }, [campaignOrTime, selectedProcessId]);

  const apiCampaignsList = useAppSelector(selectCampaignsPanelList);

  const isGenerateReportButtonDisabled = () => {
    if (selectedProcessId === undefined || campaignOrTime === undefined) {
      return true;
    }

    if (campaignOrTime === 'campaign' && selectedCampaigns.length === 0) {
      return true;
    }

    if (
      campaignOrTime === 'time' &&
      (startDate === undefined || endDate === undefined)
    ) {
      return true;
    }

    if (dateError) {
      return true;
    }

    return false;
  };

  interface GenerateReportData<T> {
    processId: string;
    data: T;
  }

  const handleGenerateReport = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reportType || !selectedProcessId) {
      alert('Something went wrong');
      return;
    }

    const downloadReport = (payload: { url: string; filename: string }) => {
      const link = document.createElement('a');
      link.href = payload.url;
      link.download = payload.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any;
    setIsLoadingVisible(true);

    if (reportType === 'report_per_call') {
      data = {
        file_type: fileFormat,
      };

      if (campaignOrTime === 'campaign') {
        data.campaign_ids = selectedCampaigns.map((campaign) => campaign.id);
      } else if (campaignOrTime === 'time') {
        data.start_date = startDate
          ? startDate.format('YYYY-MM-DD')
          : undefined;
        data.end_date = endDate ? endDate.format('YYYY-MM-DD') : undefined;
      } else {
        alert('Something went wrong');
        return;
      }

      const payload: GenerateReportData<typeof data> = {
        processId: selectedProcessId,
        data,
      };

      dispatch(generateReportPerCallAsyncThunk(payload))
        .then((returnedAction) => {
          if (generateReportPerCallAsyncThunk.fulfilled.match(returnedAction)) {
            downloadReport(returnedAction.payload);
            setTimeout(() => {
              setSuccessPopupVisible(true);
            }, 1000);
          } else {
            alert('something went wrong');
          }
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    } else if (reportType === 'report_per_campaign') {
      data = {
        file_type: fileFormat,
        campaign_ids: selectedCampaigns.map((campaign) => campaign.id),
      };

      const payload: GenerateReportData<typeof data> = {
        processId: selectedProcessId,
        data,
      };

      dispatch(generateReportPerCampaignAsyncThunk(payload))
        .then((returnedAction) => {
          if (
            generateReportPerCampaignAsyncThunk.fulfilled.match(returnedAction)
          ) {
            downloadReport(returnedAction.payload);
            setTimeout(() => {
              setSuccessPopupVisible(true);
            }, 1000);
          } else {
            alert('something went wrong');
          }
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    } else if (reportType === 'report_per_record') {
      data = {
        file_type: fileFormat,
        campaign_ids: selectedCampaigns.map((campaign) => campaign.id),
      };

      const payload: GenerateReportData<typeof data> = {
        processId: selectedProcessId,
        data,
      };

      dispatch(generateReportPerRecordAsyncThunk(payload))
        .then((returnedAction) => {
          if (
            generateReportPerRecordAsyncThunk.fulfilled.match(returnedAction)
          ) {
            downloadReport(returnedAction.payload);
            setTimeout(() => {
              setSuccessPopupVisible(true);
            }, 1000);
          } else {
            alert('something went wrong');
          }
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    } else {
      alert('Invalid report type');
      setIsLoadingVisible(false);
    }
  };

  const handleClearFormClick = () => {
    setReportType('report_per_call');
    setFileFormat('csv');
    setSelectedProcessId('');
    setCampaignOrTime(undefined);
    setSelectedCampaigns([]);
    setStartDate(moment());
    setEndDate(undefined);
  };

  //popup
  const [successPopupVisible, setSuccessPopupVisible] =
    useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (successPopupVisible) {
      setSuccessPopupVisible(true);

      timer = setTimeout(() => {
        setSuccessPopupVisible(false);
      }, 10000); // Close the popup after 5 seconds
    }

    // Clean up function
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [successPopupVisible]);

  const resetForm = () => {
    setReportType('report_per_call');
    setFileFormat('csv');
    setSelectedProcessId('undefined');
    setCampaignOrTime(undefined);
    setSelectedCampaigns([]);
  };

  const isValidUUID = (value: string): boolean => {
    return value.length === 36;
  };

  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.toString() && !selectedProcessId) {
      resetForm();
    }

    const processId = params.get('processId');

    if (
      processId &&
      processId !== selectedProcessId &&
      isValidUUID(processId)
    ) {
      setSelectedProcessId(processId);
      setCampaignOrTime('campaign');
    } else if (processId && !isValidUUID(processId)) {
      navigate('/dashboard/reports');
    }

    const campaignId = params.get('campaignId');
    if (campaignId && apiCampaignsList && isValidUUID(campaignId)) {
      const selectedCampaign = apiCampaignsList?.results?.find(
        (campaign) => campaign.id === campaignId,
      );
      if (selectedCampaign) {
        setSelectedCampaigns([selectedCampaign]);
      }
    } else if (campaignId && !isValidUUID(campaignId)) {
      navigate('/dashboard/reports');
    }
  }, [location, apiCampaignsList]);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <ReportsPanelContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {successPopupVisible && (
        <SuccessPopup
          text="Report downloaded successfully"
          onClose={() => setSuccessPopupVisible(false)}
        />
      )}

      <ReportsBarContainer>
        <H1 $medium>{t('reports.reports')}</H1>
      </ReportsBarContainer>
      <ReportFormContainer onSubmit={handleGenerateReport}>
        <ReportTypeContainer>
          <ReportFormLeftContainer>
            <H3>{t('reports.reportType')}</H3>
          </ReportFormLeftContainer>
          <ReportFormRightContainer>
            <EuiSuperSelect
              placeholder={t('common.select')}
              options={reportTypeOptions}
              valueOfSelected={reportType}
              onChange={(e) => {
                setReportType(e as ReportType);
                setSelectedProcessId('undefined');
                setSelectedCampaigns([]);
                if (e === 'report_per_call') {
                  setCampaignOrTime(undefined);
                }
              }}
            />
          </ReportFormRightContainer>
        </ReportTypeContainer>

        <FileFormatContainer>
          <ReportFormLeftContainer>
            <H3>{t('reports.fileFormat')}</H3>
          </ReportFormLeftContainer>

          <ReportFormRightContainer>
            <EuiSuperSelect
              valueOfSelected={fileFormat}
              options={fileFormatOptions}
              onChange={(e) => setFileFormat(e as 'csv' | 'json' | 'xlsx')}
            />
          </ReportFormRightContainer>
        </FileFormatContainer>

        <ReportFiltersContainer>
          <ReportFormLeftContainer>
            <H3>{t('reports.reportFilters')}</H3>
          </ReportFormLeftContainer>
          <ReportFormRightContainer>
            <ProcessesInput
              processesList={processesOptions}
              selectedProcessId={selectedProcessId}
              setSelectedProcessId={(e) => {
                if (e === selectedProcessId) {
                  setCampaignOrTime(undefined);
                  setSelectedProcessId('');
                } else {
                  setSelectedProcessId(e);
                }
                setSelectedCampaigns([]);
              }}
              fullWidth
            />

            {reportType === 'report_per_call' && (
              <SuperSelectContainer>
                {campaignOrTime === undefined && (
                  <ReportButton
                    onClick={handleCampaignOrTimeClick}
                    disabled={selectedProcessId === undefined}
                  >
                    <H4 $grey={selectedProcessId === undefined}>
                      {t('reports.campaignOrTime')}
                    </H4>
                  </ReportButton>
                )}
                <div ref={campaignOrTimeRef}>
                  <EuiSuperSelect
                    style={{ border: campaignOrTime && '1px solid #127FBF' }}
                    options={campaignOrTimeOptions}
                    valueOfSelected={campaignOrTime ? campaignOrTime : ''}
                    disabled={!selectedProcessId}
                    onChange={(e) => {
                      if (e === campaignOrTime) {
                        setCampaignOrTime(undefined);
                        setSelectedCampaigns([]);
                      } else {
                        setCampaignOrTime(e as CampaignOrTime);
                      }
                    }}
                  />
                </div>
              </SuperSelectContainer>
            )}

            {campaignOrTime === 'time' ? (
              <EuiDatePickerRange
                css={{
                  width: 380,
                  background: 'white',
                  cursor: 'pointer',
                  border: dateError ? '' : '1px solid #127FBF',
                }}
                isInvalid={dateError}
                startDateControl={
                  <EuiDatePicker
                    css={{
                      background: 'white',
                      fontFamily: fontIBM,
                      cursor: 'pointer',
                    }}
                    selected={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    startDate={startDate}
                    endDate={endDate}
                    aria-label="Start date"
                    timeFormat="HH:mm"
                    showTimeSelect
                    dateFormat="DD.MM.YYYY, HH:mm"
                  />
                }
                endDateControl={
                  <EuiDatePicker
                    css={{
                      background: 'white',
                      fontFamily: fontIBM,
                      cursor: 'pointer',
                    }}
                    selected={endDate}
                    onChange={(date) => date && setEndDate(date)}
                    startDate={startDate}
                    endDate={endDate}
                    aria-label="End date"
                    showTimeSelect
                    timeFormat="HH:mm"
                    placeholder="DD.MM.YYYY, HH:MM"
                    dateFormat="DD.MM.YYYY, HH:mm"
                  />
                }
              />
            ) : (
              campaignOrTime === 'campaign' && (
                <>
                  {apiCampaignsList?.results ? (
                    <ReportsCampaignInput
                      selectedCampaigns={selectedCampaigns}
                      setSelectedCampaigns={setSelectedCampaigns}
                      campaignsList={apiCampaignsList.results}
                    />
                  ) : (
                    <EuiLoadingSpinner size={'m'} />
                  )}
                </>
              )
            )}
          </ReportFormRightContainer>
        </ReportFiltersContainer>

        <GenerateReportButtonContainer>
          <Button
            type={'button'}
            $size={'M'}
            $styleType="NORMAL"
            onClick={handleClearFormClick}
          >
            {t('reports.clearForm')}
          </Button>
          <Button type={'submit'} disabled={isGenerateReportButtonDisabled()}>
            {t('reports.downloadReport')}
          </Button>
        </GenerateReportButtonContainer>
      </ReportFormContainer>
    </ReportsPanelContainer>
  );
};

export default ReportsPanel;
