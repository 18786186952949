import {
  InvitationUpdateRequest,
  PasswordResetToken,
  PasswordResetTokenRequest,
  ResetPasswordRequest,
} from './../../../../generated/public/Api';
import {
  Api,
  InvitationRequest,
  Invitation,
  UserPublic,
  PatchedUserPermissionEditRequest,
} from '../../../../generated/tenants/Api';
import { ApiError } from '../../../../common/types';
import { Api as PublicApi } from '../../../../generated/public/Api';

const { api } = new Api();

export interface CheckInviteUserResult {
  status: 'success' | 'fail';
  error?: ApiError<Invitation>;
}

export interface CheckDeleteUserResult {
  status: 'success' | 'fail';
  error?: ApiError<string>;
}

export const getUsersList = (query?: {
  filter_info?: string;
  limit?: number;
  offset?: number;
  strategy_id?: string;
}) => api.usersList(query).then((_) => _.data);

export const deleteUsers = (query: {
  ids: Array<string>;
}): Promise<CheckDeleteUserResult> =>
  api
    .usersBatchDestroy({ id: query.ids })
    .then(() => {
      return { status: 'success' } as CheckDeleteUserResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getUserDetailed = (id: string) =>
  api.usersRetrieve(id).then((_) => _.data);

export const getUserPermissions = (strategyId: string) =>
  api.strategiesPermissionsRetrieve(strategyId).then((_) => _.data);

//invitation
export const sendInvitation = (
  args: InvitationRequest,
): Promise<CheckInviteUserResult> =>
  api
    .invitationsCreate(args)
    .then(() => {
      return { status: 'success' } as CheckInviteUserResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

const { api: publicApi } = new PublicApi();

export interface CheckInvitationResult {
  status: 'success' | 'fail' | 'needInfo';
  value?: UserPublic;
}

export interface CheckAddUserResult {
  status: 'success' | 'fail';
  error?: ApiError<{ password: 'string' }>;
}

export const checkInvitationToken = (
  token: string,
): Promise<CheckInvitationResult> =>
  publicApi.invitationsRetrieve(token).then((response) => {
    if (response.status === 204) {
      return { status: 'needInfo' };
    } else if (response.status === 200) {
      return {
        status: 'success',
        user: response.data,
      };
    } else {
      return { status: 'fail' };
    }
  });

export const invitationComplete = (
  token: string,
  data: InvitationUpdateRequest,
): Promise<CheckAddUserResult> =>
  publicApi
    .invitationsUpdate(token, data)
    .then(() => {
      return { status: 'success' } as CheckAddUserResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

//password reset

export interface CheckPasswordResetTokenResult {
  status: 'success' | 'fail';
  error?: ApiError<PasswordResetToken>;
  token?: string;
}

export const checkPasswordResetToken = (
  token: string,
): Promise<CheckPasswordResetTokenResult> =>
  publicApi.usersCheckPasswordResetTokenRetrieve(token).then((response) => {
    if (response.status === 204) {
      return { status: 'success' } as CheckPasswordResetTokenResult;
    } else {
      return { status: 'fail' } as CheckPasswordResetTokenResult;
    }
  });

export const sendPasswordResetToken = (
  data: PasswordResetTokenRequest,
): Promise<CheckPasswordResetTokenResult> =>
  publicApi
    .usersGeneratePasswordResetTokenCreate(data)
    .then(() => {
      return { status: 'success' } as CheckPasswordResetTokenResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export interface CheckPasswordResetResult {
  status: 'success' | 'fail';
  error?: ApiError<ResetPasswordRequest>;
}

export const resetUserPassword = (
  data: ResetPasswordRequest,
): Promise<CheckPasswordResetResult> =>
  publicApi
    .usersResetPasswordCreate(data)
    .then(() => {
      return { status: 'success' } as CheckPasswordResetResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

//permissions

export const userPermissionsUpdate = (
  userId: string,
  data: PatchedUserPermissionEditRequest,
) => api.usersPermissionsPartialUpdate(userId, data).then((_) => _.data);
