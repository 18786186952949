import {
  EuiCheckbox,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexItem,
  EuiRadioGroup,
  EuiSuperSelect,
  EuiSwitch,
  EuiText,
  EuiTextArea,
  EuiToolTip,
} from '@elastic/eui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../common/hooks';
import { H3, H4 } from '../../../App.style';
import { ApiError } from '../../../../common/types';
import {
  ImportModeEnum,
  ImportFileHandlingEnum,
  Label,
  Process,
  ProcessCreateRequest,
  RecordingsFormatEnum,
} from '../../../../generated/tenants/Api';
import LabelsComboboxComponent from '../../presets/labels/LabelsComboboxComponent';
import {
  selectStrategyId,
  selectStrategiesList,
} from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  FormRow,
  FormContainer,
  CyclicFormContainer,
  SwitchFormRowContainer,
} from './ProcessForm.style';
import FormRowTitle from './FormRowTitle';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from '../../../../resources/icons-new/InfoIcon';

interface GeneralFormProps {
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  detailed: boolean;
  edit?: boolean;
  apiErrors: ApiError<Process>;
  setApiErrors: React.Dispatch<React.SetStateAction<ApiError<Process>>>;
}

const GeneralForm: React.FC<GeneralFormProps> = ({
  process,
  setProcess,
  edit,
  apiErrors,
  setApiErrors,
}) => {
  const { t } = useTranslation();
  //recordings format
  const recordingsFormat = ['mp3', 'wav', 'flac'];
  const recordingsFormatOptions = recordingsFormat.map((text) => {
    return {
      value: text,
      inputDisplay: text,
      dropdownDisplay: <H3>{text}</H3>,
    };
  });

  const csvImportModeOptions = [
    {
      value: 'ADD',
      inputDisplay: t('processes.form.general.add'),
      dropdownDisplay: <H3>{t('processes.form.general.add')}</H3>,
    },
    {
      value: 'UPDATE',
      inputDisplay: t('processes.form.general.update'),
      dropdownDisplay: <H3>{t('processes.form.general.update')}</H3>,
    },
  ];

  //dynamic lines usage

  const dynamigLinesUsageOptions = [
    {
      id: 'true',
      label: (
        <div style={{ display: 'flex', gap: 10 }}>
          <H4>{t('processes.form.general.yes')}</H4>
          <H4 $grey>{t('processes.form.general.additionalLinesYes')}</H4>
        </div>
      ),
    },
    {
      id: 'false',
      label: (
        <div style={{ display: 'flex', gap: 10 }}>
          <H4>{t('processes.form.general.no')}</H4>
          <H4 $grey>{t('processes.form.general.additionalLinesNo')}</H4>
        </div>
      ),
    },
  ];

  const importFileHandling = ['NEW_CAMPAIGN', 'UPDATE_EXISTING_CAMPAIGN'];
  const importFileHandlingOptions = importFileHandling.map((text) => {
    return {
      value: text,
      inputDisplay: text,
      dropdownDisplay: <H3>{text}</H3>,
    };
  });

  const deleteArchivedFilesOptions = [
    {
      id: 'false',
      label: (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <H4>{t('processes.form.general.archive')}</H4>
          <EuiToolTip content={t('processes.form.general.archiveTooltip')}>
            <InfoIcon />
          </EuiToolTip>
        </div>
      ),
    },
    {
      id: 'true',
      label: (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <H4>{t('processes.form.general.delete')}</H4>
          <EuiToolTip content={t('processes.form.general.deleteTooltip')}>
            <InfoIcon />
          </EuiToolTip>
        </div>
      ),
    },
  ];

  //guaranteed lines logic

  const strategy = useAppSelector(selectStrategyId);
  const strategiesList = useAppSelector(selectStrategiesList);

  const currentStrategyLines = strategiesList.find(
    (obj) => obj.id === strategy,
  )?.assigned_lines;

  const { name } = useParams();

  useEffect(() => {
    setTimeout(() => {
      if (name) {
        setProcess({ ...process, name: name });
      }
    }, 400);
  }, [name]);

  const handleUpdateLabels = (labelsArr: Label[]) => {
    const labelIds = labelsArr.map((label) => label.id);

    setProcess({ ...process, labels: labelIds });
  };

  const dailyReportTypeRadios = [
    {
      id: 'CURRENT_DAY_DAILY',
      label: (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <H4>{t('processes.form.general.currentDay')}</H4>
          <EuiToolTip content={t('processes.form.general.currentDay1')}>
            <InfoIcon />
          </EuiToolTip>
        </div>
      ),
    },
    {
      id: 'FROM_START_DAILY',
      label: (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <H4>{t('processes.form.general.full')}</H4>
          <EuiToolTip content={t('processes.form.general.currentDay2')}>
            <InfoIcon />
          </EuiToolTip>
        </div>
      ),
    },
  ];

  const cyclicReportTypeRadios = [
    {
      id: 'CURRENT_DAY_CYCLIC',
      label: (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <H4>{t('processes.form.general.currentDay')}</H4>
          <EuiToolTip content={t('processes.form.general.currentDay1')}>
            <InfoIcon />
          </EuiToolTip>
        </div>
      ),
    },
    {
      id: 'FROM_START_CYCLIC',
      label: (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <H4>{t('processes.form.general.full')}</H4>
          <EuiToolTip content={t('processes.form.general.currentDay2')}>
            <InfoIcon />
          </EuiToolTip>
        </div>
      ),
    },
  ];

  return (
    <FormContainer $processForm>
      <FormRow
        label={<H3>{t('processes.form.general.processName')}</H3>}
        isInvalid={apiErrors.name ? true : false}
        error={apiErrors.name}
      >
        <EuiFieldText
          isInvalid={apiErrors.name ? true : false}
          readOnly={!edit}
          value={process.name}
          maxLength={48}
          onChange={(e) => {
            setProcess({ ...process, name: e.target.value });
            setApiErrors({ ...apiErrors, name: undefined });
          }}
          max={48}
        />
      </FormRow>

      <FormRow label={<H3>{t('processes.form.general.labels')}</H3>}>
        <LabelsComboboxComponent
          labels={process.labels}
          isDisabled={!edit}
          handleUpdateLabels={handleUpdateLabels}
        />
      </FormRow>

      <FormRow label={<H3>{t('processes.form.general.description')}</H3>}>
        <EuiTextArea
          autoFocus
          style={{ height: 100, minHeight: 50, maxHeight: 260 }}
          maxLength={500}
          readOnly={!edit}
          value={process.description ? process.description : ''}
          onChange={(e) =>
            setProcess({ ...process, description: e.target.value })
          }
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('processes.form.general.csvImportMode')}
            tooltip={
              process.import_mode === 'UPDATE'
                ? t('processes.form.general.csvImportModeTooltip1')
                : t('processes.form.general.csvImportModeTooltip2')
            }
          />
        }
      >
        {!edit ? (
          <EuiFieldText
            value={
              process.import_mode === 'ADD'
                ? t('processes.form.general.add')
                : t('processes.form.general.update')
            }
            readOnly
          />
        ) : (
          <EuiSuperSelect
            options={csvImportModeOptions}
            valueOfSelected={process.import_mode}
            onChange={(e) => {
              setProcess({
                ...process,
                import_mode: e as ImportModeEnum,
              });
            }}
          />
        )}
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('processes.form.general.guarantedLines')}
            tooltip={t('processes.form.general.guarantedLinesTooltip')}
          />
        }
      >
        <EuiFieldNumber
          readOnly={!edit}
          value={process.guaranteed_lines}
          onChange={(e) => {
            setProcess({
              ...process,
              guaranteed_lines: Number(e.target.value),
            });
          }}
          append={
            <EuiToolTip content="">
              <EuiText>max {currentStrategyLines}</EuiText>
            </EuiToolTip>
          }
          max={currentStrategyLines}
          min={0}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('processes.form.general.dynamicLinesUsage')}
            tooltip={t('processes.form.general.dynamicLinesUsageTooltip')}
          />
        }
      >
        <EuiRadioGroup
          disabled={!edit}
          options={dynamigLinesUsageOptions}
          idSelected={process.use_idle_lines ? 'true' : 'false'}
          onChange={(id) =>
            setProcess({
              ...process,
              use_idle_lines: id === 'true' ? true : false,
            })
          }
          name="dynamic-lines-usage"
        />
      </FormRow>

      <FormRow
        style={{ position: 'relative' }}
        label={<H3>{t('processes.form.general.sftp')}</H3>}
      >
        <EuiFlexItem
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            left: 140,
            top: 0,
          }}
        >
          <EuiSwitch
            compressed
            disabled={!edit}
            label=""
            checked={process.sftp_data_transfer ? true : false}
            onChange={(e) =>
              setProcess({ ...process, sftp_data_transfer: e.target.checked })
            }
          />
        </EuiFlexItem>
      </FormRow>

      {process.sftp_data_transfer && (
        <>
          <FormRow
            label={
              <FormRowTitle
                title={t('processes.form.general.inGlob')}
                tooltip={t('processes.form.general.inGlobTooltip')}
              />
            }
          >
            <EuiFieldText
              readOnly={!edit}
              value={process.in_glob ? process.in_glob : ''}
              onChange={(e) => {
                setProcess({
                  ...process,
                  in_glob: e.target.value,
                });
              }}
            />
          </FormRow>

          <FormRow
            label={
              <FormRowTitle
                title={t('processes.form.general.deleteArchived')}
                tooltip={t('processes.form.general.deleteArchivedTooltip')}
              />
            }
          >
            <EuiRadioGroup
              disabled={!edit}
              id="delete-archived-files"
              options={deleteArchivedFilesOptions}
              idSelected={process.delete_archived_files ? 'true' : 'false'}
              onChange={(id) =>
                setProcess({
                  ...process,
                  delete_archived_files: id === 'true' ? true : false,
                })
              }
            />
          </FormRow>

          {process.delete_archived_files === false && (
            <FormRow
              label={
                <FormRowTitle
                  title={t('processes.form.general.archivePath')}
                  tooltip={t('processes.form.general.archivePathTooltip')}
                />
              }
            >
              <EuiFieldText
                readOnly={!edit}
                value={process.archive_path ? process.archive_path : ''}
                onChange={(e) => {
                  setProcess({
                    ...process,
                    archive_path: e.target.value,
                  });
                }}
              />
            </FormRow>
          )}

          <FormRow
            label={<H3>{t('processes.form.general.recordingsFormat')}</H3>}
          >
            {!edit ? (
              <EuiFieldText value={process.recordings_format} readOnly />
            ) : (
              <EuiSuperSelect
                options={recordingsFormatOptions}
                valueOfSelected={process.recordings_format}
                onChange={(e) => {
                  setProcess({
                    ...process,
                    recordings_format: e as RecordingsFormatEnum,
                  });
                }}
              />
            )}
          </FormRow>

          <FormRow
            label={
              <FormRowTitle
                title={t('processes.form.general.importFileHandling')}
                tooltip={
                  process.import_file_handling === 'NEW_CAMPAIGN'
                    ? t('processes.form.general.importFileHandlingTooltip1')
                    : t('processes.form.general.importFileHandlingTooltip2')
                }
              />
            }
          >
            {!edit ? (
              <EuiFieldText value={process.import_file_handling} readOnly />
            ) : (
              <EuiSuperSelect
                options={importFileHandlingOptions}
                valueOfSelected={process.import_file_handling}
                onChange={(e) =>
                  setProcess({
                    ...process,
                    import_file_handling: e as ImportFileHandlingEnum,
                  })
                }
              />
            )}
          </FormRow>

          <FormRow
            label={
              <FormRowTitle
                title={t('processes.form.general.externalSftpRecordings')}
                tooltip={t(
                  'processes.form.general.externalSftpRecordingsTooltip',
                )}
              />
            }
          >
            <EuiFieldText
              readOnly={!edit}
              value={process.external_sftp_recordings_path ?? ''}
              onChange={(e) => {
                setProcess({
                  ...process,
                  external_sftp_recordings_path: e.target.value,
                });
              }}
            />
          </FormRow>

          <FormRow
            label={
              <FormRowTitle
                title={t('processes.form.general.externalSftpReports')}
                tooltip={t('processes.form.general.externalSftpReportsTooltip')}
              />
            }
          >
            <EuiFieldText
              readOnly={!edit}
              value={process.external_sftp_reports_path ?? ''}
              onChange={(e) => {
                setProcess({
                  ...process,
                  external_sftp_reports_path: e.target.value,
                });
              }}
            />
          </FormRow>

          <FormRow
            label={
              <FormRowTitle
                title={t('processes.form.general.externalSftpTranscript')}
                tooltip={t(
                  'processes.form.general.externalSftpTranscriptTooltip',
                )}
              />
            }
          >
            <EuiFieldText
              readOnly={!edit}
              value={process.external_sftp_transcripts_path ?? ''}
              onChange={(e) => {
                setProcess({
                  ...process,
                  external_sftp_transcripts_path: e.target.value,
                });
              }}
            />
          </FormRow>

          <SwitchFormRowContainer>
            <EuiSwitch
              disabled={true}
              label=""
              checked={process.daily_report ? true : false}
              onChange={(e) =>
                setProcess({
                  ...process,
                  daily_report: e.target.checked,
                })
              }
              compressed
            />
            {t('processes.form.general.send')}{' '}
            <b>{t('processes.form.general.dailyReport')}</b>
          </SwitchFormRowContainer>

          {process.daily_report && (
            <>
              <FormRow
                label={
                  <FormRowTitle
                    title={t('processes.form.general.uploadReports')}
                  />
                }
              >
                <div style={{ marginTop: 5 }}>
                  <EuiCheckbox
                    disabled={!edit}
                    id="report_per_call"
                    label={<H3>{t('processes.form.general.perCall')}</H3>}
                    compressed
                    checked={process.upload_report_per_call ?? false}
                    onChange={(e) => {
                      setProcess({
                        ...process,
                        upload_report_per_call: e.target.checked,
                      });
                    }}
                  />
                  <EuiCheckbox
                    disabled={!edit}
                    id="report_per_record"
                    compressed
                    label={<H3>{t('processes.form.general.perRecord')}</H3>}
                    checked={process.upload_report_per_record ?? false}
                    onChange={(e) => {
                      setProcess({
                        ...process,
                        upload_report_per_record: e.target.checked,
                      });
                    }}
                  />
                  <EuiCheckbox
                    disabled={!edit}
                    id="per campaign"
                    compressed
                    label={<H3>{t('processes.form.general.perCampaign')}</H3>}
                    checked={process.upload_report_per_campaign ?? false}
                    onChange={(e) => {
                      setProcess({
                        ...process,
                        upload_report_per_campaign: e.target.checked,
                      });
                    }}
                  />
                </div>
              </FormRow>

              <FormRow
                style={{ marginBottom: 16 }}
                label={
                  <FormRowTitle
                    title={t('processes.form.general.dailyDataRange')}
                  />
                }
              >
                <EuiRadioGroup
                  disabled={!edit}
                  id="daily-report-type"
                  options={dailyReportTypeRadios}
                  idSelected={
                    process.daily_report_data_range === 'CURRENT_DAY'
                      ? 'CURRENT_DAY_DAILY'
                      : 'FROM_START_DAILY'
                  }
                  onChange={(id) => {
                    setProcess({
                      ...process,
                      daily_report_data_range:
                        id === 'CURRENT_DAY_DAILY'
                          ? 'CURRENT_DAY'
                          : 'FROM_START',
                    });
                  }}
                />
              </FormRow>

              <SwitchFormRowContainer>
                <EuiSwitch
                  disabled={!edit}
                  label=""
                  checked={process.cyclic_reports ? true : false}
                  onChange={(e) =>
                    setProcess({
                      ...process,
                      cyclic_reports: e.target.checked,
                    })
                  }
                  compressed
                />
                {t('processes.form.general.send')}{' '}
                <b>{t('processes.form.general.cyclicReport')}</b>
              </SwitchFormRowContainer>

              {process.cyclic_reports && (
                <CyclicFormContainer>
                  <FormRow
                    label={
                      <FormRowTitle
                        title={t('processes.form.general.cyclicReports')}
                      />
                    }
                  >
                    <div style={{ marginTop: 5 }}>
                      <EuiCheckbox
                        disabled={!edit}
                        id="cyclic_report_per_call"
                        label={<H3>{t('processes.form.general.perCall')}</H3>}
                        compressed
                        checked={process.cyclic_report_per_call ?? false}
                        onChange={(e) => {
                          setProcess({
                            ...process,
                            cyclic_report_per_call: e.target.checked,
                          });
                        }}
                      />
                      <EuiCheckbox
                        disabled={!edit}
                        id="cyclic_report_per_record"
                        compressed
                        label={<H3>{t('processes.form.general.perRecord')}</H3>}
                        checked={process.cyclic_report_per_record ?? false}
                        onChange={(e) => {
                          setProcess({
                            ...process,
                            cyclic_report_per_record: e.target.checked,
                          });
                        }}
                      />
                      <EuiCheckbox
                        disabled={!edit}
                        id="cyclic_report_per_campaign"
                        compressed
                        label={
                          <H3>{t('processes.form.general.perCampaign')}</H3>
                        }
                        checked={process.cyclic_report_per_campaign ?? false}
                        onChange={(e) => {
                          setProcess({
                            ...process,
                            cyclic_report_per_campaign: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </FormRow>

                  <FormRow
                    label={
                      <FormRowTitle
                        title={t('processes.form.general.frequency')}
                      />
                    }
                  >
                    <EuiFieldNumber
                      placeholder="0"
                      min={0}
                      max={120}
                      value={process.cyclic_reports_frequency_seconds ?? 0}
                      onChange={(e) => {
                        setProcess({
                          ...process,
                          cyclic_reports_frequency_seconds: Number(
                            e.currentTarget.value,
                          ),
                        });
                      }}
                      aria-label="Frequency"
                    />
                  </FormRow>

                  <FormRow
                    label={
                      <FormRowTitle
                        title={t('processes.form.general.cyclicDataRange')}
                      />
                    }
                  >
                    <EuiRadioGroup
                      disabled={!edit}
                      id="cyclic-report-type-2"
                      options={cyclicReportTypeRadios}
                      idSelected={
                        process.cyclic_report_data_range === 'CURRENT_DAY'
                          ? 'CURRENT_DAY_CYCLIC'
                          : 'FROM_START_CYCLIC'
                      }
                      onChange={(id) => {
                        setProcess({
                          ...process,
                          cyclic_report_data_range:
                            id === 'CURRENT_DAY_CYCLIC'
                              ? 'CURRENT_DAY'
                              : 'FROM_START',
                        });
                      }}
                    />
                  </FormRow>
                </CyclicFormContainer>
              )}
            </>
          )}
        </>
      )}
    </FormContainer>
  );
};

export default GeneralForm;
