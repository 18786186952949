import { EuiFlexItem, EuiPopover } from '@elastic/eui';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { Button, H2, H3 } from '../../../../../App.style';
import { Strategy } from '../../../../../../generated/tenants/Api';
import { setStrategyId } from './api/strategiesSlice';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PopoverStrategies, StrategyButton } from './StrategiesPanel.style';

import { ChevronUpIcon } from '../../../../../../resources/icons-new/ChevronUpIcon';
import { useNavigate } from 'react-router-dom';
import { selectPermissions } from '../../../../users/api/userSlice';
import { TenantSettingsIcon } from '../../../../../../resources/icons-new/TenantSettingsIcon';
import {
  SidebarSelectContainer,
  SidebarStrategiesContainer,
} from '../../../../sidebar/Sidebar.style';

interface StrategyNavbarSelectProps {
  selectedStrategy: Strategy;
  setSelectedStrategy: React.Dispatch<
    React.SetStateAction<Strategy | undefined>
  >;
  strategiesList: Strategy[];
}

const StrategyNavbarSelect: React.FC<StrategyNavbarSelectProps> = ({
  selectedStrategy,
  setSelectedStrategy,
  strategiesList,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [isStrategyPopoverOpen, setIsStrategyPopoverOpen] =
    useState<boolean>(false);

  const strategiesOptions = strategiesList.map((strategy) => {
    return { value: strategy.id, text: strategy.name };
  });

  const handleChangeStrategy = (e: string) => {
    const selectedStrategy = strategiesList.find(
      (strategy) => strategy.id === e,
    );

    if (selectedStrategy) {
      dispatch(setStrategyId(selectedStrategy.id));
      setSelectedStrategy(selectedStrategy);
      setIsStrategyPopoverOpen(false);
      window.location.reload();
    }
  };

  const handleGoToAdminPage = () => {
    navigate('/tenant_panel/');
  };

  const userPermissions = useAppSelector(selectPermissions);

  const isTenantSettingsVisible = () => {
    return userPermissions?.is_tenant_admin || userPermissions?.is_tenant_owner;
  };

  return (
    <EuiFlexItem>
      <EuiPopover
        ownFocus={false}
        button={
          <SidebarStrategiesContainer>
            <SidebarSelectContainer
              onClick={() => setIsStrategyPopoverOpen((prev) => !prev)}
            >
              <H2>{selectedStrategy?.name}</H2>
              {isStrategyPopoverOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </SidebarSelectContainer>
          </SidebarStrategiesContainer>
        }
        isOpen={isStrategyPopoverOpen}
        closePopover={() => setIsStrategyPopoverOpen(false)}
        anchorPosition="downCenter"
        panelPaddingSize="none"
        hasArrow={false}
      >
        <PopoverStrategies>
          {strategiesOptions.map((strategy, index) => {
            const isSelected = selectedStrategy.id === strategy.value;
            return (
              <StrategyButton
                key={`strategy-${index}`}
                $selected={isSelected}
                disabled={isSelected}
                onClick={() => handleChangeStrategy(strategy.value)}
              >
                <H3>{strategy.text}</H3>
              </StrategyButton>
            );
          })}

          {isTenantSettingsVisible() && (
            <div style={{ padding: '8px 12px' }}>
              <Button
                $size="S"
                $styleType="NORMAL"
                onClick={handleGoToAdminPage}
              >
                Tenant settings{' '}
                <TenantSettingsIcon $pointer style={{ width: 14 }} />
              </Button>
            </div>
          )}
        </PopoverStrategies>
      </EuiPopover>
    </EuiFlexItem>
  );
};

export default StrategyNavbarSelect;
