/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdminInvitation {
  /** @format uuid */
  readonly id: string;
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  /**
   * * `SUPERUSER` - Superuser
   * * `SUPERVISOR` - Supervisor
   */
  role: RoleEnum;
  /** @format date-time */
  readonly expires_at: string;
}

export interface AdminInvitationRequest {
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string;
  /**
   * * `SUPERUSER` - Superuser
   * * `SUPERVISOR` - Supervisor
   */
  role: RoleEnum;
}

export interface AdminRoleUpdate {
  is_super_admin: boolean;
  is_supervisor: boolean;
}

export interface AdminUser {
  /** @format uuid */
  readonly id: string;
  /**
   * Email Address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 128 */
  first_name?: string;
  /** @maxLength 128 */
  last_name?: string;
  /** @format date-time */
  last_login?: string | null;
  is_super_admin?: boolean;
  is_supervisor?: boolean;
}

export interface AppConfig {
  /** @maxLength 256 */
  base_domain: string;
}

export interface BillingCampaignList {
  /** @format uuid */
  id: string;
  tenant_name: string;
  process_name: string;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  status: string;
  telco_cost: string;
  sms_cost: string;
  total_cost: string;
  loaded_records: number;
  finished_records: number;
}

export interface BillingCompany {
  /** @format uuid */
  id: string;
  slug: string;
  strategies: BillingStrategy[];
}

export interface BillingConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /**
   * @format double
   * @min 0.01
   */
  domestic_mobile_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  international_mobile_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  domestic_landline_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  international_landline_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  sms_price: number;
  /**
   * * `PLN` - Pln
   * * `EUR` - Eur
   * * `USD` - Usd
   */
  currency: CurrencyEnum;
  /** * `PER_CALL` - Per Call */
  template: BillingConfigurationTemplateEnum;
}

export interface BillingConfigurationRequest {
  /**
   * @format double
   * @min 0.01
   */
  domestic_mobile_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  international_mobile_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  domestic_landline_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  international_landline_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  sms_price: number;
  /**
   * * `PLN` - Pln
   * * `EUR` - Eur
   * * `USD` - Usd
   */
  currency: CurrencyEnum;
  /** * `PER_CALL` - Per Call */
  template: BillingConfigurationTemplateEnum;
}

/** * `PER_CALL` - Per Call */
export type BillingConfigurationTemplateEnum = 'PER_CALL';

export interface BillingProcess {
  name: string;
  /** @format uuid */
  id: string;
  is_archived: boolean;
}

export interface BillingProcessList {
  tenants: BillingCompany[];
}

export interface BillingRaportRequest {
  /** @minLength 1 */
  slug: string;
  /** @format uuid */
  process_id: string | null;
  campaign_ids: string[];
}

export interface BillingStrategy {
  name: string;
  processes: BillingProcess[];
}

/**
 * * `pl-PL` - pl-PL
 * * `es-ES` - es-ES
 * * `es-MX` - es-MX
 * * `en-US` - en-US
 * * `en-AU` - en-AU
 */
export type BotLanguageEnum = 'pl-PL' | 'es-ES' | 'es-MX' | 'en-US' | 'en-AU';

export interface Company {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  readonly name: string;
  /**
   * Tenant URL Name
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  readonly slug: string;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sftp_uid?: number;
  readonly free_lines: number;
  details: string;
  timezone: string;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /**
   * @min 1
   * @max 2147483647
   */
  available_lines?: number;
  /** @format uuid */
  sms_gateway?: string | null;
  billing_configuration: BillingConfiguration;
}

export interface CompanyCreateRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  slug: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /** @min 1 */
  available_lines: number;
  /** @minLength 1 */
  details?: string;
  timezone: string;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /** @format uuid */
  sms_gateway?: string;
  billing_configuration: BillingConfigurationRequest;
}

export interface CompanyPrivate {
  slug: string;
  external_gateway: string;
}

/**
 * * `PLN` - Pln
 * * `EUR` - Eur
 * * `USD` - Usd
 */
export type CurrencyEnum = 'PLN' | 'EUR' | 'USD';

export interface InvitationUpdateRequest {
  /**
   * @minLength 8
   * @maxLength 128
   */
  password: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  first_name: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  last_name: string;
}

export interface LoginRequest {
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  password: string;
}

export interface NumberList {
  phone_numbers: string[];
}

export interface NumberListRequest {
  phone_numbers: string[];
}

export interface PaginatedAdminUserList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: AdminUser[];
}

export interface PaginatedBillingCampaignListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: BillingCampaignList[];
}

export interface PasswordResetToken {
  /** @format email */
  email: string;
}

export interface PasswordResetTokenRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface PatchedAdminRoleUpdateRequest {
  is_super_admin?: boolean;
  is_supervisor?: boolean;
}

export interface PatchedCompanyCreateRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  slug?: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  /** @min 1 */
  available_lines?: number;
  /** @minLength 1 */
  details?: string;
  timezone?: string;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /** @format uuid */
  sms_gateway?: string;
  billing_configuration?: BillingConfigurationRequest;
}

export interface PatchedPhoneNumberPatchRequest {
  /** @format uuid */
  company?: string | null;
}

export interface PatchedSmsConfigurationRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  gateway?: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  header?: string;
}

export interface PhoneNumberCreateRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  tenant_slug?: string;
  phone_numbers: string[];
  /** @minLength 1 */
  mobile_operator: string;
}

export interface PhoneNumberDetail {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 128 */
  phone_number: string;
  /** @format uuid */
  company?: string | null;
  readonly tenant_slug: string;
  readonly external_gateway: string;
}

export interface PhoneNumberHistory {
  /** @format date-time */
  created_at: string;
  tenants: PhoneNumberHistoryTenant[];
}

export interface PhoneNumberHistoryCampaign {
  /** @format uuid */
  id: string;
  /** @format date */
  created_at: string;
  /** @format date */
  ended_at: string;
  bot_label: string;
  calls_count: number;
  is_active: boolean;
  deleted: boolean;
}

export interface PhoneNumberHistoryProcess {
  /** @format uuid */
  id: string;
  name: string;
  calls_count: number;
  deleted: boolean;
  campaigns: PhoneNumberHistoryCampaign[];
  strategy_name: string;
}

export interface PhoneNumberHistoryTenant {
  name: string;
  slug: string;
  /** @format uuid */
  id: string;
  /** @format date-time */
  assigned_to_tenant_date: string;
  processes: PhoneNumberHistoryProcess[];
}

export interface PhoneNumberPatch {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  readonly phone_number: string;
  /** @format uuid */
  company?: string | null;
  readonly tenant_slug: string;
  /** @format uuid */
  readonly sip_trunk: string;
}

export interface PhoneNumberResults {
  added_numbers: string[];
  wrong_numbers: string[];
  already_assigned: string[];
}

export interface RcsTransfer {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @maxLength 128 */
  call_phone_number: string;
  /** @maxLength 128 */
  campaign_phone_number: string;
  /** @maxLength 128 */
  transfer_phone_number: string;
}

export interface RcsTransferPhoneNumber {
  /** @format uuid */
  id: string;
  transfer_phone_number: string;
}

export interface RcsTransferRequest {
  /**
   * @minLength 1
   * @maxLength 128
   */
  call_phone_number: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  campaign_phone_number: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  transfer_phone_number: string;
}

export interface ResetPassword {
  /**
   * @minLength 12
   * @maxLength 128
   */
  new_password: string;
  /**
   * @minLength 12
   * @maxLength 128
   */
  confirm_password: string;
  password_reset_token: string;
}

export interface ResetPasswordRequest {
  /**
   * @minLength 12
   * @maxLength 128
   */
  new_password: string;
  /**
   * @minLength 12
   * @maxLength 128
   */
  confirm_password: string;
  /** @minLength 1 */
  password_reset_token: string;
}

/**
 * * `SUPERUSER` - Superuser
 * * `SUPERVISOR` - Supervisor
 */
export type RoleEnum = 'SUPERUSER' | 'SUPERVISOR';

export interface SmsConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 64 */
  gateway: string;
  /** @maxLength 64 */
  header: string;
}

export interface SmsConfigurationRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  gateway: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  header: string;
}

export interface UserChangePasswordRequest {
  /** @minLength 1 */
  current_password: string;
  /**
   * @minLength 8
   * @maxLength 128
   */
  new_password: string;
  /** @minLength 1 */
  confirm_password: string;
}

export interface UserPublic {
  /** @format uuid */
  readonly id: string;
  /**
   * Email Address
   * @format email
   * @maxLength 254
   */
  email: string;
  is_super_admin?: boolean;
  is_supervisor?: boolean;
  readonly companies: Company[];
  /** @maxLength 128 */
  first_name?: string;
  /** @maxLength 128 */
  last_name?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title No title
 * @version 0.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags admin-invitations
     * @name AdminInvitationsList
     * @request GET:/api/admin-invitations
     * @secure
     * @response `200` `(AdminInvitation)[]`
     */
    adminInvitationsList: (params: RequestParams = {}) =>
      this.request<AdminInvitation[], any>({
        path: `/api/admin-invitations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-invitations
     * @name AdminInvitationsCreate
     * @request POST:/api/admin-invitations
     * @secure
     * @response `201` `AdminInvitation`
     */
    adminInvitationsCreate: (data: AdminInvitationRequest, params: RequestParams = {}) =>
      this.request<AdminInvitation, any>({
        path: `/api/admin-invitations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-invitations
     * @name AdminInvitationsRetrieve
     * @request GET:/api/admin-invitations/{token}
     * @secure
     * @response `200` `UserPublic`
     * @response `204` `void` No response body
     */
    adminInvitationsRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/admin-invitations/${token}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-invitations
     * @name AdminInvitationsUpdate
     * @request PUT:/api/admin-invitations/{token}
     * @secure
     * @response `200` `UserPublic`
     */
    adminInvitationsUpdate: (token: string, data: InvitationUpdateRequest, params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/admin-invitations/${token}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-users
     * @name AdminUsersList
     * @request GET:/api/admin-users
     * @secure
     * @response `200` `PaginatedAdminUserList`
     */
    adminUsersList: (
      query?: {
        filter_info?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedAdminUserList, any>({
        path: `/api/admin-users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-users
     * @name AdminUsersAdminPermissionsPartialUpdate
     * @request PATCH:/api/admin-users/{user_id}/admin-permissions
     * @secure
     * @response `200` `AdminRoleUpdate`
     */
    adminUsersAdminPermissionsPartialUpdate: (
      userId: string,
      data: PatchedAdminRoleUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<AdminRoleUpdate, any>({
        path: `/api/admin-users/${userId}/admin-permissions`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags config
     * @name ConfigRetrieve
     * @request GET:/api/config
     * @secure
     * @response `200` `AppConfig`
     */
    configRetrieve: (params: RequestParams = {}) =>
      this.request<AppConfig, any>({
        path: `/api/config`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsRetrieve
     * @request GET:/api/invitations/{token}
     * @secure
     * @response `200` `UserPublic`
     * @response `204` `void` No response body
     */
    invitationsRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/invitations/${token}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsUpdate
     * @request PUT:/api/invitations/{token}
     * @secure
     * @response `200` `UserPublic`
     */
    invitationsUpdate: (token: string, data: InvitationUpdateRequest, params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/invitations/${token}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session
     * @name SessionRetrieve
     * @request GET:/api/session
     * @secure
     * @response `200` `UserPublic`
     */
    sessionRetrieve: (params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/session`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session
     * @name SessionCreate
     * @request POST:/api/session
     * @secure
     * @response `201` `UserPublic`
     */
    sessionCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/session`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session
     * @name SessionDestroy
     * @request DELETE:/api/session
     * @secure
     * @response `204` `void` No response body
     */
    sessionDestroy: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/session`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersChangePasswordUpdate
     * @request PUT:/api/users/change-password
     * @secure
     * @response `204` `void` No response body
     */
    usersChangePasswordUpdate: (data: UserChangePasswordRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/change-password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersCheckPasswordResetTokenRetrieve
     * @request GET:/api/users/check-password-reset-token/{token}
     * @secure
     * @response `200` `void` No response body
     */
    usersCheckPasswordResetTokenRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/check-password-reset-token/${token}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersGeneratePasswordResetTokenCreate
     * @request POST:/api/users/generate-password-reset-token
     * @secure
     * @response `200` `PasswordResetToken`
     */
    usersGeneratePasswordResetTokenCreate: (data: PasswordResetTokenRequest, params: RequestParams = {}) =>
      this.request<PasswordResetToken, any>({
        path: `/api/users/generate-password-reset-token`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersResetPasswordCreate
     * @request POST:/api/users/reset-password
     * @secure
     * @response `200` `ResetPassword`
     */
    usersResetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<ResetPassword, any>({
        path: `/api/users/reset-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  private = {
    /**
     * No description
     *
     * @tags private
     * @name PrivateApiBillingsCampaignListList
     * @request GET:/private/api/billings/campaign-list
     * @secure
     * @response `200` `PaginatedBillingCampaignListList`
     */
    privateApiBillingsCampaignListList: (
      query: {
        /** @format uuid */
        campaign_ids?: string;
        /** @format date */
        end_date?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** @format uuid */
        process_id?: string;
        /** @format date */
        start_date?: string;
        /** @format uuid */
        tenant_id: string;
        time?: 'CUSTOM' | 'LAST_7_DAYS' | 'LAST_MONTH' | 'THIS_MONTH' | 'TODAY';
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedBillingCampaignListList, any>({
        path: `/private/api/billings/campaign-list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiBillingsProcessListRetrieve
     * @request GET:/private/api/billings/process-list
     * @secure
     * @response `200` `BillingProcessList`
     */
    privateApiBillingsProcessListRetrieve: (params: RequestParams = {}) =>
      this.request<BillingProcessList, any>({
        path: `/private/api/billings/process-list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiBillingsRaportCreate
     * @request POST:/private/api/billings/raport
     * @secure
     * @response `200` `File`
     */
    privateApiBillingsRaportCreate: (data: BillingRaportRequest, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/private/api/billings/raport`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesList
     * @request GET:/private/api/companies
     * @secure
     * @response `200` `(Company)[]`
     */
    privateApiCompaniesList: (params: RequestParams = {}) =>
      this.request<Company[], any>({
        path: `/private/api/companies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesCreate
     * @request POST:/private/api/companies
     * @secure
     * @response `201` `Company`
     */
    privateApiCompaniesCreate: (data: CompanyCreateRequest, params: RequestParams = {}) =>
      this.request<Company, any>({
        path: `/private/api/companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesRetrieve
     * @request GET:/private/api/companies/{id}
     * @secure
     * @response `200` `Company`
     */
    privateApiCompaniesRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<Company, any>({
        path: `/private/api/companies/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesUpdate
     * @request PUT:/private/api/companies/{id}
     * @secure
     * @response `200` `Company`
     */
    privateApiCompaniesUpdate: (id: string, data: CompanyCreateRequest, params: RequestParams = {}) =>
      this.request<Company, any>({
        path: `/private/api/companies/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesPartialUpdate
     * @request PATCH:/private/api/companies/{id}
     * @secure
     * @response `200` `Company`
     */
    privateApiCompaniesPartialUpdate: (id: string, data: PatchedCompanyCreateRequest, params: RequestParams = {}) =>
      this.request<Company, any>({
        path: `/private/api/companies/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesDestroy
     * @request DELETE:/private/api/companies/{id}
     * @secure
     * @response `204` `void` No response body
     */
    privateApiCompaniesDestroy: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/private/api/companies/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesPhoneNumbersCreate
     * @request POST:/private/api/companies/{id}/phone-numbers
     * @secure
     * @response `201` `NumberList`
     */
    privateApiCompaniesPhoneNumbersCreate: (id: string, data: NumberListRequest, params: RequestParams = {}) =>
      this.request<NumberList, any>({
        path: `/private/api/companies/${id}/phone-numbers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiCompaniesByNumberRetrieve
     * @request GET:/private/api/companies/by-number
     * @secure
     * @response `200` `CompanyPrivate`
     */
    privateApiCompaniesByNumberRetrieve: (
      query?: {
        campaign_number?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CompanyPrivate, any>({
        path: `/private/api/companies/by-number`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiPhoneNumbersList
     * @request GET:/private/api/phone-numbers
     * @secure
     * @response `200` `(PhoneNumberDetail)[]`
     */
    privateApiPhoneNumbersList: (params: RequestParams = {}) =>
      this.request<PhoneNumberDetail[], any>({
        path: `/private/api/phone-numbers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiPhoneNumbersCreate
     * @request POST:/private/api/phone-numbers
     * @secure
     * @response `201` `PhoneNumberResults`
     */
    privateApiPhoneNumbersCreate: (data: PhoneNumberCreateRequest, params: RequestParams = {}) =>
      this.request<PhoneNumberResults, any>({
        path: `/private/api/phone-numbers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiPhoneNumbersRetrieve
     * @request GET:/private/api/phone-numbers/{id}
     * @secure
     * @response `200` `PhoneNumberDetail`
     */
    privateApiPhoneNumbersRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<PhoneNumberDetail, any>({
        path: `/private/api/phone-numbers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiPhoneNumbersPartialUpdate
     * @request PATCH:/private/api/phone-numbers/{id}
     * @secure
     * @response `200` `PhoneNumberPatch`
     */
    privateApiPhoneNumbersPartialUpdate: (
      id: string,
      data: PatchedPhoneNumberPatchRequest,
      params: RequestParams = {},
    ) =>
      this.request<PhoneNumberPatch, any>({
        path: `/private/api/phone-numbers/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiPhoneNumbersHistoryRetrieve
     * @request GET:/private/api/phone-numbers/{id}/history
     * @secure
     * @response `200` `PhoneNumberHistory`
     */
    privateApiPhoneNumbersHistoryRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<PhoneNumberHistory, any>({
        path: `/private/api/phone-numbers/${id}/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiRcsTransferRetrieve
     * @request GET:/private/api/rcs-transfer
     * @secure
     * @response `200` `RcsTransferPhoneNumber`
     * @response `404` `void` No response body
     */
    privateApiRcsTransferRetrieve: (
      query: {
        call_phone_number: string;
        campaign_phone_number: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RcsTransferPhoneNumber, void>({
        path: `/private/api/rcs-transfer`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiRcsTransferCreate
     * @request POST:/private/api/rcs-transfer
     * @secure
     * @response `200` `RcsTransfer`
     */
    privateApiRcsTransferCreate: (data: RcsTransferRequest, params: RequestParams = {}) =>
      this.request<RcsTransfer, any>({
        path: `/private/api/rcs-transfer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiRcsTransferDestroy
     * @request DELETE:/private/api/rcs-transfer
     * @secure
     * @response `204` `void` No response body
     */
    privateApiRcsTransferDestroy: (
      query: {
        /** @format uuid */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/private/api/rcs-transfer`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSmsConfigurationList
     * @request GET:/private/api/sms-configuration
     * @secure
     * @response `200` `(SmsConfiguration)[]`
     */
    privateApiSmsConfigurationList: (params: RequestParams = {}) =>
      this.request<SmsConfiguration[], any>({
        path: `/private/api/sms-configuration`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSmsConfigurationCreate
     * @request POST:/private/api/sms-configuration
     * @secure
     * @response `201` `SmsConfiguration`
     */
    privateApiSmsConfigurationCreate: (data: SmsConfigurationRequest, params: RequestParams = {}) =>
      this.request<SmsConfiguration, any>({
        path: `/private/api/sms-configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSmsConfigurationRetrieve
     * @request GET:/private/api/sms-configuration/{id}
     * @secure
     * @response `200` `SmsConfiguration`
     */
    privateApiSmsConfigurationRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<SmsConfiguration, any>({
        path: `/private/api/sms-configuration/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSmsConfigurationUpdate
     * @request PUT:/private/api/sms-configuration/{id}
     * @secure
     * @response `200` `SmsConfiguration`
     */
    privateApiSmsConfigurationUpdate: (id: string, data: SmsConfigurationRequest, params: RequestParams = {}) =>
      this.request<SmsConfiguration, any>({
        path: `/private/api/sms-configuration/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSmsConfigurationPartialUpdate
     * @request PATCH:/private/api/sms-configuration/{id}
     * @secure
     * @response `200` `SmsConfiguration`
     */
    privateApiSmsConfigurationPartialUpdate: (
      id: string,
      data: PatchedSmsConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<SmsConfiguration, any>({
        path: `/private/api/sms-configuration/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSmsConfigurationDestroy
     * @request DELETE:/private/api/sms-configuration/{id}
     * @secure
     * @response `204` `void` No response body
     */
    privateApiSmsConfigurationDestroy: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/private/api/sms-configuration/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
}
